.rate-recipe-ul-container {
    position: fixed;
    bottom: 5px;
    right: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 9950;

    list-style: none;

    margin-right: 0px;
    
    transition: margin-right .5s linear;
}

.rate-recipe-ul-container.shrink {
    margin-right: 395px;
}

.rate-recipe-ul-container.parent {
    flex-direction: column-reverse;
} 

.rate-recipe-li-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 80px;
    width: 80px;
    border-radius: 50%;

    z-index: 9950;

    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .rate-recipe-li-container {
        height: 100px;
        width: 100px;
    }
}

.rate-recipe-li-container.parent {
    border: solid 2px #283897;
    background-color: #FFFFFF;

    transform: translateY(0) scale(1);
    opacity: 1;
}

.rate-recipe-li-container.children {
    background-color: transparent;
}

.rate-recipe-li-container.parent.action {
    margin-bottom: 10px;
    background-color: #283897;
}

.rate-recipe-li-container.parent span{
    font-size: 10px;
    line-height: 1;
}

.rate-recipe-li-container.parent .little-faces-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.rate-recipe-li-container.parent .little-faces-container .little-face {
    height: 18px;
    width: 18px;
} 

.rate-recipe-li-container.children .normal-faces-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.rate-recipe-li-container.children .normal-faces-container .normal-face {
    /*
    height: 50px;
    width: 50px;
    */

    height: 100%;
    width: 100%;
} 