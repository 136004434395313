.no-data-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#infanital-slider-id .owl-nav { 
    color: var(--infanital-slider-id-owl-nav-color);
}

@media screen and (max-width: 767px) {
    #infanital-slider-id .icon-user.icon,
    #infanital-slider-id .icon2-TRITURADA.icon{
        margin: 0;
        padding: 0;
        font-size: 65px;
    }

    #infanital-slider-id .default-heading {
        display: block;
        margin: 8px 0;
        padding: 0;
    }
    
    #infanital-slider-id .default-heading h2 { 
        font-size: 14px;
    }

    #infanital-slider-id .box-area .owl-nav {
        top: 20%;
    }
}

@media screen and (max-width: 1023px) {
    #infanital-slider-id .icon{
        padding: 4px 0 0 0;
    }
}
