.avatar-list-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.avatar-list-container::after {
    content: "";
    flex: 1 0 25%;
    margin: 6px; 
}



.avatar-img {
    flex: 1 0 25%;
    margin: 6px; 
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;

    height: 75px;

}

.avatar-img .avatar-button-last {
    background-color: #283897;
    font-family: "Sansa Pro";
    color: var(--white);
    font-size: 14px;
    text-transform: uppercase;

    border-radius: 50%;
    height: 75px;
    width: 75px;

    vertical-align: middle;
    text-align: center;

    justify-content: center;
    align-items: center;
    display: flex;
}

.avatar-img .avatar {
    max-height: 75px;
    max-width: 75px;
}

.avatar-img .avatar:hover, .avatar-img .avatar.selected {
    border: 5px solid #283897;
    border-radius: 50%;
}

@media screen and (min-width: 768px) {
    .avatar-img {
        flex: 1 0 25%; 
        margin: 6px auto;
    }
    .avatar-list-container::after {
        content: "";
        flex: 1 0 25%;
        margin: 6px auto; 
    }
}