.overPopup {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
}

.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9995;
    width: 100vw;
    height: 100vh;
}

.popup.vis {
    display: block;
}

.popup.hidden {
    display: none;
}

.popup.fullscreen { 
    
}

.close-spacer { 
    margin-right: 8px;
    cursor: pointer;
    color: #283897;
}

.popup .contentPopup {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background: #FFF;
    padding: 15px;
    width: 900px;
    max-width: calc(100% - 30px);
    max-height: 90vh;
    
    overflow: auto;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.popup .contentPopup::-webkit-scrollbar {
    display: none;
}

.popup.fullscreen .contentPopup {
    position: relative;
    top: 0px;
    left: 0px;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;

    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
}

.popup.fullscreen .close-spacer {
    position: fixed;
    top: 8px;
    right: 12px;
    
    font-size: 26px;

    margin-right: 0px;
}

@media screen and (min-width: 768px) {
    /*
    .popup.fullscreen .contentPopup {
        width: 95vw;
        height: 95vh;
        max-width: 95vw;
        max-height: 95vh;
    }
    */

    .popup.fullscreen .close-spacer {
        right: 32px;
        font-size: 20px;
    }
}