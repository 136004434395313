/*
Try position fixed but -> not ok
.footer-area {
    position: fixed;
    width: 100%;
    bottom: 0;
}
*/

.copyright-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    flex-direction: row;
}