.menu-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.menu-container.menu-container__full {
    flex-direction: column;
}

.menu-container.menu-container__middle {
    flex-direction: row;
}

.menu-container__middle > div {
    flex: 100%;
}

.menu-container__full > div {
    flex: 100%;
}

@media (min-width: 768px) {
    .menu-container__middle > div {
        flex: 48%;
    }
}

/*
@media (min-width: 992px) {
    .menu-container__middle > div {
        flex: 48%;
    }
}
*/

.recipe-no-elements-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem auto;

    font-size: 18px;
    text-transform: uppercase;
}

.master-recipe-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;

    border-radius: 20px;

    align-self: flex-start;
}

.children .master-recipe-container { 
    margin: 8px 4px;
    padding-top: 16px;
}
