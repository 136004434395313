.rate-recipe-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    vertical-align: middle;

    font-family: "Sansa Pro Nor"!important;

    margin-top: 24px;
}

.rate-recipe-modal-container h2{
    color: #24387C;
    font-size: 24px;
    text-transform: uppercase;

    font-family: "Sansa Pro SmBd";
    font-weight: 600;

    margin: 8px auto;
}

.rate-recipe-modal-container .rate-recipe-thanks-img{
    width: 100px;
    height: 100px;
}

.rate-recipe-modal-container p.occasion{
    color: #24387C;
    font-size: 18px;
    text-transform: uppercase;
    
    font-family: "Sansa Pro SmBd";
    font-weight: 600;
    
    margin: 8px auto;
}

.rate-recipe-modal-container .rate-recipe-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.rate-recipe-modal-container .rate-recipe-buttons-container .rate-recipe-modal-button {
    background-color: #283897;
    border: none;
    border-radius: 4px;
    color: #fff;

    margin-top: 8px;
    padding: 8px 0px;

    font-family: "Sansa Pro SmBd";
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;

    min-width: 175px;
    max-width: 200px;
}
.rate-recipe-modal-container .rate-recipe-buttons-container .rate-recipe-modal-button.invert {
    background-color: #fff;
    border: 1px solid #283897; 
    border-radius: 4px;
    color: #283897;
}

.rate-recipe-modal-container .master-recipe-container,
.children .rate-recipe-modal-container .master-recipe-container {
    width: 100%;
}


.rate-recipe-modal-container .recipe__title {
    display: none;
}

.rate-recipe-modal-container .recipe__name {
    font-size: 24px;
}

.rate-recipe-modal-container .rate-recipe-faces-container {
    width: 100%;
    margin: 6px 2px;
}

.rate-recipe-faces-container .rate-recipe-img {
    width: 45px;
    height: 45px;
}

.rate-recipe-modal-container .recipe__icon {
    font-size: 45px;
}

@media screen and (min-width: 360px) {
    .rate-recipe-faces-container .rate-recipe-img {
        width: 58px;
        height: 58px;
    }
}

@media screen and (min-width: 392px) {
    .rate-recipe-faces-container .rate-recipe-img {
        width: 65px;
        height: 65px;
    }
}

@media screen and (min-width: 768px) {
    .rate-recipe-modal-container .recipe__title {
        display: none;
    }

    .rate-recipe-modal-container .recipe__name {
        font-size: 24px;
    }

    .rate-recipe-modal-container .rate-recipe-faces-container {
        width: 100%;
        margin: 6px 4px;
    }

    .rate-recipe-modal-container .rate-recipe-faces-container .rate-recipe-img {
        width: 80px;
        height: 80px;
    }
}

/*Portrait mode ipad pro*/
@media screen and (max-width: 1024px) and (orientation:portrait) {
    .rate-recipe-modal-container .master-recipe-container {
        flex: 100% 1;
    }
}

@media screen and (max-width: 1366px) and (orientation:landscape) {
    .rate-recipe-modal-container .master-recipe-container {
        flex: 100% 1;
    }

    .rate-recipe-modal-container .recipe__title {
        display: none;
    }

    .rate-recipe-modal-container .recipe__name {
        font-size: 24px;
    }

    .rate-recipe-modal-container .rate-recipe-faces-container {
        width: 100%;
        margin: 6px 4px;
    }

    .rate-recipe-modal-container .rate-recipe-faces-container .rate-recipe-img {
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 992px) {
    .rate-recipe-modal-container .menu-container__middle > div {
        flex: 100%;
    }
}

@media screen and (min-width: 992px) and (orientation:portrait) {
    .rate-recipe-modal-container .recipe__icon {
        font-size: 55px;
    }

    .rate-recipe-modal-container .recipe__name {
        font-size: 18px;
    }

    .rate-recipe-modal-container .rate-recipe-faces-container {
        width: 80%;
    }

    .rate-recipe-modal-container .rate-recipe-faces-container .rate-recipe-img {
        width: 70px;
        height: 70px;
    }
}



/*
TODO: move inside css file on ftp;
*/

/**Children*/
.children .rate-recipe-modal-container .master-recipe-container {
    background-color: transparent!important;
    margin: 0px;
    padding: 0px;
}

.children .rate-recipe-modal-container .recipe__name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    height: auto;
    text-transform: none;
    line-height: 24px;
}


.children .rate-recipe-modal-container .recipe-container.recipe-container__only-text {
    margin-bottom: 15px;
}