.inner-area .column-details .default-heading h3 {
    text-transform: uppercase;
}

.profile-info-container {
    text-align: left;
    margin: 8px auto;

    width: 80%;
}

.profile-name-input { 
    color: #65676A;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;

    width: 100%;
    padding: 10px 5px;
    border: none;
    border-bottom: var(--form-inline-form-control-border-bottom-color) solid 2px;
    border-radius: 0;
    height: auto;
}

.profile-info-container .name{
    font-family: "Sansa Pro Nor";
    font-size: 14px;
    color: #283897;

    margin-bottom: 0px;
}

.school-name { 
    text-align: center;
    text-transform: uppercase;

    font-family: "Sansa Pro Nor";
    font-size: 16px;
    color: #283897;

    margin: 16px auto;
}

.edit-avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 8px auto;
}

.edit-avatar-container .avatar-img {
    width: 55px;
    height: 55px;
    margin: 4px auto;
}

.edit-avatar-container .avatar-button {
    margin: 16px auto 4px auto;

    background-color: #283897;
    border-color: #283897;

    font-family: "Sansa Pro Nor";
    font-size: 14px;
    color: var(--white);

    letter-spacing: 0px;
}


.action-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    width: 50%;

    margin: 64px auto auto auto;
}

.single-action-button-container {
    height: 100px;
    width: 200px;
    text-align: center;
}

.delete-bottom-button { 
    position: relative;
    bottom: 10px;
    top: 25px;
}



.children #edit-profile-container .default-heading h3 {
    font-family: "Soopafre";
}

.children #edit-profile-container .avatar-img {
    height: 75px;
    width: 75px;
}

#choose-avatar-image .default-heading {
    margin-bottom: 0px;
}

#choose-avatar-image .default-heading h3 {
    text-transform: uppercase;
    font-family: "Sansa Pro SmBd";
}

.avatar-btn {
    margin: 32px auto auto auto;

    background-color: #283897;
    border-color: #283897;

    font-family: "Sansa Pro Nor";
}

.avatar-btn:hover {
    background-color: #283897;
    border-color: #283897;
}