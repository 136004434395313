.icon-close-circle:before {
    color: var(--account-page-icon-colse-color);
}

.form-group.last {
    margin-bottom: 12px;
}

.btn-delete {
    margin: 12px auto 12px auto;
    background-color: var(--account-page-button-delete-background-color);
    font-size: 16px;
}

.btn-delete:hover {
    margin: 12px auto 12px auto;
    background-color: var(--account-page-button-delete-background-color-hover);
    font-size: 16px;
}

.pencil-img-container {
    margin-top: 16px;
}

#add-account-id-container .box-area {
    margin-top: 16px;
    width: 100%;
    background-color: var(--account-area-box-area-add-profile-background-color);
    text-align: center;
    display: inline-block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
    min-height: 0px;
    min-width: 0px;
    max-width: 400px;
}

#add-account-id-container .box-area .default-heading {
    margin-bottom: 0;
}

#add-account-id-container .box-area .default-heading h2 {
    vertical-align: middle;
    align-self: center;
    color: var(--white);
}

#add-account-id-container .box-area.add-profile .add-icon {
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    background-color: var(--account-area-box-area-add-profile-add-icon-background-color);
    border-radius: 100%;
    margin: 0 auto 15px auto;

    align-items: center;
    justify-content: center;
}

.delete-account-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;
    width: 100%;
    text-align: center;
}

.delete-account-modal-container .title { 
    color: var(--head-area-default-heading-h3-color);
    font-weight: 600;
    text-transform: uppercase; 

    font-family: "Sansa Pro Nor", sans-serif;

    font-size: 24px;
    line-height: 30px;

    margin: 4px auto;
}

.delete-account-modal-container .description { 
    font-size: 18px;
    color: var(--body-base-color);
    text-transform: none; 
    
    font-family: "Sansa Pro Nor", sans-serif;

    margin: 4px auto;
}

.delete-account-modal-container .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    height: 100%;
    width: 100%;
    text-align: center;

    margin: 16px auto;
    padding: auto;
}

@media screen and (min-width: 768px){
    .delete-account-modal-container .buttons-container {
        width: 75%;
    }
}