.recipe-container { 
    position: relative; 
    display: block; 
    text-align: center; 
    margin-bottom: 60px;
}

/*Rate component*/
.recipe-container.with-rate-component { 
    margin-bottom: 30px;
}

.recipe-container-one-occasion {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;

    padding: 16px 8px;
    width: 95%;
    max-width: 95%;
    background: transparent;

    border-radius: 10px;

    margin: auto 10px 10px 10px;
}

@media screen and (min-width: 768px){
    .recipe-container-one-occasion {
        max-width: 75%;
        margin: auto auto 10px auto;
    }
}

.recipe-container.recipe-container__only-text { 
    margin-bottom: 15px; 
}

.children .recipe-container.recipe-container__only-text { 
    margin-bottom: 0px; 
}

.recipe__icon { 
    font-size: 55px; 
    margin-bottom: 15px; 
    text-align: center;

    width: 100%;
}

.recipe__icon.image__icon {
    height: 55px; 
    width: 55px;
    
    /*
    height: 60px; 
    width: 60px;
    */
}

.recipe__title { 
    color: var(--recipe-title-color); 
    text-transform: uppercase; 
    font-size: 20px;

    text-align: center
}
.recipe__title.recipe__title-with-mb { 
    margin-bottom: 30px;
}

.recipe__name { 
    font-size: 18px; 
    font-weight: 500; 
    margin-bottom: 0; 


    height: auto;
}

.children .recipe__name { 
    height: 100px;
}

.add-to-favourite-container {
    display: none;
}

/*
TODO: verify
*/
.children #recipe-detail-container .rate-recipe-area-container .default-heading h2 {
    color: inherit!important;
    /*
    color: var(--information-area-default-heading-h2-color)!important;
    */
}