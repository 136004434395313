#someal .header-row-container { 
    display: inline-flex;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
}

#sohappy .header-row-container { 
    display: inline-flex;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: space-between;
}

.headerSection .navbar-img,
#someal .headerSection .navbar-img {
    width: 35%;
}

#sohappy .headerSection .navbar-img {
    width: 22.5%;
}

.headerSection .full-item-centered,
#someal .headerSection .full-item-centered {
    /*
    width: 55%;
    justify-self: center;
    text-align: center;
    */
    width: 30%;
    max-height: 100px;
}

#sohappy .headerSection .full-item-centered {
    width: 55%;
    max-height: 100px;
}

.headerSection .full-item-centered .navbar-brand-middle {
    max-height: 100px;
}

.headerSection .full-item-centered .navbar-brand-middle img {
    max-height: 100px;
}

.headerSection .item-centered {
    min-width: var(--header-headerSection-item-centered-min-width);
    max-width: var(--header-headerSection-item-centered-max-width);
}

.headerSection .dropdown-buttons-container,
#someal .headerSection .dropdown-buttons-container {
    width: 35%;
}


#sohappy .headerSection .dropdown-buttons-container {
    width: 22.5%;
}

.version-sidebar-bottom {
    margin-top: 50px;
}

.account-area .dropdown-menu {
    max-width: auto;
    min-width: auto;
    width: 100vw;
}

.nav-link.child-mode {
    font-family: "Soopafre";
    color: #FFB81A!important;
}

.avatar-profile-img {
    width: 40px;
    height: 40px;
}

.header-school-name{
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: var(--menu-area-default-heading-h1-color);
}

@media screen and (min-width: 250px) {
    /*
    .headerSection .navbar-img { 
        width: 20%;
        padding-left: 10px;
    }
    */

    .headerSection .item-centered {
        min-width: var(--header-headerSection-item-centered-min-width-327);
        max-width: var(--header-headerSection-item-centered-max-width-327);
    }
    
    .headerSection .dropdown-buttons-container {
        /*
        width: 20%;
        */
        padding-right: 10px;
    }
}

@media screen and (min-width: 768px) {
    /*
    
    */
    #sohappy .headerSection .navbar-img { 
        width: 20%;
    }

    #sohappy .headerSection .navbar-img img {
        margin-left: auto;
        margin-right: auto;
    }

    #sohappy .headerSection .full-item-centered { 
        width: auto;
    }

    .headerSection .item-centered {
        min-width: var(--header-headerSection-item-centered-min-width-768);
        max-width: var(--header-headerSection-item-centered-max-width-768);
    }
    
    #sohappy .headerSection .dropdown-buttons-container {
        width: 20%;
    }
    /*
    */

    .avatar-profile-img {
        width: 55px;
        height: 55px;
    }
}

.chldren-dropdown-container {
    display: none;
}

.children .chldren-dropdown-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;

}

.children .chldren-dropdown-container .avatar-img {
    height: 75px;
    width: 75px;
}

.children .children-profile-name {
    margin: 8px auto;
    
    font-size: 24px;
    color: #283897;
    text-transform: uppercase;
    font-family: "Soopafre";
}

.children .change-avatar {
    margin: 8px auto;
    
    font-size: 24px;
    color: #283897;
    text-transform: uppercase;
    font-family: "Soopafre";
}


/*
.header-row-container { 
    justify-content: space-between;
}

.headerSection .navbar-img { 
    justify-self: flex-start;
    width: 38%;
    padding-left: 2px;
}

.headerSection .full-item-centered {
    width: 55%;
    justify-self: center;
    text-align: center;
}

.headerSection .item-centered {
    min-width: var(--header-headerSection-item-centered-min-width);
    max-width: var(--header-headerSection-item-centered-max-width);
}

.headerSection .dropdown-buttons-container {
    justify-self: flex-end;
    width: 38%;
    padding-right: 2px;
}

.version-sidebar-bottom {
    margin-top: 50px;
}

.account-area .dropdown-menu {
    max-width: auto;
    min-width: auto;
    width: 100vw;
}

.nav-link.child-mode {
    font-family: "Soopafre";
    color: #FFB81A!important;
}

.avatar-profile-img {
    width: 40px;
    height: 40px;
}

.header-school-name{
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: var(--menu-area-default-heading-h1-color);
}

@media screen and (min-width: 250px) {
    .headerSection .navbar-img { 
        width: 20%;
        padding-left: 10px;
    }

    .headerSection .item-centered {
        min-width: var(--header-headerSection-item-centered-min-width-327);
        max-width: var(--header-headerSection-item-centered-max-width-327);
    }
    
    .headerSection .dropdown-buttons-container {
        width: 20%;
        padding-right: 10px;
    }
}

@media screen and (min-width: 768px) {
    .headerSection .navbar-img { 
        width: 20%;
    }

    .headerSection .full-item-centered { 
        width: auto;
    }

    .headerSection .item-centered {
        min-width: var(--header-headerSection-item-centered-min-width-768);
        max-width: var(--header-headerSection-item-centered-max-width-768);
    }
    
    .headerSection .dropdown-buttons-container {
        width: 20%;
    }

    .avatar-profile-img {
        width: 55px;
        height: 55px;
    }
}

.headerSection.fixed { 
  position: fixed;
  width: 100%;
  max-height: 140px;
  top: 0;
}

.headerSection.fixed.centered-absolute {
  left: auto;
  right: auto;
}

@media screen and (min-width: 768px) {  
  .headerSection.fixed.centered-absolute {
    left: 25%;
    right: 25%;
  }
}

.headerSection.fixed.centered-absolute ~ .middle-content {
  padding-top: 140px;
}

.headerSection.fixed ~ .middle-content {
  padding-top: 65px;
}

@media screen and (min-width: 768px) {  
  .headerSection.fixed.centered-absolute ~ .middle-content {
    padding-top: 140px;
  }
  
  .headerSection.fixed ~ .middle-content {
    padding-top: 100px;
  }
}



*/