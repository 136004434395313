@charset "UTF-8";

@import url('./variables.css');
@import url('./new_icons.css');
@import url('./font_awesome_icons.css');

html, body {
  height: 100%; }

body {
  background: var(--body-base-bg-color);
  color: var(--body-base-color);
  padding: 0px;
  font-family: "Sansa Pro Nor", sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale; }

*, figure, ul, li {
  margin: 0px;
  padding: 0px;
  list-style: none; }

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
  margin-bottom: 0; }

a, .btn, button, i {
  color: var(--zero); }

a:hover {
  color: var(--base-a-hover); }

.trs {
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out; }

a:hover, a:focus {
  text-decoration: none;
  outline: none; }

img {
  max-width: 100%; }

p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: var(--input-base-color);
  opacity: 1; }

input::-moz-placeholder, textarea::-moz-placeholder {
  color: var(--input-base-color);
  opacity: 1; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: var(--input-base-color);
  opacity: 1; }

input:-moz-placeholder, textarea:-moz-placeholder {
  color: var(--input-base-color);
  opacity: 1; }

select, input, textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

@font-face {
  font-family: 'Sansa Pro';
  src: url("../fonts/SansaPro-Bold.eot");
  src: url("../fonts/SansaPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SansaPro-Bold.woff2") format("woff2"), url("../fonts/SansaPro-Bold.woff") format("woff"), url("../fonts/SansaPro-Bold.ttf") format("truetype"), url("../fonts/SansaPro-Bold.svg#SansaPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Sansa Pro';
  src: url("../fonts/SansaPro-Light.eot");
  src: url("../fonts/SansaPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/SansaPro-Light.woff2") format("woff2"), url("../fonts/SansaPro-Light.woff") format("woff"), url("../fonts/SansaPro-Light.ttf") format("truetype"), url("../fonts/SansaPro-Light.svg#SansaPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Sansa Pro Nor';
  src: url("../fonts/SansaPro-Normal.eot");
  src: url("../fonts/SansaPro-Normal.eot?#iefix") format("embedded-opentype"), url("../fonts/SansaPro-Normal.woff2") format("woff2"), url("../fonts/SansaPro-Normal.woff") format("woff"), url("../fonts/SansaPro-Normal.ttf") format("truetype"), url("../fonts/SansaPro-Normal.svg#SansaPro-Normal") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Sansa Pro SmBd';
  src: url("../fonts/SansaPro-SemiBold.eot");
  src: url("../fonts/SansaPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/SansaPro-SemiBold.woff2") format("woff2"), url("../fonts/SansaPro-SemiBold.woff") format("woff"), url("../fonts/SansaPro-SemiBold.ttf") format("truetype"), url("../fonts/SansaPro-SemiBold.svg#SansaPro-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }
/**Child mode*/
@font-face {
  font-family: 'Soopafre';
  src: url('../fonts/soopafre.ttf') format('truetype');
}
/**** Web Icons */
@font-face {
  font-family: 'someal';
  src: url("../fonts/someal.eot?l8tq3z");
  src: url("../fonts/someal.eot?l8tq3z#iefix") format("embedded-opentype"),
    url("../fonts/someal.ttf?l8tq3z") format("truetype"),
    url("../fonts/someal.woff?l8tq3z") format("woff"),
    url("../fonts/someal.svg?l8tq3z#someal") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'someal' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle; }

.icon-desktop .path1:before {
  content: "\e900";
  color: var(--icon-diets-color-6); }

.icon-desktop .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: var(--icon-diets-color-5); }

.icon-desktop .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: var(--icon-diets-color-5); }

.icon-mobile .path1:before {
  content: "\e903";
  color: var(--icon-diets-color-6); }

.icon-mobile .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: var(--icon-diets-color-5); }

.icon-mobile .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: var(--icon-diets-color-5); }

.icon-apple .path1:before {
  content: "\e906";
  color: var(--icon-diets-color-4); }

.icon-apple .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-apple .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: #e30613; }

.icon-apple .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: #e30613; }

.icon-bowl-foods .path1:before {
  content: "\e90a";
  color: var(--icon-diets-color-4); }

.icon-bowl-foods .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-foods .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: #e30613; }

.icon-bowl-foods .path4:before {
  content: "\e90d";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-foods .path5:before {
  content: "\e90e";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-foods .path6:before {
  content: "\e90f";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-foods .path7:before {
  content: "\e910";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-foods .path8:before {
  content: "\e911";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-foods .path9:before {
  content: "\e912";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-spoon .path1:before {
  content: "\e913";
  color: var(--icon-diets-color-4); }

.icon-bowl-spoon .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-spoon .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: #e30613; }

.icon-bowl-spoon .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-bowl-spoon .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path1:before {
  content: "\e918";
  color: var(--icon-diets-color-4); }

.icon-buns .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path3:before {
  content: "\e91a";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path4:before {
  content: "\e91b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path5:before {
  content: "\e91c";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path6:before {
  content: "\e91d";
  margin-left: -1em;
  color: #e30613; }

.icon-buns .path7:before {
  content: "\e91e";
  margin-left: -1em;
  color: #e30613; }

.icon-buns .path8:before {
  content: "\e91f";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path9:before {
  content: "\e920";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path10:before {
  content: "\e921";
  margin-left: -1em;
  color: #e30613; }

.icon-buns .path11:before {
  content: "\e922";
  margin-left: -1em;
  color: #e30613; }

.icon-buns .path12:before {
  content: "\e923";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path13:before {
  content: "\e924";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-buns .path14:before {
  content: "\e925";
  margin-left: -1em;
  color: #e30613; }

.icon-buns .path15:before {
  content: "\e926";
  margin-left: -1em;
  color: #e30613; }

.icon-carrot .path1:before {
  content: "\e927";
  color: var(--icon-diets-color-4); }

.icon-carrot .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path3:before {
  content: "\e929";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path4:before {
  content: "\e92a";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path5:before {
  content: "\e92b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path6:before {
  content: "\e92c";
  margin-left: -1em;
  color: #323232; }

.icon-carrot .path7:before {
  content: "\e92d";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path8:before {
  content: "\e92e";
  margin-left: -1em;
  color: #e30613; }

.icon-carrot .path9:before {
  content: "\e92f";
  margin-left: -1em;
  color: #e30613; }

.icon-carrot .path10:before {
  content: "\e930";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path11:before {
  content: "\e931";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path12:before {
  content: "\e932";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path13:before {
  content: "\e933";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path14:before {
  content: "\e934";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-carrot .path15:before {
  content: "\e935";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path1:before {
  content: "\e936";
  color: var(--icon-diets-color-4); }

.icon-cow .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path3:before {
  content: "\e938";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path4:before {
  content: "\e939";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path5:before {
  content: "\e93a";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path6:before {
  content: "\e93b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path7:before {
  content: "\e93c";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path8:before {
  content: "\e93d";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path9:before {
  content: "\e93e";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path10:before {
  content: "\e93f";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-cow .path11:before {
  content: "\e940";
  margin-left: -1em;
  color: #e30613; }

.icon-cow .path12:before {
  content: "\e941";
  margin-left: -1em;
  color: #e30613; }

.icon-cow .path13:before {
  content: "\e942";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-dish .path1:before {
  content: "\e943";
  color: var(--icon-diets-color-4); }

.icon-dish .path2:before {
  content: "\e944";
  margin-left: -1em;
  color: #e30613; }

.icon-dish .path3:before {
  content: "\e945";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-dish .path4:before {
  content: "\e946";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-dish .path5:before {
  content: "\e947";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-dish .path6:before {
  content: "\e948";
  margin-left: -1em;
  color: var(--icon-diets-color-4); 
}

.icon-egges .path1:before {
  content: "\e949";
  color: var(--icon-diets-color-4); }

.icon-egges .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-egges .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-egges .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: #e30613; }

.icon-egges .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: #e30613; }

.icon-egges .path6:before {
  content: "\e94e";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-egges .path7:before {
  content: "\e94f";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-egges .path8:before {
  content: "\e950";
  margin-left: -1em;
  color: #e30613; }

.icon-egges .path9:before {
  content: "\e951";
  margin-left: -1em;
  color: #e30613; }

.icon-female-user .path1:before {
  content: "\e952";
  color: #e30613; }

.icon-female-user .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-female-user .path3:before {
  content: "\e954";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-female-user .path4:before {
  content: "\e955";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-female-user .path5:before {
  content: "\e956";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-female-user .path6:before {
  content: "\e957";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-female-user .path7:before {
  content: "\e958";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-food-dish .path1:before {
  content: "\e959";
  color: var(--icon-diets-color-4); }

.icon-food-dish .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: #e30613; }

.icon-food-dish .path3:before {
  content: "\e95b";
  margin-left: -1em;
  color: #e30613; }

.icon-food-plate .path1:before {
  content: "\e95c";
  color: var(--icon-diets-color-4); }

.icon-food-plate .path2:before {
  content: "\e95d";
  margin-left: -1em;
  color: #e30613; }

.icon-food-plate .path3:before {
  content: "\e95e";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-food-plate .path4:before {
  content: "\e95f";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-fruits .path1:before {
  content: "\e960";
  color: var(--icon-diets-color-4); }

.icon-fruits .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-fruits .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-fruits .path4:before {
  content: "\e963";
  margin-left: -1em;
  color: #e30613; }

.icon-fruits .path5:before {
  content: "\e964";
  margin-left: -1em;
  color: #e30613; }

.icon-fruits .path6:before {
  content: "\e965";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-fruits .path7:before {
  content: "\e966";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-grain .path1:before {
  content: "\e967";
  color: #e30613; }

.icon-grain .path2:before {
  content: "\e968";
  margin-left: -1em;
  color: #e30613; }

.icon-grain .path3:before {
  content: "\e969";
  margin-left: -1em;
  color: #e30613; }

.icon-grain .path4:before {
  content: "\e96a";
  margin-left: -1em;
  color: #e30613; }

.icon-grain .path5:before {
  content: "\e96b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-grain .path6:before {
  content: "\e96c";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-grain .path7:before {
  content: "\e96d";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-grain .path8:before {
  content: "\e96e";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-grain .path9:before {
  content: "\e96f";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-heart-beat .path1:before {
  content: "\e970";
  color: var(--icon-diets-color-4); }

.icon-heart-beat .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: #e30613; }

.icon-heart-beat .path3:before {
  content: "\e972";
  margin-left: -1em;
  color: #e30613; }

.icon-heart-beat .path4:before {
  content: "\e973";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-meater .path1:before {
  content: "\e974";
  color: var(--icon-diets-color-4); }

.icon-meater .path2:before {
  content: "\e975";
  margin-left: -1em;
  color: #e30613; }

.icon-meater .path3:before {
  content: "\e976";
  margin-left: -1em;
  color: #e30613; }

.icon-meater .path4:before {
  content: "\e977";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-milk-bottle .path1:before {
  content: "\e978";
  color: var(--icon-diets-color-4); }

.icon-milk-bottle .path2:before {
  content: "\e979";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-milk-bottle .path3:before {
  content: "\e97a";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-milk-bottle .path4:before {
  content: "\e97b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-milk-bottle .path5:before {
  content: "\e97c";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-milk-bottle .path6:before {
  content: "\e97d";
  margin-left: -1em;
  color: #e30613; }

.icon-milk-bottle .path7:before {
  content: "\e97e";
  margin-left: -1em;
  color: #e30613; }

.icon-mouse .path1:before {
  content: "\e97f";
  color: var(--icon-diets-color-4); }

.icon-mouse .path2:before {
  content: "\e980";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-mouse .path3:before {
  content: "\e981";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-mouse .path4:before {
  content: "\e982";
  margin-left: -1em;
  color: #e30613; }

.icon-mouse .path5:before {
  content: "\e983";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-neutrients .path1:before {
  content: "\e984";
  color: var(--icon-diets-color-4); }

.icon-neutrients .path2:before {
  content: "\e985";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-neutrients .path3:before {
  content: "\e986";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-neutrients .path4:before {
  content: "\e987";
  margin-left: -1em;
  color: #e30613; }

.icon-neutrients .path5:before {
  content: "\e988";
  margin-left: -1em;
  color: #e30613; }

.icon-neutrients .path6:before {
  content: "\e989";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path1:before {
  content: "\e98a";
  color: var(--icon-diets-color-4); }

.icon-pig .path2:before {
  content: "\e98b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path3:before {
  content: "\e98c";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path4:before {
  content: "\e98d";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path5:before {
  content: "\e98e";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path6:before {
  content: "\e98f";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path7:before {
  content: "\e990";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path8:before {
  content: "\e991";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path9:before {
  content: "\e992";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-pig .path10:before {
  content: "\e993";
  margin-left: -1em;
  color: #e30613; }

.icon-pig .path11:before {
  content: "\e994";
  margin-left: -1em;
  color: #e30613; }

.icon-rice-dish .path1:before {
  content: "\e995";
  color: #e30613; }

.icon-rice-dish .path2:before {
  content: "\e996";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-rice-dish .path3:before {
  content: "\e997";
  margin-left: -1em;
  color: #e30613; }

.icon-rice-dish .path4:before {
  content: "\e998";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-rice-dish .path5:before {
  content: "\e999";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-rice-dish .path6:before {
  content: "\e99a";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-rice-dish .path7:before {
  content: "\e99b";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-sea-foods .path1:before {
  content: "\e99c";
  color: var(--icon-diets-color-4); }

.icon-sea-foods .path2:before {
  content: "\e99d";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-sea-foods .path3:before {
  content: "\e99e";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-sea-foods .path4:before {
  content: "\e99f";
  margin-left: -1em;
  color: #e30613; }

.icon-sea-foods .path5:before {
  content: "\e9a0";
  margin-left: -1em;
  color: #e30613; }

.icon-sea-foods .path6:before {
  content: "\e9a1";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-sea-foods .path7:before {
  content: "\e9a2";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-sea-foods .path8:before {
  content: "\e9a3";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-soup .path1:before {
  content: "\e9a4";
  color: #e30613; }

.icon-soup .path2:before {
  content: "\e9a5";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-soup .path3:before {
  content: "\e9a6";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-soup .path4:before {
  content: "\e9a7";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-soup .path5:before {
  content: "\e9a8";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-soup .path6:before {
  content: "\e9a9";
  margin-left: -1em;
  color: #e30613; }

.icon-soup .path7:before {
  content: "\e9aa";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-user .path1:before {
  content: "\e9ab";
  color: var(--icon-diets-color-4); }

.icon-user .path2:before {
  content: "\e9ac";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-user .path3:before {
  content: "\e9ad";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-user .path4:before {
  content: "\e9ae";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-user .path5:before {
  content: "\e9af";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-user .path6:before {
  content: "\e9b0";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-user .path7:before {
  content: "\e9b1";
  margin-left: -1em;
  color: #e30613; }

/*
  Added by Mari on 11/06/2021 for years slider
*/
.icon-user .path8:before {
  content: "\e952";
  margin-left: -1em;
  color: #e30613;
}
.icon-user .path9:before {
  content: "\e9b8";
  margin-left: -1em;
  color: #e30613;
}
.icon-user .path10:before {
  content: "\e9b9";
  margin-left: -1em;
  color: #e30613;
}

.icon-women-face .path1:before {
  content: "\e9b2";
  color: var(--icon-diets-color-4); }

.icon-women-face .path2:before {
  content: "\e9b3";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-women-face .path3:before {
  content: "\e9b4";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-women-face .path4:before {
  content: "\e9b5";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-women-face .path5:before {
  content: "\e9b6";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-women-face .path6:before {
  content: "\e9b7";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-women-face .path7:before {
  content: "\e9b8";
  margin-left: -1em;
  color: #e30613; }

.icon-women-face .path8:before {
  content: "\e9b9";
  margin-left: -1em;
  color: #e30613; }

.icon-menu-bar .path1:before {
  content: "\e9ba";
  color: white; }

.icon-menu-bar .path2:before {
  content: "\e9bb";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-menu-bar .path3:before {
  content: "\e9bc";
  margin-left: -1em;
  color: #e30613; }

.icon-menu-bar .path4:before {
  content: "\e9bd";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-menu-bar .path5:before {
  content: "\e9be";
  margin-left: -1em;
  color: var(--icon-diets-color-4); }

.icon-email:before {
  content: "\e9bf";
  color: var(--icon-diets-color-4); }

.icon-edit:before {
  content: "\e9c0";
  color: #c4c4c4; }

.icon-edit2:before {
  content: "\e9c1";
  color: #c4c4c4; }

.icon-blankstar:before {
  content: "\e9c2";
  color: #263a7b; }

.icon-solidstar:before {
  content: "\e9c3";
  color: var(--icon-diets-color-4); }

.icon-close:before {
  content: "\e9c4";
  color: #fff; }

.icon-close-circle:before {
  content: "\e9c5";
  color: #fff; }

.icon-download-arrow:before {
  content: "\e9c6";
  color: var(--icon-diets-color-4); }

.icon-top-arrow:before {
  content: "\e9c7"; }

.icon-left-arrow:before {
  content: "\e9c9";
  color: var(--icon-diets-color-4); }

.icon-right-arrow:before {
  content: "\e9ca";
  color: var(--icon-diets-color-4); }

.icon-left-arrow-solid .path1:before {
  content: "\e9cb";
  color: #65676a; }

.icon-left-arrow-solid .path2:before {
  content: "\e9cc";
  margin-left: -1em;
  color: white; }

.icon-right-arrow-solid .path1:before {
  content: "\e9cd";
  color: #65676a; }

.icon-right-arrow-solid .path2:before {
  content: "\e9ce";
  margin-left: -1em;
  color: white; }

.icon-minus:before {
  content: "\e9cf"; }

.icon-plus:before {
  content: "\e9d0"; }

.icon-search .path1:before {
  content: "\e9d1";
  color: #73c0b9; }

.icon-search .path2:before {
  content: "\e9d2";
  margin-left: -1em;
  color: white; }

.icon-search2:before {
  content: "\e9d3";
  color: #0f123a; }

.icon-small-arrow-left:before {
  content: "\e9d4";
  color: var(--date-slider-icon-small-arrow-left-color--before) }

.icon-small-arrow-right:before {
  content: "\e9d5";
  color: var(--date-slider-icon-small-arrow-right-color--before) }

/***********default*********/
.wrapper {
  background-color: var(--wrapper-background-color);
  position: relative; }

.body-color {
  background-color: var(--body-color-bg-color); }

.container {
  max-width: 1200px;
  width: 100%; }

.positioRelative {
  position: relative; }

.height100 {
  height: 100%; }

.height80 {
  height: 80%; }

.verticalyMiddle {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.overflowHidden {
  overflow: hidden; }

.bodyOverlay {
  overflow: hidden; }

.bodyOverlay:before {
  position: fixed;
  background-color: var(--body-overlay-before-background-color);
  opacity: 0.3;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999; }

.phone-hide {
  display: none; }

.img-cover-area {
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top center; }

.badge-primary {
  background-color: inherit;
  color: var(--badge-primary-color);
  font-size: 12px;
  border-radius: 100%;
  text-align: center;
  line-height: 20px;
  padding: 0; }

.paragraph {
  position: relative; }

.paragraph p {
  display: block;
  font-size: 22px;
  line-height: 36px;
  color: var(--paragraph-p-color);
  font-weight: 400;
  margin-bottom: 0; }

.maxwidth {
  max-width: 600px;
  margin: 0 auto; }

.maxwidth .navbar-brand-middle {
  margin-right: 0; }

/*.hide-on-mobile{ display: block; }*/
.hide-on-desktop {
  display: none; }

a:not([href]).btn:not([tabindex]) {
  color: var(--a-not-href-btn-not-tabindex); }

.btn {
  font-size: 18px;
  padding: 6px 30px;
  min-width: 250px;
  min-height: 50px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 10px; }

.btn.focus, .btn:focus {
  box-shadow: none; }

.btn-primary {
  box-shadow: none;
  background: var(--btn-primary-base-background-color);
  border-color: var(--btn-primary-base-border-color);
  color: var(--btn-primary-base-color); }

.btn-primary:hover,
.btn-primary:focus {
  box-shadow: none;
  background-color: var(--btn-primary-base-background-color-hover-focus);
  border-color: var(--btn-primary-base-border-color-hover-focus);
  color: var(--btn-primary-base-color-hover-focus); }

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--btn-primary-base-not-disabled-background-color);
  border-color: var(--btn-primary-base-not-disabled-border-color);
  color: var(--btn-primary-base-not-disabled-color);
  box-shadow: none; }

.btn-secondary {
  box-shadow: none;
  background: var(--btn-secondary-base-background-color);
  border-color: var(--btn-secondary-base-border-color);
  color: var(--btn-secondary-base-color); }

.btn-secondary:hover,
.btn-secondary:focus {
  box-shadow: none;
  background: var(--btn-secondary-base-background-color-hover-focus);
  border-color: var(--btn-secondary-base-border-color-hover-focus);
  color: var(--btn-secondary-base-color-hover-focus); }

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  box-shadow: none;
  background: var(--btn-secondary-base-not-disabled-background-color);
  border-color: var(--btn-secondary-base-not-disabled-border-color);
  color: var(--btn-secondary-base-not-disabled-color); }

.btn-success {
  display: inline-block; 
  background-color: var(--btn-success-base-background-color);
  border: var(--btn-success-base-border-color) solid 3px;
  color: var(--btn-success-base-color); }

.btn-success:hover,
.btn-success:focus {
  background: var(--btn-success-base-background-color-hover-focus);
  border-color: var(--btn-success-base-border-color-hover-focus);
  color: var(--btn-success-base-color-hover-focus); }

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background: var(--btn-success-base-not-disabled-background-color);
  border-color: var(--btn-success-base-not-disabled-border-color);
  color: var(--btn-success-base-not-disabled-color); }

.btn-light {
  display: inline-block; 
  background-color: var(--btn-light-base-background-color);
  border: var(--btn-light-base-border-color) solid 3px;
  color: var(--btn-light-base-color); }

.btn-light:hover,
.btn-light:focus {
  background: var(--btn-light-base-background-color-hover-focus);
  border-color: var(--btn-light-base-border-color-hover-focus);
  color: var(--btn-light-base-color-hover-focus); }

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus,
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background: var(--btn-light-base-not-disabled-background-color);
  border-color: var(--btn-light-base-not-disabled-border-color);
  color: var(--btn-light-base-not-disabled-color); }

.btn-link {
  color: var(--btn-link-base-color);
  max-width: 200px;
  text-align: left;
  font-size: 16px;
  padding: 0; }

.btn-link:hover {
  color: var(--btn-link-base-color-hover-focus);
  text-decoration: underline; }

.default-heading {
  display: block;
  position: relative; }

.default-heading h1, .default-heading > a.heading01 {
  font-size: 36px;
  line-height: 40px; }

.default-heading h2, .default-heading > a.heading02 {
  font-size: 30px;
  line-height: 36px; }

.default-heading h3, .default-heading > a.heading03 {
  font-size: 24px;
  line-height: 30px; }

.default-heading h4, .default-heading > a.heading04 {
  font-size: 18px;
  line-height: 28px; }

.default-heading h5, .default-heading > a.heading05 {
  font-size: 16px;
  line-height: 24px; }

.default-heading h6, .default-heading > a.heading06 {
  font-size: 14px;
  line-height: 18px; }

.owl-carousel .owl-item img {
  display: inline-block;
  width: auto; }

.owl-carousel .owl-dots {
  text-align: center; }

.owl-carousel .owl-dots button.owl-dot {
  margin: 0 5px;
  background-color: var(--owl-carousel-dots-button-owl-dot-background-color);
  width: 20px;
  height: 20px;
  border: var(--owl-carousel-dots-button-owl-dot-border-color) solid 2px;
  border-radius: 100px; }

.owl-carousel .owl-dots button.owl-dot.active {
  background-color: var(--owl-carousel-dots-button-owl-dot-background-color-active);
  border: var(--owl-carousel-dots-button-owl-dot-border-color-active) solid 2px;
  width: 20px;
  height: 20px;
  border-radius: 100px; }

.owl-nav {
  position: absolute;
  top: 45%;
  width: 100%;
  left: 0;
  right: 0; }

.owl-nav .owl-prev {
  position: absolute;
  left: 15px; }

.owl-nav .owl-next {
  position: absolute;
  right: 15px; }

.owl-nav .owl-prev i,
.owl-nav .owl-next i {
  font-size: 24px; }

/*.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev{ -webkit-transform: rotateY(130deg); transform: rotateY(130deg);} */
button:focus {
  outline: none; }

.form-group {
  position: relative;
  margin-bottom: 25px; }
  
.form-group label {
  color: var(--form-group-label-color);
  font-weight: 500;
  position: relative;
  line-height: 18px;
  font-size: 16px; }

.form-group label span {
  position: absolute;
  top: 0;
  right: -10px;
  color: var(--form-group-label-span-color);
  margin-left: 10px;
  display: block; }

.form-group select {
  font-size: 16px;
  padding: 10px 5px;
  height: auto;
  color: var(--form-group-select-color);
  background: var(--form-group-select-bg-color);
  border: none;
  border-bottom: var(--form-group-select-border-bottom-color) solid 2px;
  border-radius: 0; }

.form-control,
.form-inline .form-control {
  width: 100%;
  padding: 10px 5px;
  border: none;
  border-bottom: var(--form-inline-form-control-border-bottom-color) solid 2px;
  border-radius: 0;
  height: auto;
  font-size: 16px;
  color: var(--form-inline-form-control-color); }

.form-control.minheight,
.form-inline .form-control.minheight {
  min-height: 170px; }

.form-group textarea.form-control {
  border: var(--form-group-textarea-form-control-border-color) solid 2px; }

.form-group select.custom-select {
  /*background:#ebeff2 url(../images/down-arrow.png);*/
  background-repeat: no-repeat;
  background-position: 98% 16px;
  background-size: 15px;
  border: 1px solid var(--form-group-select-custom-select-border-color); }

.form-group .input-group.date .input-group-addon {
  background-color: var(--form-group-input-group-date-input-group-addon-bg-color);
  color: var(--form-group-input-group-date-input-group-addon-color);
  padding: 7px 8px;
  border-radius: 0rem .25rem .25rem 0rem; }

.form-group .input-group.date .input-group-addon .fa {
  color: var(--form-group-input-grou-date-input-group-addon-fa-color); }

.form-control:focus, select:focus, .custom-select:focus, textarea:focus {
  box-shadow: none;
  border-color: var(--form-control-select-custom-select-textarea-focus-border-color); }

.form-control:invalid {
  border-color: var(--form-control-invalid-border-color); }

.required-area {
  color: var(--required-area-color); }

.error-area {
  color: var(--error-area-color);
  font-size: 11px;
  line-height: 11px;
  position: absolute;
  display: block;
  margin-top: 3px; }

.custom-checkbox .custom-control-label {
  min-height: inherit; }

.custom-checkbox .custom-control-label::before {
  background-color: inherit;
  border: var(--custom-checkbox-custom-control-label--before-border-color) solid 2px;
  border-radius: 3px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--custom-checkbox-custom-control-input-custom-control-label--before-border-color);
  background-color: var(--custom-checkbox-custom-control-input-custom-control-label--before-background-color); }

.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
  width: 18px;
  height: 18px; }

.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
  left: -1.8rem;
  /*top:-0.2rem;*/
  top: 0rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-checkbox .custom-control-label::before {
  border-color: var(--custom-checkbox-custom-control-input-custom-checkbox-custom-control-label--before-border-color);
  background-color: var(--custom-checkbox-custom-control-input-custom-checkbox-custom-control-label--before-background-color); }

.custom-checkbox .custom-control-input:focus ~ .custom-checkbox .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(21, 60, 89, 0.25); }

.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-checkbox .custom-control-label::before {
  background-color: var(--custom-checkbox--custom-control-input-not-disabled-active-custom-checkbox-custom-control-label--before-background-color);
  border-color: var(--custom-checkbox--custom-control-input-not-disabled-active-custom-checkbox-custom-control-label--before-border-color); }

.blue-radio-btn.custom-control.custom-radio.active .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--blue-radio-btn-custom-control-custom-radio-active-custom-control-input-checked-custom-control-label--before-background-color);
  border-color: var(--blue-radio-btn-custom-control-custom-radio-active-custom-control-input-checked-custom-control-label--before-border-color); }

.custom-switch {
  padding-left: 0;
  min-height: inherit; }

.custom-switch .custom-control-label {
  cursor: pointer;
  min-height: inherit;
  vertical-align: middle;
  border: var(--custom-switch-custom-control-label-border-color) solid 1px;
  width: 66px;
  max-width: 66px;
  height: 30px;
  max-height: 30px;
  border-radius: 100px; }

.custom-switch .custom-control-label::before {
  left: 7px; }

.custom-switch .custom-control-label::before {
  border: none;
  background-color: var(--custom-switch-custom-control-label--before-background-color);
  width: 48px;
  height: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.custom-switch .custom-control-label::after {
  background: var(--custom-switch-custom-control-label--after-background-color-base);
  /* Old browsers */
  background: var(--custom-switch-custom-control-label--after-background-color-old-browser);
  /* FF3.6-15 */
  background: var(--custom-switch-custom-control-label--after-background-color-ff3-browser);
  /* Chrome10-25,Safari5.1-6 */
  background: var(--custom-switch-custom-control-label--after-background-color-chrome-browser);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f2f0', endColorstr='#e3e2de',GradientType=0 );
  /* IE6-9 */
  width: 18px;
  height: 18px;
  top: calc(.25rem + 1px);
  left: calc(.25rem + 2px); }

.custom-switch .custom-control-input:checked ~ .custom-control-label {
  border: var(--custom-switch-custom-control-input-checked-custom-control-label-border-color) solid 1px; }

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--custom-switch-custom-control-input-checked-custom-control-label--before-border-color);
  background-color: var(--custom-switch-custom-control-input-checked-custom-control-label--before-bg-color); }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2bbb54+1,198d3a+49,2bbb54+100 */
  background: var(--custom-switch-custom-control-input-checked-custom-control-label--after-background-color-base);
  /* Old browsers */
  background: var(--custom-switch-custom-control-input-checked-custom-control-label--after-background-color-old-browser);
  /* FF3.6-15 */
  background: var(--custom-switch-custom-control-input-checked-custom-control-label--after-background-color-ff3-browser);
  /* Chrome10-25,Safari5.1-6 */
  background: var(--custom-switch-custom-control-input-checked-custom-control-label--after-background-color-chrome-browser);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2bbb54', endColorstr='#2bbb54',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  -webkit-transform: translateX(2.2rem);
  transform: translateX(2.2rem); }

/*.custom-control.custom-radio .custom-control-label::after,
.custom-control.custom-radio .custom-control-label::before{ width: 19px; height: 19px; }*/
.custom-control {
  min-height: inherit;
  padding-left: 30px; }

.custom-control-label::before, .custom-control-label::after {
  top: 0.16rem;
  left: -1.8rem; }

.custom-control.custom-radio .custom-control-label::before {
  border: var(--custom-control-custom-radio-custom-control-label--before-border-color) solid 1px;
  height: 14px;
  width: 14px; }

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none; }

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--custom-control-custom-radio-custom-control-input-checked-custom-control-label--before-border-color);
  background-color: var(--custom-control-custom-radio-custom-control-input-checked-custom-control-label--before-background-color); }

.red-radio-btn.custom-control.custom-radio .custom-control-label::before {
  border: var(--red-radio-btn-custom-control-custom-radio-custom-control-label--before-border-color) solid 1px; }

.red-radio-btn.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none; }

.red-radio-btn.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--red-radio-btn-custom-control-custom-radio-custom-control-input-checked-custom-control-label--before-border-color);
  background-color: var(--red-radio-btn-custom-control-custom-radio-custom-control-input-checked-custom-control-label--before-background-color); }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
  background-color: var( --custom-file-input-lang-en-custom-file-label--after-background-color);
  color: #FFFFFF; }

.our-custom.custom-file {
  /*width:100%; max-width: 150px;*/
  cursor: pointer;
  height: auto; }

.our-custom.custom-file.martop {
  margin-top: 10px; }

.our-custom.custom-file.width-Auto {
  max-width: 100%;
  margin-right: 0; }

.our-custom.custom-file .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
  background-color: var(--our-custom-custom-file-custom-file-input-lang-en-custom-file-label--after);
  display: none; }

.our-custom.custom-file .custom-file-label::after {
  display: none; }

.our-custom.custom-file .custom-file-input {
  min-width: 100%;
  z-index: -1;
  height: auto; }

.our-custom.custom-file .custom-file-label {
  text-align: center;
  cursor: pointer;
  height: auto;
  min-height: 26px;
  padding: 15px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.our-custom.custom-file .custom-file-label i {
  margin-right: 5px; }

.our-custom.custom-file .custom-file-label:hover {
  background-color: var(--our-custom-custom-file-custom-file-label--hover-bg-color);
  color: var(--our-custom-custom-file-custom-file-label--hover-color); }

.our-custom.custom-file .custom-file-label:hover i {
  color: var(--our-custom-custom-file-custom-file-label--hover-i-color); }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--custom-control-input-focus-not-checked-custom-control-label--before-border-color); }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: var(--custom-control-input-not-disabled-active-custom-control-label--before-color);
  background-color: var(--custom-control-input-not-disabled-active-custom-control-label--before-background-color);
  border-color: var(--custom-control-input-not-disabled-active-custom-control-label--before-border-color); }

/*.custom-control-input:focus:not(:checked)~.custom-control-label::before,
.custom-control-input:not(:disabled):active~.custom-control-label::before{ background-color: inherit; }*/
.mCSB_scrollTools {
  opacity: 1;
  right: 0px;
  -webkit-overflow-scrolling: touch; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #a9a9a9;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px; }

/*.mCSB_scrollTools .mCSB_dragger{ height: 25px !important;}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{width: 14px; border:#132439 solid 3px;  -webkit-border-radius: 50px;  -moz-border-radius: 50px;  border-radius: 50px; background-color: #FFFFFF;}
*/
.mCustomScrollbar {
  height: 100%;
  max-height: 100%;
  -webkit-overflow-scrolling: touch; }

.mCSB_inside > .mCSB_container {
  margin-right: 15px; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
  background-color: var(--mCSB_scrollTools-mCSB_dragger-mCSB_dragger_bar-bg-color);
  border: var(--mCSB_scrollTools-mCSB_dragger-mCSB_dragger_bar-border-color) solid 1px; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:focus .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: var(--mCSB_scrollTools-mCSB_dragger-hover-focus-active-mCSB_dragger_onDrag-mCSB_dragger_bar-bg-color);
  border: var(--mCSB_scrollTools-mCSB_dragger-hover-focus-active-mCSB_dragger_onDrag-mCSB_dragger_bar-border-color) solid 1px; }

.mCSB_draggerRail {
  display: none; }

.mCustomScrollbar-area .mCustomScrollbar {
  max-height: 95%; }

.mCustomScrollbar-area .mCustomScrollbar.small-scroll {
  max-height: 85%; }

.table th, .table thead th {
  vertical-align: middle;
  border-top: 1px solid var(--table-th-table-thead-th-border-top-color);
  font-size: 11px;
  letter-spacing: 0.22px;
  color: var(--table-th-table-thead-th-color);
  font-weight: 400;
  line-height: 16px;
  padding: 10px 6px; }

.table td p, .table td {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  vertical-align: middle; }

.table-striped tbody tr:nth-of-type(odd) {
  background: var(--table-striped-tbody-tr-nth-of-type-background-color) 0% 0% no-repeat padding-box; }

.table tr.active-tr {
  background: var(--table-tr-active-tr-background-color) !important; }

.table-striped tbody tr.gray-strip {
  background: var(--table-striped-tbody-tr-gray-strip-background-color) 0% 0% no-repeat padding-box; }

.table-striped tbody tr.white-strip {
  background: var(--table-striped-tbody-tr-white-strip-background-color) 0% 0% no-repeat padding-box; }

.nav-pills {
  padding-top: 10px;
  margin-bottom: 20px; }

.nav-pills .nav-link {
  background-color: inherit;
  border: none;
  border-bottom: var(--nav-pills-nav-link-border-bottom-color) solid 1px;
  border-radius: 0;
  padding: 5px 15px;
  position: relative;
  display: inline-block;
  margin-right: -6px; }

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus,
.nav-pills .show > .nav-link {
  background-color: inherit;
  border: none;
  border-bottom: var(--nav-pills-nav-link-active-hover-focus-show-nav-link-border-bottom-color) solid 1px;
  color: var(--nav-pills-nav-link-active-hover-focus-show-nav-link-color); }

.tab-content {
  border: none; }

section {
  position: relative;
  padding: 0 0 30px 0; }

.modal .modal-dialog {
  max-width: 840px;
  width: 100%;
  margin: 100px auto 10px auto; }

.modal .modal-dialog .modal-content {
  border-radius: 0px;
  border: none;
  /*
  box-shadow: 0px 3px 6px var(--modal-modal-dialog-modal-content-box-shadow-color); 
  */
}

.modal .modal-dialog .modal-content .modal-body {
  height: 100%;
  min-height: 699px; }

.modal .close, .modal-header .close {
  font-size: 36px;
  border: var(--modal-close-modal-header-close-border-color) solid 2px;
  height: 40px;
  line-height: 32px;
  width: 40px;
  border-radius: 100%;
  text-shadow: none;
  opacity: 100;
  position: relative;
  margin: 0;
  padding: 5px 5px;
  color: var(--modal-close-modal-header-close-color);
  text-align: center;
  display: inline-block;
  z-index: 1; }

.modal-header {
  border: 0;
  padding: 15px;
  justify-content: flex-end; }

.alert-light {
  box-shadow: 0px 10px 30px var(--alert-light-box-shadow-color);
  border-radius: 20px;
  color: var(--alert-light-color);
  z-index: 9; }

.alert-light p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  font-weight: 400;
  margin-bottom: 0; }

.accordion .card {
  border: none; }

.accordion .card .card-body {
  padding: 0; }

.accordion > .card > .card-header {
  margin-bottom: 0;
  background-color: inherit;
  padding: 15px â€‹15px; }

.accordion > .card > .card-header .btn {
  min-height: inherit; }

.accordion > .card > .card-header button::before {
  transform: rotate(90deg);
  content: "\e909";
  color: var(--accordion-card-card-header-button--before-color);
  position: absolute;
  right: 15px;
  font-size: 14px;
  font-family: 'swetrack' !important; }

.accordion > .card > .card-header button.collapsed::before {
  transform: rotate(0deg);
  content: "\e909";
  color: var( --accordion-card-card-header-button-collapsed--before-color);
  position: absolute;
  right: 5px;
  font-size: 14px;
  font-family: 'swetrack' !important; }

.alert-dismissible .close {
  opacity: 100; }

.btn-group {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  overflow: hidden;
  border: 0.5px solid var(--btn-group-base-border-color);
  border-radius: 11px;
  text-align: center; }

.btn-group > .btn.btn-link {
  background-color: inherit;
  min-height: inherit;
  color: var(--btn-group-btn-btn-link-color);
  margin: 0;
  line-height: inherit;
  width: 100%;
  max-width: 150px;
  padding: 10px 10px; }

.btn-group > .btn.btn-link:first-child {
  border-right: 0.5px solid var(--btn-group-btn-btn-link-first-child-border-right-color); }

.datepicker, .ui-timepicker-standard {
  min-width: 365px;
  background-color: var(--datepicker-ui-timepicker-standard-background-color);
  padding: 15px;
  border-radius: 10px; }

.datepicker table {
  width: 100%;
  min-width: 100%;
  color: var(--datepicker-table-color); }

.datepicker table.table-condensed thead tr:nth-child(2) {
  border-radius: 8px;
  background: var(--datepicker-table-table-condensed-thead-tr-nth-child-2-background-color-base);
  /* Old browsers */
  background: var(--datepicker-table-table-condensed-thead-tr-nth-child-2-background-color-old-browser);
  /* FF3.6-15 */
  background: var(--datepicker-table-table-condensed-thead-tr-nth-child-2-background-color-ff3-browser);
  /* Chrome10-25,Safari5.1-6 */
  background: var(--datepicker-table-table-condensed-thead-tr-nth-child-2-background-color-chrome-browser);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f68', endColorstr='#324f68',GradientType=0 );
  /* IE6-9 */ }

.datepicker table.table-condensed thead tr:nth-child(2) th {
  color: var(--datepicker-table-table-condensed-thead-tr-nth-child-2-th-color); }

.datepicker table.table-condensed thead tr:nth-child(3) {
  background-color: var(--datepicker-table-table-condensed-thead-tr-nth-child-3-background-color); }

.datepicker table.table-condensed thead tr th {
  text-align: center; }

.datepicker table tr td, .datepicker table tr th {
  color: var(--datepicker-table-tr-td-datepicker-table-tr-th-color);
  width: 45px;
  height: 46px;
  border-radius: 0; }

.datepicker table.table-condensed thead tr:nth-child(2) th:nth-child(1) {
  border-radius: 8px 0px 0px 8px; }

.datepicker table.table-condensed thead tr:nth-child(2) th:nth-child(3) {
  border-radius: 0px 8px 8px 0px; }

.datepicker table tr td.day:hover, .datepicker table tr td.focused {
  background-color: var(--datepicker-table-tr-td-day-hover-datepicker-table-tr-td-focused-background-color);
  border-radius: 100%; }

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active {
  background-color: var(--datepicker-table-tr-td-active-active-active-highlighted-active-active-active-background-color);
  border-color: var(--datepicker-table-tr-td-active-active-active-highlighted-active-active-active-border-color);
  border-radius: 100%; }

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background-color: inherit; }

.ui-timepicker-standard a {
  color: var(--ui-timepicker-standard-a-color); }

.ui-timepicker-standard a.ui-state-hover {
  background-color: var(--ui-timepicker-standard-a-ui-state-hover-background-color);
  border: var(--ui-timepicker-standard-a-ui-state-hover-border) solid 1px; }

.modal {
  z-index: 2500; }

.nav-tabs {
  border: none;
  justify-content: space-around;
  padding: 15px 0 15px 0; }

.nav-tabs .nav-link {
  border: none;
  font-size: 26px;
  font-weight: 800;
  color: var(--nav-tabs-nav-link-color);
  text-transform: uppercase; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  color: var(--nav-tabs-nav-item-show-active-hover-nav-link-color); }

/**** Header **/
.headerSection {
  padding: 0 0px 0 0px;
  position: relative;
  /*
    Updated from 99999 to 9990
    from Mari on 6/07/21
  */
  z-index: 9800;
  background-color: var(--header-headerSection-base-background-color); }

.headerSection.border-botm {
  border-bottom: var(--header-headerSection-border-botm-border-bottom-color) solid 1px; }

.headerSection .minheight {
  height: 100%;
  min-height: 100px; }

.navbar-brand {
  padding: 0px;
  margin-right: 0;
  font-size: var(--header-navbar-brand-font-size);
  font-weight: 700;
  color: var(--header-navbar-brand-color);
  text-transform: uppercase; }

.headerSection .minheight .dropdown-toggle::after {
  display: none; }

.headerSection .minheight .dropdown-toggle .icon-user {
  font-size: var(--header-headerSection-minheight-dropdown-toggle-icon-user); }

.navbar-brand {
  width: 100%;
  max-width: 310px;
  margin: 0 auto;
  font-size: 22px;
  /*
  display: block;
  */ 
}

.navbar-brand img {
  max-height: var(--header-navbar-brand-img-max-height);
  max-width: var(--header-navbar-brand-img-max-width);
}

@media screen and (min-width: 768px) {
  .navbar-brand img {
    max-height: var(--header-navbar-brand-img-min-width-768px-max-height);
    max-width: var(--header-navbar-brand-img-min-width-768px-max-width);
  }
}

.navbar-brand.auto-width {
  display: inline-block;
  max-width: 180px; }

.navbar-brand-middle {
  display: block;
  max-width: var(--header-navbar-brand-middle-767);
  margin: 0 auto; }

.navbar-brand-middle.textual-area {
  max-width: inherit; }

.navbar-brand-middle.textual-area span {
  font-size: var(--header-navbar-brand-middle-textual-area-span-font-size);
  font-family: 'Sansa Pro';
  font-weight: 800;
  color: var(--header-navbar-brand-middle-textual-area-span-color); }

.navbar {
  padding: 0; }

.navbar .navbar-nav {
  margin-top: var(--header-navbar-navbar-nav-margin-top); }

.navbar .navbar-nav li.nav-item {
  display: block; }

.navbar .navbar-nav li.nav-item > a.nav-link {
  padding: 15px 0;
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: var(--header-navbar-navbar-nav-li-nav-item-a-nav-link-color);
  font-weight: 600;
  text-transform: uppercase; }

.navbar .navbar-nav li.nav-item > a.nav-link i {
  color: var(--header-navbar-navbar-nav-li-nav-item-a-nav-link-i-color); }

.navbar .navbar-nav li.nav-item > a.nav-link:hover,
.navbar .navbar-nav li.nav-item > a.nav-link.active,
.navbar .navbar-nav li.nav-item.show > a.nav-link {
  color: var(--header-navbar-navbar-nav-li-nav-item-a-nav-link-hover-active-show-a-nav-link-color); }

.navbar .navbar-nav li.nav-item > a.nav-link:hover i,
.navbar .navbar-nav li.nav-item > a.nav-link.active i,
.navbar .navbar-nav li.nav-item.show > a.nav-link i {
  color: var(--header-navbar-navbar-nav-li-nav-item-a-nav-link-hover-active-show-a-nav-link-i-color); }

.navbar .navbar-nav .active > .nav-link,
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .show > .nav-link,
.navbar .navbar-nav .open > .nav-link {
  color: var(--header-navbar-navbar-nav-active-nav-link-active-show-show-open-nav-link-color); }

.navbar .navbar-collapse {
  position: relative; }

.navbar-nav .dropdown-menu {
  min-width: 250px;
  /*left: auto; right: 0;*/
  padding: 10px 20px;
  color: var(--header-navbar-nav-dropdown-menu-color);
  border-radius: 0;
  border: none;
  margin: 0px 0 0 0; }

.navbar-nav li .dropdown-menu {
  min-width: 250px;
  padding: 10px 20px;
  color: var(--header-navbar-nav-li-dropdown-menu-color);
  border-radius: 0;
  border: none;
  margin: 0px 0 0 0; }

.navbar-nav li .dropdown-item:focus, .navbar-nav li .dropdown-item:hover {
  color: var(--header-navbar-nav-li-dropdown-item-focus-navbar-nav-li-dropdown-item-hover-color);
  background-color: var(--header-navbar-nav-li-dropdown-item-focus-navbar-nav-li-dropdown-item-hover-background-color); }

.navbar-nav li .dropdown-item {
  color: var(--header-navbar-nav-li-dropdown-item-color);
  border-bottom: var(--header-navbar-nav-li-dropdown-item-border-bottom) solid 1px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 16px;
  position: relative; }

.navbar-nav li .dropdown-item:before {
  position: absolute;
  background-color: var(--header-navbar-nav-li-dropdown-item--before-background-color);
  height: 5px;
  width: 5px;
  left: 0;
  top: 12px;
  border-radius: 100%;
  content: ""; }

.navbar-nav li .dropdown-item:last-child {
  margin-bottom: 5px; }

/*.navbar-nav li .dropdown-toggle::after{ display: none; }*/
#triangle-down:after {
  position: absolute;
  left: 22px;
  top: -12px;
  width: 0;
  height: 0;
  content: '';
  border-left: 8px solid var(--header-triangle-down--after-border-left-color);
  border-right: 8px solid var(--header-triangle-down--after-border-right-color);
  border-top: 8px solid var(--header-triangle-down--after-boder-top-color); }

.navbar-nav li .dropdown-menu.login-form {
  padding: 20px 20px; }

.navbar-nav li .dropdown-menu.login-form .btn {
  font-size: 14px;
  padding: 5px 20px;
  width: 100%;
  text-transform: uppercase; }

.headerSection.menu-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9800;
  border-bottom: var(--header-headerSection-menu-fixed-border-bottom-color) solid 1px; 
}

.headerSection.fixed { 
  position: fixed;
  width: 100%;
  max-height: 140px;
  top: 0;
}

.headerSection.fixed.centered-absolute {
  left: auto;
  right: auto;
}

@media screen and (min-width: 768px) {  
  .headerSection.fixed.centered-absolute {
    left: 25%;
    right: 25%;
  }
}

.headerSection.fixed.centered-absolute ~ .middle-content {
  padding-top: 140px;
}

.headerSection.fixed.no-logged ~ .middle-content {
  padding-top: 100px;
}

.headerSection.fixed ~ .middle-content {
  padding-top: 65px;
}

@media screen and (min-width: 768px) {  
  .headerSection.fixed.centered-absolute ~ .middle-content {
    padding-top: 140px;
  }

  .headerSection.fixed.no-logged ~ .middle-content {
    padding-top: 100px;
  }
  
  .headerSection.fixed ~ .middle-content {
    padding-top: 100px;
  }
}

/*.headerSection.menu-fixed .navbar-brand{ max-width: 130px; }
.headerSection.menu-fixed .top-head-area{ padding: 5px 0; }
.headerSection.menu-fixed .top-head-area .default-heading h5{ font-size: 24px; }
.headerSection.menu-fixed .middle-content.paddTop{ padding-top: 104px; }*/
.lagguage-change {
  position: relative;
  width: 100px; }

.lagguage-change .dropdown-menu {
  margin-top: 10px; }

.lagguage-change .nav-link.dropdown-toggle {
  color: var(--lagguage-change-nav-link-dropdown-toggle-color);
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  line-height: 0;
  width: 100%;
  max-width: 60px; }

.lagguage-change .dropdown-toggle::after {
  display: none; }

.lagguage-change i {
  font-size: 12px;
  line-height: 0; }

.logo-area {
  width: 150px; }

.dropdown-submenu:hover ul {
  display: block;
  left: 100%; }

.dropdown-submenu {
  position: relative; }

.dropdown-submenu:hover .dropdown-menu {
  top: 0; }

.humbarger-arrow-area {
  position: relative;
  left: 0;
  z-index: 1;
  cursor: pointer;
  /*display: none; */
  display: inline-block; }

.humbarger-arrow-area .icon-menu-bar {
  font-size: var(--header-humbarger-arrow-area-icon-menu-bar-font-size); }

.humbarger {
  position: relative;
  display: inline-block; }

.humbarger-arrow-area .humbarger.closed .center-border {
  display: none; }

.humbarger-arrow-area .humbarger.closed:before {
  top: 0px;
  transform: rotateZ(45deg); }

.humbarger-arrow-area .humbarger.closed:after {
  transform: rotateZ(-45deg);
  bottom: -3px; }

.center-border {
  height: 3px;
  width: 33px;
  background-color: var(--header-center-border-background-color);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.humbarger:before {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 3px;
  width: 33px;
  background-color: var(--header-humbarger--before-background-color);
  content: ""; }

.humbarger:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 3px;
  width: 33px;
  background-color: var(--header-humbarger--after-background-color);
  content: ""; }

.top-head-area {
  position: relative;
  padding: 0px 0 0px 0; }

.top-head-area .default-heading h5 {
  letter-spacing: 2px;
  font-weight: 400;
  color: var(--header-top-head-area-default-heading-h5-color);
  text-transform: uppercase; }

.top-head-area .default-heading.main-heading {
  font-weight: 600;
  text-transform: capitalize; }

.top-head-area .humbarger-arrow-area {
  position: absolute; }

.carousel-inner {
  height: 100%; }

.carousel-item {
  height: 100%;
  background: no-repeat;
  background-size: cover;
  background-position: top center; }

.carousel-item:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.5;
  background-color: var(--carousel-item--before-background-color); }

.carousel-item img {
  width: 100%; }

.carousel, .item {
  height: 100%;
  vertical-align: middle; }

.carousel-inner > .item img {
  width: 100%;
  height: 100%;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover; }

.carousel-caption {
  text-align: left;
  width: 100%;
  padding: 10px 0;
  max-width: 100%;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: 0 auto;
  bottom: auto;
  padding-bottom: 0; }

.slider-text-area {
  width: 100%;
  max-width: 70%;
  padding-top: 100px; }

.slider-text-area .default-heading h1,
.slider-text-area .default-heading a {
  margin-bottom: 0;
  line-height: 65px;
  letter-spacing: 1.5px; }

.slider-text-area .btn {
  margin-top: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  max-width: 500px;
  width: 100%;
  padding: 15px 25px; }

.slider-text-area p {
  line-height: 16px;
  color: var(--slider-text-area-p-color);
  margin-bottom: 0; }

.carousel-indicators {
  left: auto;
  bottom: 25px; }

.carousel-indicators li {
  width: 12px;
  height: 12px;
  border: var(--carousel-indicators-li-border-color) solid 1px;
  background-clip: inherit;
  border-radius: 100%;
  opacity: 1; }

.carousel-indicators li:hover,
.carousel-indicators li.active {
  background-color: var(--carousel-indicators-li-hover-active-background-color);
  border: var(--carousel-indicators-li-hover-active-border-color) solid 1px; }

/*.carousel.slide,  .carousel.slide .carousel-inner, .carousel.slide .carousel-inner .carousel-item{ min-height:550px;}*/
.middle-content {
  position: relative; }

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  /*display: flex; flex-wrap: wrap;*/
  height: 100%;
  width: 100%;
  margin-right: -390px;
  max-width: 390px;
  background-color: var(--sidebar-background-color);
  margin-left: 0px;
  transition: margin-right .5s linear; }

.sidebar.paddNone {
  padding-top: 0; }

.sidebar.zindex-increased {
  z-index: 9990; }

.sidebar.shrink {
  margin-right: 0; }

.sidebar .navbar {
  padding: 20px; }

.sidebar.sidebar-hide-desktop {
  display: none; }

.signout-area {
  position: relative;
  padding: 20px;
  text-align: center; }

.signout-area a {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--sidebar-ignout-area-a-color); }

.signout-area a .icon-close-circle {
  color: var(--sidebar-ignout-area-a-icon-close-circle-color);
  font-size: 36px;
  display: block;
  margin-bottom: 10px; }

.closed-arrow {
  right: 15px;
  top: 15px;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  max-width: 24px;
  width: 100%;
  z-index: 1; }

.left-area {
  position: relative;
  width: 100%;
  max-width: 310px; }

.box-area {
  width: 100%;
  max-width: 310px;
  margin-bottom: 20px;
  background: var(--box-area-base-background-color);
  text-align: center;
  border-radius: 15px;
  min-height: 260px; }

/*
#sohappy .left-area .box-area.infanital-ciclo,
#sohappy .left-area .box-area.menu-general {
  max-height: 120px;
  display: inline-block !important;
  max-width: 47.6%;
  margin-right: 9px;
  vertical-align: top
} 

#sohappy .left-area .box-area.menu-general {
  margin-right: 0px;
}
*/ 

.box-area .icon {
  font-size: 82px;
  display: block;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 50px; }

.box-area .icon.img {
  display: block;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 50px;
  max-height: auto;
  line-height: 1; 
}

.box-area .icon .img {
  height: var(--box-area-icon-img-height);
  width: var(--box-area-icon-img-width);
}

.create-profile .icon .img {
  height: var(--create-profile-icon-img-height);
  width: var(--create-profile-icon-img-width);
}

.box-area .default-heading {
  margin-bottom: 50px; }

.box-area .default-heading h2 {
  font-size: 24px;
  line-height: 28px;
  font-family: 'Sansa Pro';
  font-weight: 300;
  color: var(--box-area-default-heading-h2-color);
  display: block;
  text-transform: uppercase; }

.box-area .icon-small-arrow-right:before,
.box-area .icon-small-arrow-left:before {
  color: var(--box-area-icon-small-arrow-right-left--before); }

.box-area .small-text-area {
  text-align: center;
  padding: 5px 0;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--box-area-small-text-area-color); }

.box-area .small-text-area.top {
  top: 10px; }

.box-area .small-text-area.bottom {
  bottom: 10px; }

.box-area .small-text-area .text {
  display: none; }

.box-area:hover .small-text-area .text {
  display: block; }

.box-area .owl-nav {
  display: none; 
}

/*
.box-area .owl-nav {
  display: none; 
}
*/

.box-area .owl-nav {
  display: block; 
  font-size: 22px;
}

.box-area:hover .owl-nav {
  display: block; }

.box-area.menu-general {
  background: var(--box-area-menu-general-background); }

.box-area.download-area {
  background: var(--box-area-download-area-background);
  padding: 10px;
  min-height: inherit; }

.box-area.download-area .dotted-border {
  border: var(--box-area-download-area-dotted-border-background-color) dashed 2px;
  padding: 5px; }

.box-area.download-area .dotted-border .icon-download-arrow {
  font-size: 65px; }

.box-area.download-area .dotted-border .icon-download-arrow:before {
  color: var(--box-area-download-area-dotted-border-icon-download-arrow--before-color); }

.box-area.download-area .dotted-border .year {
  font-size: 18px;
  line-height: 24px;
  font-family: 'Sansa Pro';
  font-weight: 300;
  text-transform: uppercase;
  color: var(--box-area-download-area-dotted-border-year-color); }

.advertisement {
  display: block;
  text-align: center; }

.info-text {
  position: relative;
  display: block;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
  width: 100%; }

.info-text p {
  margin-bottom: 0;
  text-transform: uppercase;
  padding: 15px 0 0px 0; }

.menu-area {
  position: relative; }

.menu-area .default-heading {
  display: block;
  text-align: center;
  padding: 30px 0 15px 0; }

.menu-area .default-heading h1 {
  color: var(--menu-area-default-heading-h1-color);
  font-weight: 600;
  text-transform: uppercase; }

.date-list {
  text-align: center;
  position: relative;
  list-style: none; }

.date-list li {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 20%;
  margin-left: -4px; }

.menu-slider .extra-small-text {
  display: block;
  text-align: center;
  padding: 5px 0; }

.menu-slider .extra-small-text .text {
  text-transform: uppercase;
  font-family: 'Sansa Pro';
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--menu-slider-extra-small-text-text-color); }

.menu-slider .number-counts {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 100%;
  text-align: center;
  display: flex;
  vertical-align: middle;
  font-size: 36px;
  font-weight: bold;
  color: var(--menu-slider-number-counts-color); }

/*.menu-slider .owl-item:hover .number-counts,
.menu-slider .owl-item.active.center .number-counts{ background-color: #d2dc28;  color: #ffffff; }*/
.menu-slider {
  position: relative; }

.menu-slider .owl-item.active.center .date-list li:nth-child(3) .number-counts,
.date-list li:hover .number-counts, .date-list li.active .number-counts,
.menu-slider .date-list .date-li.disabled:hover .number-counts, 
.menu-slider .date-list .date-li.disabled.active .number-counts {
  background-color: var(--menu-slider-owl-item-active-center-date-list-li--nth-child-3-number-counts-background-color);
  color: var(--menu-slider-owl-item-active-center-date-list-li--nth-child-3-number-counts-color); }

.number-counts {
  cursor: pointer; }

.menu-slider .owl-nav .owl-prev {
  left: -5px; }

.menu-slider .owl-nav .owl-next {
  right: -5px; }

.buttons-area {
  position: absolute;
  top: 45%;
  width: 100%;
  left: 0;
  right: 0; }

.buttons-area .arrow-prev {
  position: absolute;
  left: -15px;
  border: none;
  background: none; }

.buttons-area .arrow-next {
  position: absolute;
  right: -15px;
  border: none;
  background: none; }

.buttons-area i {
  font-size: 24px; }

.column-details {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 60px; }

.column-details .icon {
  font-size: 55px;
  margin-bottom: 15px; }

.column-details .default-heading h3 {
  color: var(--column-details-default-heading-h3-color);
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Sansa Pro SmBd';
  font-weight: 600; }

.column-details p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0; }

.information-area {
  position: relative;
  background-color: var(--information-area-background-color);
  padding: 20px 10px 10px 10px;
  text-align: center; }

.information-area.opened {
  display: block; }

.information-area.closed {
  display: none; }

.information-area:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -30px;
  height: 9px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAJBAMAAAAWSsseAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAMUExURf///09flCY6e2l3pB6/ZCAAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTY8AHGBXBFLMyDnkGBgAIgQBJRrqtjwAAAABJRU5ErkJggg==);
  border: none;
  /*
  border: var(--information-area--after-border-color) dashed 1px; 
  */
}

.information-area .default-heading h2,
.rate-recipe-area-container .default-heading h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--information-area-default-heading-h2-color);
  display: block;
  text-transform: uppercase; }

.number-list {
  display: block;
  list-style: none;
  text-align: center; }

.number-list li {
  display: inline-block;
  vertical-align: top;
  padding: 10px 15px; }

.number-list li .circle-area {
  height: 68px;
  width: 68px;
  margin-bottom: 20px;
  border-radius: 100%;
  font-size: 24px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  background-color: var(--number-list-li-circle-area-background-color);
  border: var(--number-list-li-circle-area-border);
  color: var(--number-list-li-circle-area-color);
  position: relative; }

.number-list li .circle-area span {
  display: block;
  text-align: center;
  color: var(--number-list-li-circle-area-span-color);
  font-size: 16px;
  line-height: 1;
  position: absolute;
  bottom: 3px;
  width: 100%; }

.number-list li .circle-area:hover span {
  color: var(--number-list-li-circle-area--hover-span-color); }

.number-list li .circle-area.blue.active,
.number-list li .circle-area.blue:hover {
  background-color: var(--number-list-li-circle-area-blue-active-blue--hover-background-color);
  color: var(--number-list-li-circle-area-blue-active-blue--hover-color); }

.number-list li .circle-area.yellow {
  border-color: var(--number-list-li-circle-area-yellow-border-color); }

.number-list li .circle-area.yellow.active,
.number-list li .circle-area.yellow:hover {
  background-color: var(--number-list-li-circle-area-yellow-active-yellow--hover-background-color);
  color: var(--number-list-li-circle-area-yellow-active-yellow--hover-color); }

.number-list li .circle-area.orange {
  border-color: var(--number-list-li-circle-area-orange-border-color); }

.number-list li .circle-area.orange.active,
.number-list li .circle-area.orange:hover {
  background-color: var(--number-list-li-circle-area-orange-active-orange--hover-background-color);
  color: var(--number-list-li-circle-area-orange-active-orange--hover-color); }

.number-list li .circle-area.pink {
  border-color: var(--number-list-li-circle-area-pink-border-color); }

.number-list li .circle-area.pink.active,
.number-list li .circle-area.pink:hover {
  background-color: var(--number-list-li-circle-area-pink-active-pink--hover-background-color);
  color: var(--number-list-li-circle-area-pink-active-pink--hover-color); }

.number-list li .name {
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--number-list-li-name-color); }

.recomendaciones-area {
  position: relative;
  margin-top: 60px;
  text-align: center; }

.recomendaciones-area .default-heading h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--recomendaciones-area-default-heading-h2-color);
  display: block;
  text-transform: uppercase; }

.right-area {
  position: relative;
  width: 100%;
  max-width: 310px;
  margin-left: auto; 
}

/*Added by Mari on 23/01/23 to fix right-area alignment on tablet*/
@media screen and (max-width: 992px) {
  .right-area {
    margin-right: auto; 
  }
}

.right-area .box-area.info-box {
  background-color: var(--right-area-box-area-info-box-background-color);
  color: var(--right-area-box-area-info-box-color);
  padding: 30px 25px;
  margin-left: auto; }

.right-area .box-area.info-box .default-heading {
  margin-bottom: 35px; }

.right-area .box-area.info-box .default-heading h2 {
  color: var(--right-area-box-area-info-box-default-heading-h2-color); }

.right-area .box-area.info-box p {
  color: var( --right-area-box-area-info-box-p-color);
  font-family: 'Sansa Pro';
  font-weight: 300; }

.right-area .recomendaciones-area .box-area.advices-box {
  background-color: var(--right-area-box-area-advices-box-background-color);
  color: var(--right-area-box-area-advices-box-color);
  padding: 30px 25px;
  margin-left: auto; }

.right-area .recomendaciones-area .box-area.advices-box .default-heading {
  margin-bottom: 35px; }

.right-area .recomendaciones-area .box-area.advices-box .default-heading h2 {
  color: var(--right-area-box-area-advices-box-default-heading-h2-color); }

.right-area .recomendaciones-area .box-area.advices-box p {
  color: var( --right-area-box-area-advices-box-p-color);
  font-family: 'Sansa Pro';
  font-weight: 300; }

.activevades {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  margin-left: auto;
  max-width: 310px; }

.activevades .default-heading h2 {
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 15px;
  font-weight: 700;
  color: var(--activevades-default-heading-h2-color);
  display: block;
  text-transform: uppercase; }

.box-area.suggestion-message {
  background-color: var(--box-area-suggestion-message-background-color);
  padding: 10px 15px;
  min-height: inherit;
  margin-left: auto; }

.box-area.suggestion-message .icon-email {
  font-size: 50px; }

.box-area.suggestion-message .icon-email:before {
  color: var(--box-area-suggestion-message-icon-email--before-color); }

.box-area.suggestion-message .default-heading {
  margin-bottom: 0px;
  margin-top: 10px; }

.box-area.suggestion-message .default-heading h2 {
  color: var(--box-area-suggestion-message-default-heading-h2-color); }

.box-area.suggestion-message p {
  color: var(--box-area-suggestion-message-p-color);
  margin-bottom: 0;
  font-weight: 14px; }

.box-area.suggestion-message .default-heading h2 {
  font-size: 18px; }

.right-area .advertisement {
  max-width: 310px;
  margin-left: auto; }

/**** Cover Page *****/
.language-change {
  position: relative;
  text-align: right; }

.language-change a {
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px; }

.cover-area {
  position: relative;
  padding: 30px 0; }

.cover-area .navbar-brand-middle {
  max-width: 230px;
  margin-bottom: 50px; }

.desktop-screen, .mobile-screen {
  display: block;
  text-align: center; }

.desktop-screen span, .mobile-screen span {
  font-size: 180px; }

.desktop-screen span {
  font-size: 200px; }

/**** Form Area ****/
.form-area {
  position: relative; }

.head-area {
  position: relative;
  display: block;
  margin: 30px 0; }

.head-area .default-heading h3 {
  color: var(--head-area-default-heading-h3-color);
  font-weight: 600; }

.head-area .default-heading h2 {
  color: var(--head-area-default-heading-h2-color);
  font-weight: 300;
  text-align: center; }

.head-area .default-heading h2 strong {
  font-weight: 800;
  display: block; }

.register-here {
  display: block;
  text-align: right; }

.register-here a {
  color: var(--register-here-a-color);
  text-transform: uppercase;
  font-weight: 600; 
  padding-bottom: 3px;
  border-bottom: 1px solid var(--register-here-a-border-bottom-color);
}

.btns-area {
  text-align: center;
  position: relative;
  display: block; }

.btns-area a {
  display: block;
  color: var(--btns-area-a-color);
  padding: 15px 0;
  font-size: 12px; }

.graphic {
  position: absolute;
  top: 0;
  z-index: 9900; 
}

.graphic.left-graphic {
  left: -30px;
  width: 100%;
  max-width: 300px; }

.graphic.right-graphic {
  right: -30px;
  width: 100%;
  max-width: 300px; }

/**** Confirmation ****/
.body-color {
  background-color: var(--body-color-background-color); }

.confirmation {
  background-color: var(--confirmation-base-background-color);
  padding: 15px;
  border-radius: 20px;
  margin: 20px auto;
  width: 100%;
  max-width: 600px; }

.confirmation .form-group label {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: var(--confirmation-form-group-label-color); }

.confirmation .form-area {
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px; }

.confirmation .footer-area {
  padding: 0; }

.all-graphic {
  text-align: center;
  display: block;
  margin-bottom: -10px; }

/**** Profile ****/
.create-profile {
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--create-profile-base-background-color); }

.create-profile .head-area {
  padding: 30px 0 20px 0;
  margin: 0; }

.create-profile .row-area {
  margin-bottom: 20px; }

.create-profile .row-area .default-heading h3 {
  color: var(--create-profile-row-area-default-heading-h3-color);
  margin-bottom: 10px; }

.create-profile .row-area .column-details {
  margin-bottom: 0px; }

.create-profile .row-area.top-row {
  padding: 0 120px; }

.create-profile .row-area.top-row .column-details .icon {
  font-size: 46px;
  margin-bottom: 10px;
  cursor: pointer; }

.create-profile .row-area.top-row .column-details .default-heading h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600; }

.create-profile .row-area.bottom-row .column-details .icon {
  margin-bottom: 10px;
  position: relative;
  cursor: pointer; }

  /* 
To fix the icon position on Safari (for some reason the relative value breaks all of the on that browser
*/
@supports (-webkit-hyphens: none) {
  .create-profile .row-area.bottom-row span.icon span[class*="path"] {
    position: initial;
  }
}

.create-profile .row-area.bottom-row .column-details .icon:hover:before,
.create-profile .row-area.bottom-row .column-details .icon.active:before {
  z-index: 0;
  border-radius: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: 55px;
  width: 55px;
  background-color: var(--create-profile-row-area-bottom-row-column-details-icon--hover--before-icon-active--before-background-color);
  content: ""; }

.create-profile .row-area.top-row .column-details .icon .path3 {
  position: relative; }

.create-profile .row-area.top-row .column-details .icon:hover .path3:after,
.create-profile .row-area.top-row .column-details .icon.active .path3:after {
  z-index: -3;
  border-radius: 100%;
  position: absolute;
  left: -40px;
  bottom: 0;
  right: 0;
  top: 5px;
  height: 36px;
  width: 33px;
  background-color: var(--create-profile-row-area-top-row-column-details-icon--hover-path3--after-icon-active-path3--after-background-color);
  content: ""; }

.create-profile .row-area.top-row .column-details .icon:hover .path1:after,
.create-profile .row-area.top-row .column-details .icon.active .path1:after {
  z-index: -3;
  border-radius: 15px 0px 0px 15px;
  position: absolute;
  left: 0px;
  bottom: 0;
  right: 0;
  top: 18px;
  height: 11px;
  width: 7px;
  background-color: var(--create-profile-row-area-top-row-column-details-icon--hover-path1--after-icon-active-path1--after-background-color);
  content: ""; }

.create-profile .row-area.top-row .column-details .icon:hover .path2:after,
.create-profile .row-area.top-row .column-details .icon.active .path2:after {
  z-index: -3;
  border-radius: 0 15px 15px 0;
  position: absolute;
  left: auto;
  bottom: 0;
  right: 0;
  top: 18px;
  height: 11px;
  width: 7px;
  background-color: var(--create-profile-row-area-top-row-column-details-icon--hover-path2--after-icon-active-path2--after-background-color);
  content: ""; }

.create-profile .row-area.top-row .column-details .icon.icon-user .path1:after,
.create-profile .row-area.top-row .column-details .icon.icon-user .path2:after {
  top: 20px; }

.create-profile .row-area.top-row .column-details .icon.icon-user .path3:after {
  top: 7px; }

.create-profile .row-area.bottom-row .column-details .default-heading h3 {
  font-size: 13px;
  line-height: 18px; }

/* 
To fix the icon position on Safari (for some reason the relative value breaks all of the on that browser
*/
@supports (-webkit-hyphens: none) {
  .create-profile .row-area.top-row .column-details .icon:hover:before,
  .create-profile .row-area.top-row .column-details .icon.active:before,
  .create-profile .row-area.bottom-row .column-details .icon:hover:before,
  .create-profile .row-area.bottom-row .column-details .icon.active:before {
    display: none;
  }

  .create-profile .row-area.top-row .column-details .icon:hover,
  .create-profile .row-area.top-row .column-details .icon.active,
  .create-profile .row-area.bottom-row .column-details .icon:hover,
  .create-profile .row-area.bottom-row .column-details .icon.active {
    z-index: 0;
    border-radius: 100%;
    height: 55px;
    width: 55px;
    background-color: var(--create-profile-row-area-bottom-row-column-details-icon--hover--before-icon-active--before-background-color);
  }

  /*
  .create-profile .row-area.top-row .column-details .icon:hover,
  .create-profile .row-area.top-row .column-details .icon.active {
    background-color: var(--create-profile-row-area-bottom-row-column-details-icon--hover--before-icon-active--after-background-color);
  }
  */

  .create-profile .row-area.top-row .column-details .icon .path3 {
    position: unset;
  }

  .create-profile .row-area.top-row .column-details .icon.path1,.create-profile .row-area.top-row .column-details .icon.path2, .create-profile .row-area.top-row .column-details .icon.path3, .create-profile .row-area.top-row .column-details .icon.path4, .create-profile .row-area.top-row .column-details .icon.path5, .create-profile .row-area.top-row .column-details .icon.path6, .create-profile .row-area.top-row .column-details .icon.path7, .create-profile .row-area.top-row .column-details .icon.path8, .create-profile .row-area.top-row .column-details .icon.path9, .create-profile .row-area.top-row .column-details .icon.path10,
  .create-profile .row-area.top-row .column-details .icon.path11, .create-profile .row-area.top-row .column-details .icon.path12, .create-profile .row-area.top-row .column-details .icon.path13, .create-profile .row-area.top-row .column-details .icon.path14, .create-profile .row-area.top-row .column-details .icon.path15, .create-profile .row-area.top-row .column-details .icon.path16, .create-profile .row-area.top-row .column-details .icon.path17, .create-profile .row-area.top-row .column-details .icon.path18, .create-profile .row-area.top-row .column-details .icon.path19, .create-profile .row-area.top-row .column-details .icon.path20,
  .create-profile .row-area.bottom-row .column-details .icon.path1,.create-profile .row-area.bottom-row .column-details .icon.path2, .create-profile .row-area.bottom-row .column-details .icon.path3, .create-profile .row-area.bottom-row .column-details .icon.path4, .create-profile .row-area.bottom-row .column-details .icon.path5, .create-profile .row-area.bottom-row .column-details .icon.path6, .create-profile .row-area.bottom-row .column-details .icon.path7, .create-profile .row-area.bottom-row .column-details .icon.path8, .create-profile .row-area.bottom-row .column-details .icon.path9, .create-profile .row-area.bottom-row .column-details .icon.path10,
  .create-profile .row-area.bottom-row .column-details .icon.path11, .create-profile .row-area.bottom-row .column-details .icon.path12, .create-profile .row-area.bottom-row .column-details .icon.path13, .create-profile .row-area.bottom-row .column-details .icon.path14, .create-profile .row-area.bottom-row .column-details .icon.path15, .create-profile .row-area.bottom-row .column-details .icon.path16, .create-profile .row-area.bottom-row .column-details .icon.path17, .create-profile .row-area.bottom-row .column-details .icon.path18, .create-profile .row-area.bottom-row .column-details .icon.path19, .create-profile .row-area.bottom-row .column-details .icon.path20 {
    z-index: 1;
  }
}

.path1, .path2, .path3, .path4, .path5, .path6, .path7, .path8, .path9, .path10,
.path11, .path12, .path13, .path14, .path15, .path16, .path17, .path18, .path19, .path20 {
  position: relative;
  z-index: 1; 
}

/* 
To fix the icon position on Safari (for some reason the relative value breaks all of the on that browser
*/
@supports (-webkit-hyphens: none) {
  .path1, .path2, .path3, .path4, .path5, .path6, .path7, .path8, .path9, .path10,
  .path11, .path12, .path13, .path14, .path15, .path16, .path17, .path18, .path19, .path20 {
    position: unset;
  }
}

.create-profile .row-area.top-row .column-details .icon-female-user.icon .path1 {
  z-index: 999; }

.create-profile .row-area.bottom-row .column-details .icon:hover .path1:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path2:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path3:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path4:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path5:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path6:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path7:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path8:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path9:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path10:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path11:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path12:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path13:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path14:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path15:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path16:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path17:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path18:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path19:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path20:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path1:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path2:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path3:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path4:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path5:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path6:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path7:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path8:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path9:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path10:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path11:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path12:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path13:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path14:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path15:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path16:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path17:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path18:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path19:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path20:before {
  color: var(--create-profile-row-area-bottom-row-column-details-icon-active-path-1-20-color); }

.create-profile .row-area.top-row .column-details .icon:hover .path1:before,
.create-profile .row-area.top-row .column-details .icon:hover .path2:before,
.create-profile .row-area.top-row .column-details .icon:hover .path3:before,
.create-profile .row-area.top-row .column-details .icon:hover .path4:before,
.create-profile .row-area.top-row .column-details .icon:hover .path5:before,
.create-profile .row-area.top-row .column-details .icon:hover .path6:before,
.create-profile .row-area.top-row .column-details .icon:hover .path7:before,
.create-profile .row-area.top-row .column-details .icon:hover .path8:before,
.create-profile .row-area.top-row .column-details .icon:hover .path9:before,
.create-profile .row-area.top-row .column-details .icon:hover .path10:before,
.create-profile .row-area.top-row .column-details .icon:hover .path11:before,
.create-profile .row-area.top-row .column-details .icon:hover .path12:before,
.create-profile .row-area.top-row .column-details .icon:hover .path13:before,
.create-profile .row-area.top-row .column-details .icon:hover .path14:before,
.create-profile .row-area.top-row .column-details .icon:hover .path15:before,
.create-profile .row-area.top-row .column-details .icon:hover .path16:before,
.create-profile .row-area.top-row .column-details .icon:hover .path17:before,
.create-profile .row-area.top-row .column-details .icon:hover .path18:before,
.create-profile .row-area.top-row .column-details .icon:hover .path19:before,
.create-profile .row-area.top-row .column-details .icon:hover .path20:before,
.create-profile .row-area.top-row .column-details .icon.active .path1:before,
.create-profile .row-area.top-row .column-details .icon.active .path2:before,
.create-profile .row-area.top-row .column-details .icon.active .path3:before,
.create-profile .row-area.top-row .column-details .icon.active .path4:before,
.create-profile .row-area.top-row .column-details .icon.active .path5:before,
.create-profile .row-area.top-row .column-details .icon.active .path6:before,
.create-profile .row-area.top-row .column-details .icon.active .path7:before,
.create-profile .row-area.top-row .column-details .icon.active .path8:before,
.create-profile .row-area.top-row .column-details .icon.active .path9:before,
.create-profile .row-area.top-row .column-details .icon.active .path10:before,
.create-profile .row-area.top-row .column-details .icon.active .path11:before,
.create-profile .row-area.top-row .column-details .icon.active .path12:before,
.create-profile .row-area.top-row .column-details .icon.active .path13:before,
.create-profile .row-area.top-row .column-details .icon.active .path14:before,
.create-profile .row-area.top-row .column-details .icon.active .path15:before,
.create-profile .row-area.top-row .column-details .icon.active .path16:before,
.create-profile .row-area.top-row .column-details .icon.active .path17:before,
.create-profile .row-area.top-row .column-details .icon.active .path18:before,
.create-profile .row-area.top-row .column-details .icon.active .path19:before,
.create-profile .row-area.top-row .column-details .icon.active .path20:before {
  color: var(--create-profile-row-area-bottom-row-column-details-icon-active-path-1-20--before-color); }

.modal-backdrop.show {
  opacity: var(--modal-backdrop-show-opacity);
  background-color: var(--modal-backdrop-show-background-color); }

.back-btn {
  display: block;
  text-align: center; }

.back-arrow {
  width: 45px;
  height: 45px;
  line-height: 45px;
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  background-color: var(--back-arrow-base-background-color); }

.back-btn .custom-checkbox .custom-control-label {
  color: var(--back-btn-custom-checkbox-custom-control-label-color);
  font-weight: 600;
  line-height: 20px; }

.back-arrow i {
  font-size: 24px; }

.back-arrow i:before {
  color: var(--back-arrow-i--before-color); }

/**** Menu Comedy ****/
.inner-area {
  position: relative;
  padding-top: 20px; }

.inner-area .back-btn-top, .inner-area .back-btn-bottom {
  position: absolute;
  left: 30px;
  top: 0; }

.inner-area .back-btn-top {
  position: relative;
  top: -15px;
  margin-bottom: 10px;
  background-color: var(--inner-area-back-btn-top-background-color);
  border: none;
  display: inline-block; }

.inner-area .icon-left {
  font-size: 26px;
  opacity: 0.5;
  margin-right: 10px; }

.inner-area .icon-left:before {
  color: var(--inner-area-icon-left--before-color); }

.inner-area .back-btn-top span, .inner-area .back-btn-bottom span {
  color: var(--inner-area-back-btn-top-span-inner-area-back-btn-bottom-span-color);
  opacity: 0.5;
  font-size: 24px;
  font-family: 'Sansa Pro SmBd';
  font-weight: 600;
  display: inline-block;
  vertical-align: middle; }

.inner-area .column-details {
  margin-bottom: 30px; }

.inner-area .column-details .icon {
  font-size: 100px;
  margin-top: 10px; }

.inner-area .column-details .default-heading {
  margin-bottom: 50px; }

.inner-area .column-details .default-heading h3 {
  font-size: 24px;
  text-transform: capitalize; }

.ingredientes {
  padding: 30px 0;
  position: relative;
  /*border-top: #263a7b dashed 1px; border-bottom: #263a7b dashed 1px;*/ }

.ingredientes:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0px;
  height: 9px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAJBAMAAAAWSsseAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAMUExURf///09flCY6e2l3pB6/ZCAAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTY8AHGBXBFLMyDnkGBgAIgQBJRrqtjwAAAABJRU5ErkJggg==);
  border: none;
  /*
  border: var(--ingredientes--before-base-border-color) dashed 1px; 
  */
}

.ingredientes:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0px;

  height: 9px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAJBAMAAAAWSsseAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAMUExURf///09flCY6e2l3pB6/ZCAAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTY8AHGBXBFLMyDnkGBgAIgQBJRrqtjwAAAABJRU5ErkJggg==);
  border: none;

  /*
  border: var(--ingredientes--after-base-border-color) dashed 1px; 
  */
}

.ingredientes .ingredientes-list {
  position: relative;
  margin-bottom: 30px; }

.ingredientes .ingredientes-list:last-child {
  margin-bottom: 0; }

.ingredientes .ingredientes-list .default-heading h4 {
  display: block;
  color: var(--ingredientes-ingredientes-list-default-heading-h4-color);
  margin-bottom: 10px; }

.ingredientes .ingredientes-list ul {
  list-style: none; }

.ingredientes .ingredientes-list ul li {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
  width: 100%;
  max-width: 50%;
  margin-right: -3px; }

.ingredientes .ingredientes-list ul li p {
  color: var(--ingredientes-ingredientes-list-ul-li-p-color);
  margin-bottom: 0; }

.slide-area {
  padding: 15px 0; }

.slide-area span {
  font-size: 18px;
  color: var(--slide-area-span-color); }

.slide-area .icon-top-arrow {
  transform: rotate(180deg);
  font-size: 24px;
  cursor: pointer; }

.slide-area .icon-top-arrow.ninety {
  transform: rotate(90deg); }

.vocation-area {
  position: relative;
  margin-top: 30px;
  padding: 25px 20px;
  background-color: var(--vocation-area-base-background-color);
  border-radius: 10px;
  top: -10px; }

.vocation-area .default-heading {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px; }

.vocation-area .default-heading h3 {
  color: var(--vocation-area-default-heading-h3-color);
  font-family: 'Sansa Pro SmBd';
  font-weight: 600; }

.vocation-area .starts {
  display: block;
  text-align: center; }

.vocation-area .starts i {
  display: inline-block;
  padding: 0 5px;
  font-size: 32px; }

.inner-area .back-btn-bottom {
  position: relative;
  left: auto;
  top: auto;
  padding: 20px 0;
  text-align: center;
  display: block; }

/**** Activities ****/
.activity.maxwidth {
  max-width: 700px; }

.activity.maxwidth .column-details p {
  text-align: left;
  padding-bottom: 30px; }

.listing {
  position: relative;
  list-style: none;
  padding: 0px 30px 30px 30px; }

.listing:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0px;

  height: 9px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAJBAMAAAAWSsseAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAMUExURf///09flCY6e2l3pB6/ZCAAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTY8AHGBXBFLMyDnkGBgAIgQBJRrqtjwAAAABJRU5ErkJggg==);
  border: none;
  /*
  border: var(--activities-listing--after-border-color) dashed 1px; 
  */
}

.listing li {
  position: relative;
  padding: 0px 0 10px 0;
  margin-top: 10px;
  font-size: 18px;
  line-height: 22px;
  display: block;
  padding-left: 30px; }

.listing li:before {
  height: 9px;
  width: 9px;
  border-radius: 100%;
  background-color: var(--activities-listing-li--before-background-color);
  position: absolute;
  left: 0;
  top: 5px;
  content: ""; }

.numbers.listing li {
  margin-top: 0; }

.numbers.listing li:before {
  display: none; }

.numbers.listing li span {
  color: var(--numbers-listing-li-span-color);
  position: absolute;
  left: 0; }

.large-img-area {
  display: block;
  text-align: center; }

.large-img-area .default-heading {
  padding: 15px 0; }

.large-img-area .default-heading h3 {
  color: var(--large-img-area-default-heading-h3-color);
  font-weight: 600; }

.recently-area {
  position: relative;
  padding: 30px 30px; }

.add-items {
  display: block;
  text-align: center; }

.news-area,
  .activities-area {
  position: relative; }

.news-area .default-heading,
.activities-area .default-heading,
.favorites-area .default-heading {
  display: block;
  text-align: center;
  padding: 30px 0 15px 0; }

.news-area .default-heading h2,
.activities-area .default-heading h2,
.favorites-area .default-heading h2 {
  color: var(--news-area-default-heading-h2-color);
  font-size: 30px;
  text-transform: uppercase;
  font-family: 'Sansa Pro SmBd';
  font-weight: 600; }

.activities-area .default-heading h2 {
  color: #FFB81A;
}

.tags {
  position: relative;
  padding: 15px 0 30px 0; }

.tags div.owl-item {
  width: auto!important;
}

.tags li {
  width: auto;
  vertical-align: middle;
  text-align: center; }

.tags li a {
  width: 100%;
  display: block;
  border: var(--activities-tags-li-a-base-border);
  border-radius: 15px;
  padding: 12px 10px;
  color: var(--activities-tags-li-a-base-color);
  text-transform: uppercase;
  font-size: 12px; }

.tags li span {
  width: 100%;
  display: block;
  border: var(--activities-tags-li-span-base-border);
  border-radius: 15px;
  padding: 12px 10px;
  color: var(--activities-tags-li-span-base-color);
  text-transform: uppercase;
  font-size: 12px;
  min-width: fit-content; 
}

.activities-area .tags li a {
  background: var(--activities-area-tags-li-a-background);
  border: var(--activities-area-tags-li-a-border);
  color: var(--activities-area-tags-li-a-color);
}

.activities-area .tags li span {
  background: var(--activities-area-tags-li-span-background);
  border: var(--activities-area-tags-li-span-border);
  color: var(--activities-area-tags-li-span-color);
}

.tags li:hover a,
.tags li.active a,
.tags li.active span {
  border-color: var(--activities-tags-li-hover-active-a-span-border-color);
  background-color: var(--activities-tags-li-hover-active-a-span-background-color);
  color: var(--activities-tags-li-hover-active-a-span-color); }

.activities-area .tags li:hover a,
.activities-area .tags li.active a,
.activities-area .tags li.active span {
  border-color: var(--activities-area-tags-li-a-hover-active-border-color);
  background-color: var(--activities-area-tags-li-a-hover-active-background-color);
  color: var( --activities-area-tags-li-a-hover-active-color); 
}

.activities-area .tags li span:hover {
  border-color: var(--activities-area-tags-li-span-hover-border-color);
  background-color: var(--activities-area-tags-li-span-hover-background-color);
  color: var( --activities-area-tags-li-span-hover-color); 
}

.tags .owl-nav {
  top: 35%; }

.tags .owl-nav .owl-prev {
  left: -10px; }

.tags .owl-nav .owl-next {
  right: -10px; }

.tags .icon-small-arrow-left:before, .tags .icon-small-arrow-right:before {
  color: var(--activities-tags-icon-small-arrow-left-arrow-right--before-color); }

.circling-area {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 265px;
  width: 265px;
  border-radius: 100%;
  background-color: var(--activities-circling-area-base-background-color);
  padding: 25px;
  margin: 0 auto; }

.circling-area.dark {
  background-color: var(--activities-circling-area-dark-base-background-color); }

.circling-area p {
  font-size: 24px;
  line-height: 26px;
  color: var(--activities-circling-area-p-color);
  font-family: 'Sansa Pro';
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0; }

.circling-area span {
  display: block;
  color: var(--activities-circling-area-span-color);
  font-weight: 300;
  font-family: 'Sansa Pro'; }

.box-area-small {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 265px;
  border-radius: 10px;
  background-color: var(--activities-box-area-small-base-background-color);
  padding: 25px;
  margin: 0 auto; }

.box-area-small p {
  font-size: 24px;
  line-height: 26px;
  color: var(--activities-box-area-small-p-color);
  font-family: 'Sansa Pro SmBd';
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0; }

.news-area .add-items, .news-area .add-items, .news-area .circling-area,
.news-area .box-area-small, .news-area .advertisement,
.activities-area .add-items, .activities-area .add-items, .activities-area .circling-area,
.activities-area .box-area-small, .activities-area .advertisement {
  margin-bottom: 50px; }

.search-area {
  position: relative;
  width: 100%;
  max-width: 240px;
  margin: 0 auto; }

.search-area .form-control {
  border-color: var(--activities-search-area-form-control-border-color);
  max-width: 80%; }

.search-area .search-btn {
  border: none;
  background: none;
  position: absolute;
  top: 10px;
  right: 10px; }

.search-area .search-btn .icon-search {
  font-size: 30px; }

/**** Edit profile Dropdown ****/
.account-area {
  display: inline-block;
  margin-right: 10px; }

.account-area > a.dropdown-toggle {
  position: relative;
  width: 55px;
  text-align: center;
  display: inline-block; }

.dropdown-header {
  color: var(--dropdown-header-base-color);
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  padding: 25px â€‹10px 15px â€‹10px; }

.dropdown-header i {
  font-size: 24px; }

.account-area .dropdown-menu {
  z-index: 999;
  left: auto !important;
  right: -100%;
  transform: inherit !important;
  padding: 10px 10px;
  top: 75% !important;
  margin-top: 25px;
  width: 100%;
  max-width: 411px;
  min-width: 411px;
  border: none;
  background-color: var(--account-area-dropdown-menu-base-background-color); 

  max-height: 90vh;
  overflow-y: scroll;
}

.account-area .dropdown-menu:before {
  position: absolute;
  right: 15%;
  top: -20px;
  content: "";
  opacity: 0.9;
  background-color: var(--account-area-dropdown-menu--before-base-background-color);
  width: 100%;
  max-width: 35px;
  height: 35px;
  border-radius: 5px; }

.account-area .dropdown-menu:after {
  position: absolute;
  right: 19%;
  top: 5px;
  margin-top: -10px;
  font-size: 15px;
  content: "\e9c7";
  /*color:#263A7B;*/
  font-family: 'someal';
  width: 100%;
  max-width: 10px; }

.account-area .dropdown-menu .dropdown-item {
  padding: 15px 0;
  color: var(--account-area-dropdown-menu-dropdown-item-color);
  font-weight: 300;
  background-color: inherit; }

.account-area .dropdown-menu .dropdown-item:focus,
.account-area .dropdown-menu .dropdown-item:hover {
  color: var(--account-area-dropdown-menu-dropdown-item--focus-dropdown-item--hover-color); }

.account-area .user-profile-links {
  position: relative;
  padding: 20px 20px;
  border-top: var(--account-area-user-profile-links-border-top);
  border-bottom: var(--account-area-user-profile-links-border-bottom); }

.account-area .btn-link {
  min-height: inherit;
  padding: 20px 20px;
  color: var(--account-area-btn-link-color);
  display: block;
  text-decoration: none; }

.account-area .general-area {
  background: var(--account-area-general-area-background-color);
  margin-left: 6px; }

.account-area .dropdown-menu .box-area {
  max-width: 48%;
  min-height: inherit;
  padding: 20px 0;
  display: inline-block; }

.account-area .dropdown-menu .box-area .icon {
  font-size: 50px;
  padding: 0px 0 15px 0; }

.account-area .dropdown-menu .box-area .default-heading {
  margin-bottom: 0; }

.account-area .dropdown-menu .box-area .default-heading h2 {
  font-size: 20px;
  line-height: 22px; }

.account-area .box-area.add-profile {
  max-width: 100%;
  background-color: var(--account-area-box-area-add-profile-background-color); }

.account-area .box-area.add-profile .add-icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  background-color: var(--account-area-box-area-add-profile-add-icon-background-color);
  border-radius: 100%;
  margin: 0 auto  15px auto; }

.account-area .box-area.add-profile .add-icon .icon-plus {
  font-size: 20px;
  padding: 15px; }

.account-area .box-area.add-profile .add-icon .icon-plus:before {
  color: var(--account-area-box-area-add-profile-add-icon-icon-plus--before-color); }

.account-area .box-area.add-profile .add-icon .icon-right-arrow {
  font-size: 20px;
  padding: 15px; }

.account-area .box-area.add-profile .add-icon .icon-right-arrow:before {
  color: var(--account-area-box-area-add-profile-add-icon-icon-right-arrow--before-color); }

.account-area .box-area.add-profile .default-heading h2 {
  color: var(--account-area-box-area-add-profile-default-heading-h2-color); }

.account-area .signout-area a {
  color: var(--account-area-signout-area-a-color); }

.account-area .signout-area .icon-close-circle:before {
  color: var(--account-area-signout-area-icon-close-circle--before-color); }

.sidebar .icon-close-circle:before {
  color: var(--sidebar-icon-close-circle--before-color);
}
/****************Footer Sart***************/
.footer-area {
  position: relative;
  padding: 30px 0 30px 0; }

.footer-logo {
  display: block;
  text-align: center;
  margin-bottom: 20px; }

.footer-logo .small-logo {
  display: inline-block; }

.Copyright {
  display: block;
  position: relative;
  text-align: center; }

.Copyright p {
  margin-bottom: 0;
  display: inline-block; }

.Copyright a {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  position: relative; }

/*======================///////////////
      start responsive style
=====================///////////////////////*/
@media (min-width: 992px) and (max-width: 1199px) {
  .headerSection {
    padding: 0; }

  .nav-tabs .nav-link {
    font-size: 22px; }

  .column-details {
    margin-bottom: 30px; }

  .graphic.left-graphic, .graphic.right-graphic {
    max-width: 200px; }

  .tags {
    padding: 15px 15px 15px 15px; } }
@media (min-width: 768px) and (max-width: 991px) {
  .graphic {
    display: none; }

  .hide-on-mobile {
    display: none; }

  .hide-on-desktop {
    display: block; }

  .headerSection {
    padding: 0; }

  .box-area, .advertisement, .activevades {
    min-height: inherit;
    display: inline-block !important;
    width: 100%;
    max-width: 49%;
    margin-right: 10px;
    vertical-align: top; }

  /*Added by Mari on 23/01/23 to fix right-area alignment on tablet*/
  .advertisement, .activevades,
  .box-area.download-area,
  .box-area.suggestion-message {
    max-width: 100%; }

  .box-area:nth-child(2), .advertisement {
    margin-right: 0; }

  .scorri-gruppo {
    text-align: center;
  }

  .recomendaciones-area {
    margin-bottom: 30px; }

  .activevades {
    margin-right: 0; }

  .menu-slider {
    padding: 30px; }

  .menu-slider .owl-nav .owl-prev {
    left: 0; }

  .menu-slider .owl-nav .owl-next {
    right: 0; }

  .left-area .box-area .default-heading, .menu-area .box-area .default-heading, .box-area .small-text-area, .advertisement {
    display: none; }

  .left-area .box-area {
    margin-bottom: 0; }

  .box-area .owl-nav {
    display: inherit;
    top: 40%;
    width: 100%;
    left: 0;
    right: 0; 
  }

  .box-area .owl-nav .owl-prev {
    left: 0; }

  .box-area .owl-nav .owl-next {
    right: 0; }

  .box-area .icon-small-arrow-right:before,
  .box-area .icon-small-arrow-left:before {
    color: var(--box-area--768-991--icon-small-arrow-right-left--before-color);
    opacity: 0.5; }

  .box-area .icon {
    padding-top: 45px;
    padding-bottom: 45px; }

  .box-area.download-area {
    /*
    display: none !important; 
    */
  }

  .menu-area .box-area .default-heading {
    display: block;
    margin-bottom: 8px;
  }

  .menu-area .owl-item .icon {
    padding: 0px;
  }

  .account-area .dropdown-menu {
    right: -100%!important;
  }

  .account-area .dropdown-menu::after {
    right: 19.5%!important;
  }

  .advertisement {
    display: none !important; }

  .right-area .advertisement {
    display: inline-block !important;
    margin-right: auto; }

  .news-area .right-area,
  .activities-area .right-area {
    margin-right: auto; }

  .tags {
    padding: 15px 15px 15px 15px; }

  .circling-area {
    width: 225px;
    height: 225px; }

  .box-area-small {
    max-width: 225px; }

  .circling-area p, .box-area-small p {
    font-size: 20px;
    line-height: 24px; } }
@media (max-width: 767px) {

  .btn {
    min-width: auto;
  }
  .modal .modal-dialog {
    max-width: 840px;
    width: 100%;
    margin: 50px auto 10px auto; }

  .hide-on-mobile {
    display: none; }

  .hide-on-desktop {
    display: block; }

  .default-heading h1, .default-heading > a.heading01 {
    font-size: 24px; }

  .default-heading h3, .default-heading > a.heading03,
  .inner-area .column-details .default-heading h3 {
    font-size: 20px;
    line-height: 24px; }

  .headerSection {
    padding: 0;
    /*z-index: 99999;*/ }

  .headerSection .minheight {
    min-height: 65px; }

  /*
  .account-area .dropdown-menu {
    max-width: 230px;
    min-width: 230px; }
  */

  .account-area .dropdown-menu:before {
    height: 4px; }

  .account-area .dropdown-menu:after {
    font-size: 10px; }

  .account-area .dropdown-menu .dropdown-header {
    max-width: 100%;
    padding: 15px 20px;
    font-size: 14px;
    letter-spacing: 1px; }

  .account-area .user-profile-links, .account-area .btn-link {
    padding: 15px 20px; }

  .account-area .dropdown-menu .dropdown-item {
    padding: 10px 0; }

  .navbar-brand-middle {
    max-width: var(--header-navbar-brand-middle); }

  /*
   .humbarger-arrow-area {
    display: block; }
  .account-area {
    display: none; }
  */

  .account-area {
    margin-right: 0px;
  }

  .sidebar {
    max-width: 100%;
    margin-right: -100%; }

  .sidebar .navbar {
    padding-left: 15px;
    padding-top: 0; }

  .sidebar.sidebar-hide-desktop {
    display: flex; }

  .sidebar .navbar .navbar-nav li.nav-item > a.nav-link {
    font-size: 16px;
    padding: 10px 0px; }

  .box-area, .advertisement {
    display: inline-block !important;
    width: 100%;
    max-width: 48.8%;
    margin-right: 9px;
    margin-bottom: 0;
    vertical-align: top;
    min-height: inherit; }

  .box-area:nth-child(2), .advertisement {
    margin-right: 0; }

  .left-area .box-area .default-heading, .menu-area .box-area .default-heading, .box-area .small-text-area, .advertisement {
    display: none; }

  .left-area .box-area {
    margin-bottom: 0; }

  .box-area .owl-nav {
    display: inherit;
    top: 32%;
  }
  
  .box-area .owl-nav .owl-prev {
    margin-left: 4px;
    left: 0; }

  .box-area .owl-nav .owl-next {
    margin-right: 4px;
    right: 0; }

  .box-area .icon-small-arrow-right:before,
  .box-area .icon-small-arrow-left:before {
    color: var(--box-area--767--icon-small-arrow-right-left--before-color);
    opacity: 0.5; }

  .box-area .icon {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 50px; }
  
  .box-area .icon.img {
    max-height: 85px;
    padding-top: 5px;
    margin: auto;
  }

  .box-area .icon .img {
    width: 50px;
    height: 50px;
  }

  .create-profile .icon .img {
    height: 50px;
    width: 50px;
  }

  .box-area.download-area {
    /*
    display: none !important; 
    */
  }

  .advertisement {
    display: none !important; }

  .recomendaciones-area {
    margin-bottom: 20px; }

  .activevades {
    text-align: center;
    margin-right: auto; }

  .menu-slider {
    padding: 10px 20px 20px 20px; }

  .menu-slider .owl-nav .owl-prev {
    left: 0; }

  .menu-slider .owl-nav .owl-next {
    right: 0; }

  .box-area.suggestion-message {
    margin-bottom: 20px; }

  .right-area {
    margin-right: auto; }

  .right-area .box-area.info-box {
    margin-bottom: 20px; }

  .right-area .box-area.info-box .default-heading {
    margin-bottom: 15px; }

  .right-area .box-area.info-box p {
    margin-bottom: 0; }

  .right-area .advertisement {
    display: inline-block !important;
    margin-right: auto; }

  .right-area .box-area.info-box, .right-area .activevades, .right-area .box-area.suggestion-message,
  .right-area .box-area.download-area,.right-area .advertisement {
    max-width: 100%;
    width: 100%; }

  .graphic {
    display: none; }

  .head-area .default-heading h3, .register-here {
    text-align: center;
    margin-bottom: 15px; }

  .btns-area a {
    padding-top: 20px; }

  .footer-area {
    padding: 10px 0 10px 0; }

  .confirmation {
    margin: 10px auto;
    width: auto;
    padding: 10px; }

  .head-area .default-heading h2 {
    font-size: 24px; }

  .navbar-brand.auto-width {
    max-width: 125px; }

  .navbar-brand-middle.textual-area span {
    font-size: 36px; }

  .language-change a {
    max-width: 55px; }

  .create-profile .row-area {
    padding: 0 15px; }

  .inner-area .back-btn-top {
    left: 15px;
    margin-bottom: 0;
    top: 0px; }

  .inner-area .back-btn-top span {
    display: none; }

  .inner-area .column-details .icon {
    font-size: 75px;
    margin-top: 0; }

  .inner-area .column-details .default-heading {
    margin-bottom: 20px; }

  .ingredientes .ingredientes-list ul li {
    max-width: 100%;
    margin-right: 0; }

  .tags {
    padding: 15px 15px 15px 15px; }

  .modal .modal-dialog {
    margin-top: 55px; } }
@media (max-width: 576px) {
  p {
    font-size: 16px;
    line-height: 22px; }

  .btn {
    font-size: 16px;
    min-height: 40px;
    line-height: 30px; }

  .form-group {
    margin-bottom: 20px; }

  .box-area, .advertisement {
    max-width: 47.6%; }

  .box-area .default-heading h2, .activevades .default-heading h2, .news-area .default-heading h2, .activities-area .default-heading h2 {
    font-size: 22px;
    line-height: 22px; }

  .nav-tabs .nav-link {
    font-size: 16px;
    padding: 0 4px; }

  .column-details {
    margin-bottom: 30px; }

  .number-list li {
    padding: 10px 5px; }

  .menu-area .default-heading {
    padding: 10px 0 0px 0; }

  .column-details p, .listing li {
    font-size: 16px;
    line-height: 24px; }

  .information-area .default-heading h2, .recomendaciones-area .default-heading h2, .head-area .default-heading h2 {
    font-size: 18px;
    line-height: 21px; }

  .menu-slider .number-counts {
    width: 50px;
    font-size: 24px;
    height: 50px; }

  .number-list li .circle-area {
    height: 54px;
    width: 54px;
    font-size: 20px;
    margin-bottom: 10px;
    border: var(--number-list--576--li-circle-area-border); }

  .number-list li .circle-area span {
    font-size: 13px; }

  .number-list li .name {
    font-size: 16px; }

  .right-area .box-area.suggestion-message {
    max-width: 100%; }

  .right-area .box-area.info-box {
    max-width: 100%; }

  .owl-carousel .owl-dots button.owl-dot, .owl-carousel .owl-dots button.owl-dot.active {
    width: 15px;
    height: 15px; }

  .cover-area {
    padding: 15px 0; }

  .cover-area .navbar-brand-middle {
    max-width: 150px; }

  .navbar-brand-middle.textual-area {
    max-width: 100%; }

  .navbar-brand-middle.textual-area span {
    font-size: 24px; }

  .language-change a {
    max-width: 28px;
    padding-left: 4px; }

  .desktop-screen span, .mobile-screen span {
    font-size: 130px; }

  .head-area {
    margin: 30px 0 10px 0; }

  .confirmation {
    margin: 10px; }

  .confirmation .form-group label {
    font-size: 14px; }

  .create-profile .row-area, .create-profile .row-area.top-row {
    padding: 0 15px; }

  .row-area .col-4 {
    padding-left: 5px;
    padding-right: 5px; }

  .create-profile .row-area.top-row .column-details .default-heading h3 {
    font-size: 13px;
    line-height: 18px; }

  .create-profile .row-area.bottom-row .column-details .default-heading h3 {
    font-size: 12px;
    line-height: 16px; }

  .modal .modal-dialog .modal-content .modal-body {
    padding: 0; }

  .listing {
    padding: 0px 0px 15px 0px; }

  .activity.maxwidth .column-details p {
    padding-bottom: 0; }

  .tags {
    padding: 15px 15px 15px 15px; }

  .tags li a {
    padding: 10px 6px;
    letter-spacing: -1px; }
  
  .tags li span {
    padding: 10px 6px;
    letter-spacing: -1px; }

  .tags .owl-nav .owl-prev i, .tags .owl-nav .owl-next i {
    font-size: 18px; }

  .news-area .add-items, .news-area .add-items, .news-area .circling-area, .news-area .box-area-small, .news-area .advertisement,
  .activities-area .add-items, .activities-area .add-items, .activities-area .circling-area, .activities-area .box-area-small, .activities-area .advertisement {
    margin-bottom: 30px; }

  .inner-area .back-btn-top {
    top: -15px; } }



/**
For menu page
by DM on 18/06/21
**/
.suggestions-container { 
  font-size: 18px; 
  font-weight: 500; 
  margin-bottom: 0; 
}

.component-img-container { 
  margin-bottom: 15px;
  text-align: center
}

.component-img-container img { 
  width: 65px;
  height: 65px;
}

/**
    DIETS
    AND YEARS 
    LITTLE VERSION

    (Now only used in SoHappy app)

    INIT
*/
.owl-carousel.box-area.menu-general.little, 
.owl-carousel.box-area.infanital-ciclo.little {
    min-height: auto;
    height: 125px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;

    position: relative;
}

.owl-carousel.box-area.menu-general.little .owl-nav, 
.owl-carousel.box-area.infanital-ciclo.little .owl-nav {
    left: 0;
    right: 0;
    width: 100%;
}

.owl-carousel.box-area.menu-general.little .owl-prev, 
.owl-carousel.box-area.infanital-ciclo.little .owl-prev {
    left: 0;
}

.owl-carousel.box-area.menu-general.little .owl-next, 
.owl-carousel.box-area.infanital-ciclo.little .owl-next {
    right: 0;
}

.owl-carousel.box-area.menu-general.little .owl-item .item.little .icon,
.owl-carousel.box-area.infanital-ciclo.little .owl-item .item.little .icon{
    font-size: 52px;
    padding-top: 10px;
    padding-bottom: 5px;


}

.owl-carousel.box-area.menu-general.little .owl-item .item.little .default-heading ,
.owl-carousel.box-area.infanital-ciclo.little .owl-item .item.little .default-heading {
    margin-bottom: 0px;
}
.owl-carousel.box-area.menu-general.little .owl-item .item.little .default-heading h2,
.owl-carousel.box-area.infanital-ciclo.little .owl-item .item.little .default-heading h2{
    font-size: 12px;
}
/**
    DIETS
    AND YEARS 
    LITTLE VERSION

    FINISH
*/