.custom-select {
    width: 180px;
    text-transform: uppercase;
    color: #283897;
    font-family: "Sansa Pro SmBd";
    font-size: 18px;
    text-align: left;

    padding: .375rem 1.75rem .375rem .75rem;

    border-color: #283897;
}

.custom-select:hover, .custom-select:focus {
    border-color: #283897;
}

.custom-select-2 {
    min-width: 180px;
    max-width: 250px;
    text-transform: uppercase;
    color: #283897;
    font-family: "Sansa Pro SmBd";
    font-size: 18px;
    text-align: left;

    padding: .375rem .75rem .375rem .75rem;

    border-color: #283897!important;
}

.custom-select-2:hover, .custom-select-2:focus {
    border-color: #283897;
}