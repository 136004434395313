.profile-confirm-button-container, .profile-delete-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile-confirm-button-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
    vertical-align: middle;
    
    height: 40px;
    width: 40px;

    border-radius: 20px;

    background-color: #283897;
    color: var(--white);
}

.profile-confirm-button-icon-container .icon-small-arrow-left:before {
    background-color: #283897;
    color: var(--white);
}

.profile-delete-button-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: "someal";
    text-align: center;
    vertical-align: middle;
    
    height: 40px;
    width: 40px;

    border-radius: 20px;

    background-color: #FF0000;
    color: var(--white);
}

.profile-confirm-button-container:hover .profile-confirm-button-icon-container
.profile-confirm-button-container:hover .icon-small-arrow-left:before {
    background-color: var(--white);
    color: #283897!important;
}

.profile-delete-button-container:hover .profile-delete-button-icon-container {
    background-color: var(--white);
    color: #FF0000!important;
}

.profile__confirm-button, .profile__delete-button { 
    font-size: 20px;
}

/*
.profile__confirm-button::before {
    content: '\f00c';
}
*/

.profile__delete-button::before {
    content: '\e9c4';
}

/*
.profile-delete-button-container:hover .profile__delete-button::before {
    //content: '\e9d0';
    content: '\e9c4';
}
*/

.profile-confirm__text, .profile-delete__text {
    margin: 8px auto;
    color: #283897;
    text-transform: uppercase;
    font-family: "Sansa Pro Nor";
    font-size: 14px;
}