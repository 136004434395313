.select-canteenids {
    height: auto;
    width: inherit;
    max-height: 100px;
    font-size: 18px;
    padding: 10px;
    text-align: center;
}
.select-canteenids option{
    text-align: center;
}

input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.default-heading.text-center .school-name {
    margin: 8px auto 16px auto;
    color: #24387C;
    font-size: 16px;
    font-family: "Sansa Pro Nor";

    text-transform: uppercase;
}

.modal.fade.show.topper {
    margin-top: 65px;
}

.modal.fade.show.scrollable {
    overflow-y: scroll;
}

.privacy-container, .terms-container { 
    margin: 24px 8px;
    text-align: center;
    font-size: 14px;
}

.display-text { 
    margin: 8px auto;
}

.privacy-container__title, .terms-container__title { 
    font-size: 18px;
    font-weight: bold;
}

.checkbox-container { 
    margin: 8px auto;
    width: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

@media screen and (min-width: 768px) {
    .privacy-container, .terms-container { 
        margin: 24px 48px;
        padding: auto 12px;
        text-align: center;
        font-size: 14px;
    }

    .checkbox-container { 
        margin: 16px auto;
        width: 40%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
}

/*
    Used for modal
*/
#create-profile-container.modal { 
    position: inherit;
    height: auto;
}

#create-profile-container.modal .modal-dialog { 
    margin-top: 56px;
}