.forget-password-container,
.sign-in-anonymously-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.forget-password-container a, 
.sign-in-anonymously-container a { 
    color: var(--login-page-forget-password-a-color);
    text-transform: uppercase;
    font-weight: 600!important;
    padding-bottom: 3px;
    border-bottom: 1px solid var(--login-page-forget-password-a-border-bottom-color);
}
