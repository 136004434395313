.rate-recipe-faces-container {
    display: inline-flex;
    align-items: center;

    margin: 6px 4px;

    text-align: center;
    vertical-align: middle;

    justify-content: space-between;
    width: 100%;

    max-width: none;

    /*
    width: 100%;
    justify-content: center;
    */
}

.rate-recipe-faces-container .rate-recipe-img {
    width: 60px;
    height: 60px;

    margin: auto 4px;

    cursor: pointer;
}

.rate-recipe-faces-container .rate-recipe-img.deactive {
    opacity: 0.30;
}

@media screen and (min-width: 768px) {
    .rate-recipe-faces-container {
        justify-content: space-between;
        width: 80%;
        max-width: 400px;
    }
}