$first-type-of-color:#13438C;
$second-type-of-color:#e78243;
$third-type-of-color:#818181;
$body-color:#ffffff;
$default-text-color:#353535;
$default-white-color:#FFFFFF;
$default-black-color:#000000;
$default-gray-color:#F7F7F7;
$default-green-color:#007300;
$default-lightgreen-color:#367a40;
$default-darkgreen-color:#28532e;
$default-font-size:16px;
$default-fontfamily01:'Sansa Pro Nor', sans-serif; 
$default-font-weight:400;
$default-line-height: 1.5;
$default-line-height-norm: normal;
$mar-padd-None: 0px;
$mar-auto: 0px auto;
$full-height: 100%;
$full-min-height: 100%;
$full-width-area:100%;
$default-paragraph-font-size: 20px;
$default-paragraph-line-height:24px;
$default-link-color:#6e1210;
$minheight:450px;
$heightNone:auto;
$display-b:block;
$display-n-b:inline-block;
$border-none:none;
$borderRadiusfull:100px;
$borderRadiusHalf:50px;
$borderRadiusNone:0;
$boxShadow:none;
$placeholder-color:#a9a9a9;
$bg-size-cover:cover;
$heightAuto:auto;
$textDecoration: none;
$outlineNone:none;
$hoverbtncolor01:#FFFFFF;
$hoverbtncolor02:#153c59;
$color01:#28532e;
