.language-container { 
    margin: 8px 16px;
}

.language-selector-container {
    width: 100%; 
    margin: 16px 0;
}

.language-selector-li-container {
    border-top: 1px solid rgb(188, 187, 193);
    text-align: left;
}

.language-selector-li-container.first {
    border-top: 1px solid rgb(188, 187, 193);
}

.language-selector-li-container.last {
    border-bottom: 1px solid rgb(188, 187, 193);
}

.language-selector-li {
    display: flex;
    width: 100%;
    font-size: 18px;
    padding: 6px 8px;
    text-align: left;
}

.language-selector__selected {
    padding: 6px 8px;
    text-align: right;
    font-size: 18px;
}

.check-icon {
    padding-top: 3px;
    justify-content: center;
    align-items: center;
}

.pencil-img { 
    min-width: 20px;
    min-height: 20px;
    align-items: center;
    justify-content: center;
}