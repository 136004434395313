.no-data-container { 
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--background-activity-slider);

    max-width: 100%;
}

.activity__subtitle {
    margin: 16px auto 8px auto;
    font-family: "Sansa Pro Nor";
    text-align: center;
    line-height: 1;

    color: #353535;
    font-size: 18px;
}

.default-heading.home-activity-title {
    padding-top: 3px;
}

.games.owl-carousel .item {
    text-align: center;
    width: auto;
}