.profile-container {
    margin: 16px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0;
}

.profile-container .dropdown-header {
    cursor: default;
    font-size: 20px;
    white-space: normal;
}

.profile-cancel-container {
    margin: 16px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    padding: 0;
}

.profile-container.selected {
    border: 2px solid var(--profile-container-selected-border);
    border-radius: 10px;
}

.modify-name-container { 
    height: 100%;
    align-self: center;
    padding: 10px;
    cursor: pointer;
}

.profile__title {
    font-size: 24px;
    color: var(--profile-title);
}

.profile__boxes {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.profile__boxes-account-page{
    justify-content: center;
}

.box-area-measure { 
    width: 150px;
    height: 150px;
}

.profile-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.profile-container-simplify {
    min-height: 150px;
    max-height: 200px;
    justify-content: space-between;
    text-align: center;
    margin: auto;
    width: 95%;

    font-family: "Sansa Pro";
    font-weight: 400;
    font-size: 24px;
    color: #24387C;
    text-transform: uppercase;
}

.profile-container-simplify.selected {
    font-weight: 600;

    border: none!important;
}

.profile-container-simplify .profile-name {
    width: 75%;
    font-size: 18px;
    text-align: left;
}

.profile-data--current-selected-point {
    font-weight: bold;
    font-size: 36px;
}

.profile-container-simplify .icon-user, .profile-container-simplify .icon2-TRITURADA {
    font-size: 55px;
}

.profile-data-container.selected {
    border: 2px solid var(--profile-data-container-selected-border);
    border-radius: 10px;
}

.profile-data-container .data-box {
    align-self: center;
    width: 200px;
    height: 150px;
    background-color: var(--profile-data-container-data-box-bg-color);
    border-radius: 10px;
    margin: 0 8px 0 8px;
}

.profile-data-container .data-box.selected {
    border: 3px solid var(--profile-data-container-data-box-selected-border);
}

.profile-data-container .data-box.little {
    width: 150px;
    height: 150px;
}

.profile-data-container .data-box.profile__diet-box {
    background: var(--profile-data-container-diet-box-bg-color);
}

.profile-data-container .data-box .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.profile-data-container .data-box .item .icon {
    font-size: 62px;
}

.profile-data-container .data-box .item .avatar {
    height: 75px;
    width: 75px;
    align-self: center;
}


.profile-data-container .data-box .item .default-heading {
    margin-top: 14px;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    max-width: 80%;
}

.profile-data-container .data-box .item .default-heading .avatar-text {
   color: transparent;
}

.profile-data-container .data-box .item .default-heading h2, h3 {
    font-size: 16px;
    line-height: 1.2;
}

@media screen and (min-width: 768px) {
    .profile-container .dropdown-header {
        cursor: default;
        font-size: 26px;
    }

    .profile-data-container {
        width: 100%;
    }

    .profile-container-simplify {
        width: 95%;
    }

    .profile-container-simplify .icon-user, .profile-container-simplify .icon2-TRITURADA {
        font-size: 62px;
    }
}

.profile-avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.profile-avatar-container:hover, .child-mode-container:hover {
    background-color: #E4E4E4;
}

.profile-avatar-container .avatar {
    height: 65px;
    width: 65px;
}

.child-mode-container {
    font-family: "Soopafre";
    color: #FFB81A!important;
    text-transform: uppercase;
    text-align: center;
    font-size: 23px;

    padding-top: 8px;
    padding-bottom: 8px;
}


.profile-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-name-container .school-name { 
    text-align: center;
    text-transform: uppercase;

    font-family: "Sansa Pro Nor";
    font-size: 16px;
    color: #283897;

    margin: 0px auto;
}

.modify-button { 
    background-color: #283897;
    border-color: #283897;

    font-family: "Sansa Pro Nor";
    font-size: 14px;
    color: var(--white);

    letter-spacing: 0px;

    min-width: 160px;
}

.modify-button:hover {
    background-color: #283897;
    border-color: #283897;
}

/*
.pencil-img {
    background: transparent;
}
*/