.allergens-text-container {
    padding: 8px;
}

.ul-allergens-outside { 
    padding-left: 0px;
}

.ul-allergens-inside { 
    padding-left: 40px;
}
