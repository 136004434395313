.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--react-app-header-bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--react-app-header-color);
}

.App-link {
  color: var(--react-app-link-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.uppercase {
  text-transform: uppercase!important;
}

.lowercase {
  text-transform: lowercase!important;
}

.clickable {
  cursor: pointer;
}

.form-control.error {
  border-bottom: var(--form-control-border-color) solid 2px;
}

.no-mr { margin-right: none }
.m-r-0 { margin-right: 0!important }
.m-l-0 { margin-left: 0!important }

.element-hidden {
  display: none!important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#hp-icone-image { 
  display: block;
  width: 90%;
  margin: auto auto 32px auto;
}

@media screen and (min-width: 768px) {
  #hp-icone-image { 
    display: none;
  }
}

.topper-space {
  margin-top: 16px;
}