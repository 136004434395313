.go-back-plus-title-head-area { 
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
}

.middle-content.inner-area.col-12.go-back-plus-title-head {
    padding-bottom: 8px;
}

.go-back-plus-title-title-container {
    display: none;
}


@media screen and (min-width: 768px) {
    .go-back-plus-title-title-container {
        display: block;
    }
}