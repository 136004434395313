.no-data-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#news-box-id .owl-dots { 
    margin-top: 32px;
}

#news-box-id .owl-dots button.owl-dot {
    border-color: var(--border-color-news-owl-dot);
    background-color: var(--background-color-news-owl-dot);
}

#news-box-id .owl-dots button.owl-dot.active {
    border-color: var(--background-color-news-owl-dot-active);
    background-color: var(--border-color-news-owl-dot-active);
}