
.carousel-inner{height:$full-height;}
.carousel-item {height:$full-height;  background: no-repeat; background-size:cover; background-position:top center;}
.carousel-item:before{ position: absolute; left: 0; top: 0; bottom: 0; right: 0; width: 100%; height: 100%; content:"";  opacity: 0.5; background-color: #000000; }

.carousel-item img{ width:100%;}
.carousel, .item {  height: 100%;  vertical-align: middle;}
.carousel-inner > .item img{ width: 100%; height: 100%; background-position: center; -webkit-background-size: $bg-size-cover; -moz-background-size:$bg-size-cover; background-size:$bg-size-cover; -o-background-size: $bg-size-cover;}

.carousel-caption{  text-align: left; width: 100%; padding:10px 0;  max-width: 100%; top: 50%; left:0%; right: 0%; margin: 0 auto;  bottom: auto;
 padding-bottom: 0;}

.slider-text-area{ width: 100%; max-width: 70%; padding-top: 100px;}
.slider-text-area .default-heading h1, 
.slider-text-area .default-heading a{  margin-bottom: 0; line-height: 65px;  letter-spacing: 1.5px; }
.slider-text-area .btn{ margin-top: 50px; text-transform: uppercase; font-weight: 600; font-size: 24px; max-width: 500px; width: 100%; padding:15px 25px;}
.slider-text-area p{  line-height: 16px; color: #FFFFFF;   margin-bottom: 0;}

.carousel-indicators{left: auto; bottom: 25px;}
.carousel-indicators li{ width: 12px; height: 12px; border:#ffffff solid 1px; background-clip:inherit; border-radius: 100%; opacity: 1;}

.carousel-indicators li:hover,
.carousel-indicators li.active{ background-color: #d45552; border:#d45552 solid 1px;}
/*.carousel.slide,  .carousel.slide .carousel-inner, .carousel.slide .carousel-inner .carousel-item{ min-height:550px;}*/



.middle-content{ position:relative;}

.sidebar{position: fixed; right: 0; top: 0; z-index: 99; /*display: flex; flex-wrap: wrap;*/ height: 100%; width: 100%; margin-right:-390px; max-width: 390px; background-color:$first-type-of-color;  margin-left: 0px; transition: margin-right .5s linear; }
.sidebar.paddNone{ padding-top: 0; }
.sidebar.zindex-increased{z-index: 9999;}
.sidebar.shrink{ margin-right:0; }
.sidebar .navbar{ padding:20px; }
.sidebar.sidebar-hide-desktop{ display: none;}

.signout-area{ position: relative; padding:20px; text-align: center;}
.signout-area a{font-size: 18px; font-weight: 700; letter-spacing: 1px; text-transform: uppercase; color: #ffffff;}
.signout-area a .icon-close-circle{ color: #ffffff; font-size: 36px; display: block; margin-bottom: 10px;}

.closed-arrow{ right: 15px; top: 15px; position: absolute; cursor: pointer; overflow: hidden;  max-width: 24px; width: 100%; z-index: 1;}

.left-area{ position: relative; width: 100%; max-width: 310px;}
.box-area{ width: 100%; max-width: 310px; margin-bottom: 20px; background:#acb0d5; text-align: center;  border-radius: 15px; min-height: 260px;}
.box-area .icon{ font-size: 75px; display:  block; text-align: center; padding-bottom: 20px; padding-top: 50px;}
.box-area .default-heading{ margin-bottom: 50px; }
.box-area .default-heading h2{ font-size: 24px; line-height: 28px; font-family: 'Sansa Pro'; font-weight: 300; color: #263a7b; display: block; text-transform: uppercase; }

.box-area .icon-small-arrow-right:before, 
.box-area .icon-small-arrow-left:before{ color: #ffffff; }
.box-area .small-text-area{text-align: center; padding:5px 0; text-transform: uppercase; position: absolute; width: 100%; font-weight: 500;  font-size: 16px; line-height: 24px; color: #263a7b; }
.box-area .small-text-area.top{ top: 10px; }
.box-area .small-text-area.bottom{ bottom: 10px; }
.box-area .small-text-area .text{ display: none; }
.box-area:hover .small-text-area .text{ display: block; }
/*
    show arrows only on hover 
    .box-area .owl-nav{ display: none; }
    .box-area:hover .owl-nav{ display: block; }
*/
.box-area .owl-nav{ display: block; 
    font-size: 22px;
}

.box-area.menu-general{ background: #d2dc29; }

.box-area.download-area{ background: #f69273; padding: 10px; min-height: inherit;}
.box-area.download-area .dotted-border{ border:#fa870a dashed 2px; padding: 5px; }
.box-area.download-area .dotted-border .icon-download-arrow{ font-size: 65px;}
.box-area.download-area .dotted-border .icon-download-arrow:before{color: #ffffff;}
.box-area.download-area .dotted-border .year{font-size: 18px; line-height: 24px; font-family: 'Sansa Pro'; font-weight: 300; text-transform: uppercase; color: #ffffff;}
.advertisement{ display: block; text-align: center; }
.info-text{ position: relative; display: block; text-align: center; max-width: 250px;  margin: 0 auto; width: 100%;}
.info-text p{ margin-bottom: 0; text-transform: uppercase; padding: 15px 0 0px 0; }

.menu-area{ position: relative; }
.menu-area .default-heading{ display: block; text-align: center; padding: 30px 0 15px 0; }
.menu-area .default-heading h1{ color: #263a7b; font-weight: 600; text-transform: uppercase;}

.date-list{ position: relative; list-style: none; }
.date-list li{ display: inline-block; vertical-align: middle; width: 100%; max-width: 20%; margin-left: -4px; }
.menu-slider .extra-small-text{ display: block; text-align: center; padding:5px 0; }
.menu-slider .extra-small-text .text{ text-transform: uppercase; font-family: 'Sansa Pro'; font-weight: 300; font-size: 12px; line-height: 14px; color: #263a7b; }
.menu-slider .number-counts{ width: 75px; height: 75px; margin: 0 auto; border-radius: 100%; text-align: center; display: flex; vertical-align: middle; font-size: 36px; font-weight:bold; color: #263a7b; }
/*.menu-slider .owl-item:hover .number-counts,
.menu-slider .owl-item.active.center .number-counts{ background-color: #d2dc28;  color: #ffffff; }*/
.menu-slider{ position: relative; }
.menu-slider .owl-item.active.center .date-list li:nth-child(3) .number-counts, 
.date-list li:hover .number-counts, .date-list li.active .number-counts{ background-color: #d2dc28;  color: #ffffff; }
.number-counts{ cursor: pointer; }
.menu-slider .owl-nav .owl-prev{ left: -5px; }
.menu-slider .owl-nav .owl-next{ right: -5px; }

.buttons-area { position: absolute; top: 45%; width: 100%; left: 0; right: 0;}

.buttons-area .arrow-prev{position: absolute; left: -15px; border: none; background: none;}
.buttons-area .arrow-next{position: absolute; right:-15px; border: none; background: none;}
.buttons-area i{font-size: 24px;}

.column-details{ position: relative; display: block; text-align: center; margin-bottom: 60px; }
.column-details .icon{ font-size: 55px; margin-bottom: 15px; }
.column-details .default-heading h3{ color: #263a7b; text-transform: uppercase; font-size: 20px; font-family: 'Sansa Pro SmBd'; font-weight: 600;}
.column-details p{ font-size: 18px; font-weight: 500; margin-bottom: 0; }

.information-area{ position: relative; background-color: #f5f5f3; padding: 20px 10px 10px 10px; text-align: center; }
.information-area.opened{ display: block; }
.information-area.closed{ display: none; }
.information-area:after{ position: absolute; content: ""; left: 0; right: 0; bottom: -30px; border: #263a7b dashed 1px; }
.information-area .default-heading h2, .rate-recipe.area-container .default-heading h2{ font-size: 24px; font-weight:600; color: #263a7b; display: block; text-transform: uppercase; }
.number-list{ display: block; list-style: none; text-align: center; }
.number-list li{ display: inline-block; vertical-align: top; padding: 10px 15px;}
.number-list li .circle-area{height: 68px; width: 68px; margin-bottom: 20px; border-radius: 100%; font-size: 24px; line-height: 20px;
font-weight: 700; text-align: center; background-color: #ffffff; border: #17438e solid 5px;  color: #000000; position: relative;}
.number-list li .circle-area span{display: block; text-align: center; color: #000000; font-size: 16px; line-height: 1; position: absolute; bottom: 3px; width: 100%;}
.number-list li .circle-area:hover span{color: #ffffff;}
.number-list li .circle-area.blue.active,
.number-list li .circle-area.blue:hover{ background-color:#17438e; color: #ffffff;}
.number-list li .circle-area.yellow{ border-color:#c7d947; }
.number-list li .circle-area.yellow.active,
.number-list li .circle-area.yellow:hover{ background-color:#c7d947; color: #ffffff;}
.number-list li .circle-area.orange{ border-color:#f69273; }
.number-list li .circle-area.orange.active,
.number-list li .circle-area.orange:hover{ background-color:#f69273; color: #ffffff;}
.number-list li .circle-area.pink{ border-color:#ca268c; }
.number-list li .circle-area.pink.active,
.number-list li .circle-area.pink:hover{ background-color:#ca268c; color: #ffffff;}
.number-list li .name{ font-size: 18px; line-height: 20px; text-transform: uppercase; color: #000000;}


.recomendaciones-area{ position: relative;margin-top: 60px; text-align: center; }
.recomendaciones-area .default-heading h2{ font-size: 24px; font-weight:600; margin-bottom: 20px; color: #263a7b; display: block; text-transform: uppercase; }


.right-area{ position: relative; width: 100%; max-width: 310px; margin-left: auto; }
.right-area .box-area.info-box{ background-color: #58c3b9; padding: 30px 25px; margin-left: auto;}
.right-area .box-area.info-box .default-heading{ margin-bottom: 35px; }
.right-area .box-area.info-box .default-heading h2{ color: #ffffff; }
.right-area .box-area.info-box p{color: #ffffff; font-family: 'Sansa Pro'; font-weight: 300;}

.activevades{ position: relative; text-align: center; margin-bottom: 20px; margin-left: auto; max-width: 310px; }
.activevades .default-heading h2{  text-align: center; font-size: 24px; line-height: 28px; margin-bottom: 15px; font-weight: 700; color: #263a7b; display: block; text-transform: uppercase; }

.box-area.suggestion-message{ background-color: #ca268c; padding:10px 15px; min-height: inherit; margin-left: auto;}
.box-area.suggestion-message .icon-email{ font-size: 50px;}
.box-area.suggestion-message .icon-email:before{color: #ffffff; }
.box-area.suggestion-message .default-heading{ margin-bottom: 0px; margin-top: 10px; }
.box-area.suggestion-message .default-heading h2{ color: #ffffff; }
.box-area.suggestion-message p{color: #ffffff; margin-bottom: 0; font-weight: 14px;}
.box-area.suggestion-message .default-heading h2{ font-size: 18px;}

.right-area .advertisement{ max-width: 310px; margin-left: auto; }


/**** Cover Page *****/
.language-change{ position: relative; text-align: right;}
.language-change a{ display: inline-block; vertical-align: middle; padding-left: 20px;}

.cover-area{ position: relative; padding: 30px 0; }
.cover-area .navbar-brand-middle{ max-width: 230px; margin-bottom: 50px; }
.desktop-screen, .mobile-screen{ display: block; text-align: center; }
.desktop-screen span, .mobile-screen span{ font-size: 180px; }
.desktop-screen span{font-size: 200px; }


/**** Form Area ****/
.form-area{ position: relative; }
.head-area{ position: relative; display: block; margin: 30px 0; }
.head-area .default-heading h3{color: #263a7b; font-weight: 600;}
.head-area .default-heading h2{color: #263a7b; font-weight: 300; text-align: center;}
.head-area .default-heading h2 strong{ font-weight: 800; display: block; }

.register-here{ display: block; text-align: right; }
.register-here a{color: #263a7b; text-transform: uppercase; font-weight: 600;}
.btns-area{ text-align: center; position: relative; display: block; }
.btns-area a{ display: block; color: #13438C; padding: 15px 0; font-size: 12px; }
.graphic{ position: absolute;top: 0; z-index: 1; }
.graphic.left-graphic{ left: -30px; width: 100%; max-width: 300px;}
.graphic.right-graphic{ right: -30px; width: 100%; max-width: 300px;}

/**** Confirmation ****/
.body-color{ background-color: #cccccc; }
.confirmation{ background-color: #ffffff; padding: 15px; border-radius: 20px; margin: 20px auto; width: 100%; max-width: 600px; }
.confirmation .form-group label{ font-weight: 600; font-size: 18px; text-align: center; color: #24387C;}
.confirmation .form-area{ padding-bottom: 0; padding-left: 15px; padding-right: 15px; }
.confirmation .footer-area{ padding: 0; }
.all-graphic{ text-align: center; display: block; margin-bottom: -10px; }

/**** Profile ****/
.create-profile{ position: relative; max-width: 800px; width: 100%; margin: 0 auto;  background-color: #ffffff;}
.create-profile .head-area{ padding: 30px 0 20px 0; margin: 0; }
.create-profile .row-area{ margin-bottom: 20px;}
.create-profile .row-area .default-heading h3{ color: #263a7b; margin-bottom: 10px;}
.create-profile .row-area .column-details{ margin-bottom: 0px; }
.create-profile .row-area.top-row{ padding:0 120px; }
.create-profile .row-area.top-row .column-details .icon{ font-size:46px; margin-bottom: 10px; cursor: pointer;}
.create-profile .row-area.top-row .column-details .default-heading h3{ font-size: 16px; line-height: 20px; font-weight: 600;}
.create-profile .row-area.bottom-row .column-details .icon{ margin-bottom: 10px; position: relative; cursor: pointer;}
.create-profile .row-area.bottom-row .column-details .icon:hover:before,
.create-profile .row-area.bottom-row .column-details .icon.active:before{ z-index: 0; border-radius: 100%; position: absolute; left: 0; bottom: 0; right: 0; top: 0; background-color: #263a7b; content: "";}



.create-profile .row-area.top-row .column-details .icon .path3{ position: relative; }

.create-profile .row-area.top-row .column-details .icon:hover .path3:after,
.create-profile .row-area.top-row .column-details .icon.active .path3:after{ z-index: -3; border-radius: 100%; position: absolute; left: -40px; bottom: 0; right: 0; top: 5px; height: 36px; width: 33px; background-color: #263a7b; content: "";}

.create-profile .row-area.top-row .column-details .icon:hover .path1:after,
.create-profile .row-area.top-row .column-details .icon.active .path1:after{ z-index: -3; border-radius: 15px 0px 0px 15px; position: absolute; left:0px; bottom: 0; right: 0; top: 18px; height: 11px; width: 7px; background-color: #263a7b; content: "";}

.create-profile .row-area.top-row .column-details .icon:hover .path2:after,
.create-profile .row-area.top-row .column-details .icon.active .path2:after{ z-index: -3; border-radius:0 15px 15px 0; position: absolute; left:auto; bottom: 0; right: 0; top: 18px; height: 11px; width: 7px; background-color: #263a7b; content: "";}

.create-profile .row-area.top-row .column-details .icon.icon-user .path1:after,
.create-profile .row-area.top-row .column-details .icon.icon-user .path2:after{ top: 20px; }
.create-profile .row-area.top-row .column-details .icon.icon-user .path3:after{ top: 7px; }




.create-profile .row-area.bottom-row .column-details .default-heading h3{font-size: 13px; line-height: 18px;}
.path1, .path2, .path3, .path4, .path5, .path6, .path7, .path8, .path9, .path10,
.path11, .path12, .path13, .path14, .path15, .path16, .path17, .path18, .path19, .path20{ position: relative; z-index: 1; }

.create-profile .row-area.top-row .column-details .icon-female-user.icon .path1{ z-index: 999; }


.create-profile .row-area.bottom-row .column-details .icon:hover .path1:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path2:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path3:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path4:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path5:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path6:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path7:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path8:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path9:before, 
.create-profile .row-area.bottom-row .column-details .icon:hover .path10:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path11:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path12:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path13:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path14:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path15:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path16:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path17:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path18:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path19:before,
.create-profile .row-area.bottom-row .column-details .icon:hover .path20:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path1:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path2:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path3:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path4:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path5:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path6:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path7:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path8:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path9:before, 
.create-profile .row-area.bottom-row .column-details .icon.active .path10:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path11:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path12:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path13:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path14:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path15:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path16:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path17:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path18:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path19:before,
.create-profile .row-area.bottom-row .column-details .icon.active .path20:before{ color: #ffffff; }
.create-profile .row-area.top-row .column-details .icon:hover .path1:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path2:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path3:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path4:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path5:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path6:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path7:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path8:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path9:before, 
.create-profile .row-area.top-row .column-details .icon:hover .path10:before,
.create-profile .row-area.top-row .column-details .icon:hover .path11:before,
.create-profile .row-area.top-row .column-details .icon:hover .path12:before,
.create-profile .row-area.top-row .column-details .icon:hover .path13:before,
.create-profile .row-area.top-row .column-details .icon:hover .path14:before,
.create-profile .row-area.top-row .column-details .icon:hover .path15:before,
.create-profile .row-area.top-row .column-details .icon:hover .path16:before,
.create-profile .row-area.top-row .column-details .icon:hover .path17:before,
.create-profile .row-area.top-row .column-details .icon:hover .path18:before,
.create-profile .row-area.top-row .column-details .icon:hover .path19:before,
.create-profile .row-area.top-row .column-details .icon:hover .path20:before,
.create-profile .row-area.top-row .column-details .icon.active .path1:before, 
.create-profile .row-area.top-row .column-details .icon.active .path2:before, 
.create-profile .row-area.top-row .column-details .icon.active .path3:before, 
.create-profile .row-area.top-row .column-details .icon.active .path4:before, 
.create-profile .row-area.top-row .column-details .icon.active .path5:before, 
.create-profile .row-area.top-row .column-details .icon.active .path6:before, 
.create-profile .row-area.top-row .column-details .icon.active .path7:before, 
.create-profile .row-area.top-row .column-details .icon.active .path8:before, 
.create-profile .row-area.top-row .column-details .icon.active .path9:before, 
.create-profile .row-area.top-row .column-details .icon.active .path10:before,
.create-profile .row-area.top-row .column-details .icon.active .path11:before,
.create-profile .row-area.top-row .column-details .icon.active .path12:before,
.create-profile .row-area.top-row .column-details .icon.active .path13:before,
.create-profile .row-area.top-row .column-details .icon.active .path14:before,
.create-profile .row-area.top-row .column-details .icon.active .path15:before,
.create-profile .row-area.top-row .column-details .icon.active .path16:before,
.create-profile .row-area.top-row .column-details .icon.active .path17:before,
.create-profile .row-area.top-row .column-details .icon.active .path18:before,
.create-profile .row-area.top-row .column-details .icon.active .path19:before,
.create-profile .row-area.top-row .column-details .icon.active .path20:before{ color: #ffffff; }

.modal-backdrop.show { opacity: .9; background-color: #fff;}

.back-btn{ display: block; text-align: center; }
.back-arrow{ width: 45px; height: 45px; line-height: 45px; margin-bottom: 20px; text-align: center; display: inline-block; border-radius: 100%; background-color: #263a7b; }
.back-btn .custom-checkbox .custom-control-label{ color: #263a7b;  font-weight: 600; line-height: 20px;}
.back-arrow i{ font-size: 24px; }
.back-arrow i:before{ color: #ffffff; }

/**** Menu Comedy ****/
.inner-area{ position: relative; padding-top: 20px; }
.inner-area .back-btn-top, .inner-area .back-btn-bottom{ position: absolute; left: 30px; top: 0; }
.inner-area .icon-left{ font-size: 26px; opacity: 0.5; margin-right: 10px; }
.inner-area .icon-left:before{ color: #263A7B;}
.inner-area .back-btn-top span, .inner-area .back-btn-bottom span{ color: #263A7B; opacity: 0.5; font-size: 24px; font-family: 'Sansa Pro SmBd'; font-weight: 600; display: inline-block;vertical-align: middle; }
.inner-area .column-details{ margin-bottom: 30px; }
.inner-area .column-details .icon{font-size: 100px; margin-top: 10px;}
.inner-area .column-details .default-heading{ margin-bottom: 50px; }
.inner-area .column-details .default-heading h3{ font-size: 24px; text-transform: capitalize; }

.ingredientes{ padding:30px 0; position: relative; /*border-top: #263a7b dashed 1px; border-bottom: #263a7b dashed 1px;*/}
.ingredientes:before{position: absolute; content: ""; left: 0;  right: 0;  top:0px; border: #263a7b dashed 1px;}
.ingredientes:after{position: absolute; content: ""; left: 0;  right: 0;  bottom: 0px; border: #263a7b dashed 1px;}
.ingredientes .ingredientes-list{ position: relative; margin-bottom: 30px;}
.ingredientes .ingredientes-list:last-child{ margin-bottom: 0; }
.ingredientes .ingredientes-list .default-heading h4{ display: block; color:#263a7b; margin-bottom: 10px; }
.ingredientes .ingredientes-list ul{ list-style: none; }
.ingredientes .ingredientes-list ul li{ display: inline-block; vertical-align: top; margin-bottom: 5px; width: 100%; max-width: 50%; margin-right: -3px; }
.ingredientes .ingredientes-list ul li p{ color: #353535; margin-bottom: 0; }

.slide-area{ padding: 15px 0; }
.slide-area span{font-size: 18px;  color:#263a7b; }
.slide-area .icon-top-arrow{ transform: rotate(180deg); font-size: 24px; cursor: pointer;}
.slide-area .icon-top-arrow.ninety{transform: rotate(90deg);}

.vocation-area{ position: relative; margin-top: 30px; padding: 25px 20px; background-color: #D2DC28; border-radius: 10px; top: -10px; }
.vocation-area .default-heading{ text-align: center; text-transform: uppercase; margin-bottom: 15px; }
.vocation-area .default-heading h3{ color: #263a7b; font-family: 'Sansa Pro SmBd'; font-weight: 600;}
.vocation-area .starts{ display: block; text-align: center; }
.vocation-area .starts i{ display: inline-block; padding: 0 5px; font-size: 32px;}
.inner-area .back-btn-bottom{ position: relative;  left: auto; top: auto; padding: 20px 0; text-align: center; display: block;}


/**** Activities ****/
.activity.maxwidth{max-width: 700px;}
.activity.maxwidth .column-details p{ text-align: left; padding-bottom: 30px; }

.listing{ position: relative; list-style: none; padding:0px 30px 30px 30px; }
.listing:after{position: absolute; content: ""; left: 0;  right: 0;  bottom: 0px; border: #263a7b dashed 1px;}
.listing li{ position: relative; padding: 0px 0 10px 0; margin-top: 10px; font-size: 18px; line-height: 22px; display: block; padding-left: 30px; }
.listing li:before{ height: 9px; width: 9px; border-radius: 100%; background-color: #73C0B9; position: absolute; left: 0; top: 5px; content: ""; }
.numbers.listing li{ margin-top: 0; }
.numbers.listing li:before{ display: none; }
.numbers.listing li span{color: #73C0B9; position: absolute; left: 0;}

.large-img-area{display: block; text-align: center;}
.large-img-area .default-heading{ padding: 15px 0; }
.large-img-area .default-heading h3{ color: #73C0B9; font-weight: 600; }

.recently-area{ position: relative; padding: 30px 30px; }
.add-items{ display: block; text-align: center; }


.news-area{ position: relative; }
.news-area .default-heading{ display: block; text-align: center; padding:30px 0 15px 0; }
.news-area .default-heading h2{ color: #263A7B; font-size: 30px; text-transform: uppercase; font-family: 'Sansa Pro SmBd'; font-weight: 600; }
.tags{ position: relative; padding: 15px 0 30px 0; }
.tags li{vertical-align: middle; text-align: center; }
.tags li a{ width: 100%; display: block; border: #58C3B9 solid 3px; border-radius: 15px; padding: 12px 10px; color: #263A7B; text-transform: uppercase; font-size: 12px; }
.tags li:hover a,
.tags li.active a{background-color: #58C3B9; color: #ffffff;}
.tags .owl-nav{ top: 35%; }
.tags .owl-nav .owl-prev{ left: -10px; }
.tags .owl-nav .owl-next{ right: -10px; }
.tags .icon-small-arrow-left:before, .tags .icon-small-arrow-right:before{color: #58C3B9;}

.circling-area{ display: flex; align-items: center; text-align: center; justify-content: center; height: 265px; width: 265px; border-radius: 100%; background-color: #58C3B9; padding: 25px; margin: 0 auto; }
.circling-area.dark{ background-color: #017C73; }
.circling-area p{ font-size: 24px; line-height: 26px; color:#ffffff; font-family: 'Sansa Pro'; font-weight: 400; text-transform: uppercase; margin-bottom: 0;}
.circling-area span{ display: block; color:#ffffff; font-weight: 300; font-family: 'Sansa Pro';}

.box-area-small{ display: flex; align-items: center; text-align: center; width: 100%; max-width: 265px; border-radius: 10px; background-color: #D25F88; padding: 25px; margin: 0 auto;}
.box-area-small p{ font-size: 24px; line-height: 26px; color:#ffffff; font-family: 'Sansa Pro SmBd'; font-weight: 600; text-transform: uppercase; margin-bottom: 0;}

.news-area .add-items, .news-area .add-items, .news-area .circling-area, .news-area .box-area-small, .news-area .advertisement{ margin-bottom: 50px; }

.search-area{position: relative;  width: 100%; max-width: 240px; margin: 0 auto;}
.search-area .form-control{ border-color: #cdcdcd; max-width: 80%;}
.search-area .search-btn{ border: none; background:none; position: absolute; top: 10px; right: 10px; }
.search-area .search-btn .icon-search{ font-size: 30px; }




/**** Edit profile Dropdown ****/
.account-area{ display: inline-block; margin-right: 10px; }
.account-area > a.dropdown-toggle{ position: relative; width: 55px; text-align: center; display: inline-block; }
.dropdown-header{ color: #263a7b; font-size: 30px; font-weight:600; text-align: center; padding:25px ​10px 15px ​10px; }
.dropdown-header i{ font-size: 24px;}
.account-area .dropdown-menu{ z-index: 999; left: auto !important; right:-100%; transform: inherit !important; padding:10px 10px; top:100% !important; margin-top: 25px; width: 100%; max-width: 311px; min-width: 311px; border:none; background-color:rgba(255, 255, 255, 0.8); }
.account-area .dropdown-menu:before{ position: absolute; right: 30%; top: -20px; content:""; opacity: 0.9; background-color:#ffffff; width: 100%; max-width: 35px; height:35px; border-radius: 5px; }
.account-area .dropdown-menu:after{ position: absolute; right: 35%; top: 0;  margin-top: -10px; font-size:15px; content: "\e9c7"; /*color:#263A7B;*/ font-family: 'someal'; width: 100%; max-width: 10px;}

.account-area .dropdown-menu .dropdown-item{ padding: 15px 0; color: #ffffff; font-weight: 300; background-color: inherit;}
.account-area .dropdown-menu .dropdown-item:focus,
.account-area .dropdown-menu .dropdown-item:hover{ color: #198D3A; }
.account-area .user-profile-links{ position: relative; padding: 20px 20px;  border-top: #198D3A solid 1px; border-bottom:#198D3A solid 1px;}
.account-area .btn-link{ min-height: inherit; padding: 20px 20px; color: #198D3A; display: block; text-decoration: none;}

.account-area .general-area{background: #d2dc29; margin-left: 6px;}
.account-area .dropdown-menu .box-area{ max-width: 48%; min-height: inherit; padding: 20px 0; display: inline-block;}
.account-area .dropdown-menu .box-area .icon{ font-size: 50px; padding: 0px 0 15px 0; }
.account-area .dropdown-menu .box-area .default-heading{ margin-bottom: 0; }
.account-area .dropdown-menu .box-area .default-heading h2{ font-size: 20px; line-height: 22px; }

.account-area .box-area.add-profile{ max-width: 100%; background-color: #263a7b; }
.account-area .box-area.add-profile .add-icon{ cursor: pointer; width: 50px; height:50px; display: flex; vertical-align: middle; justify-content: center; background-color: #ffffff; border-radius: 100%; margin: 0 auto  15px auto; }
.account-area .box-area.add-profile .add-icon .icon-plus{ font-size: 20px; padding: 15px; }
.account-area .box-area.add-profile .add-icon .icon-plus:before{color: #263a7b;}
.account-area .box-area.add-profile .default-heading h2{ color: #ffffff; }

.account-area .signout-area a{ color: #263a7b;}
.account-area .signout-area .icon-close-circle:before{color: #263a7b;}

