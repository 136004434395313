.go-back-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: 100%;

    text-align: center;

    margin: auto 16px auto auto;

}

.go-back-link-container .icon-small-arrow-left { 
    color: var(--inner-area-icon-left--before-color);
    opacity: 1;
}

.go-back-link-container .button {
    margin-bottom: 10px;
    background-color: var(--inner-area-back-btn-top-background-color);
    border: none;

    font-family: "Sansa Pro SmBd";

    color: #263A7B;
    opacity: 0.5;

    font-size: 24px;
}