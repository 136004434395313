.chat-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 10px 8px;
    border-bottom: 1px solid #000;
}

.chat__icon {
    font-size: 32px;
    text-align: center;
    align-self: flex-start;
}

.chat__icon.center {
    align-self: center;
}

.chat__last-message-container { 
    font-size: 16px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 8px;
}

.chat__last-message-container p { 
    font-size: 16px;
    text-align: left;
}

.chat__date {
}

.chat__title {
    font-weight: 600;
}

.chat__last-message {
}