/**** Header **/
.headerSection{ padding: 0 0px 0 0px; position: relative; z-index: 99999; background-color: #ffffff; }
.headerSection.border-botm{border-bottom: #198D3A solid 1px; }
.headerSection .minheight{height: 100%; min-height: 100px;}
.navbar-brand{ padding:$mar-padd-None; margin-right: 0; font-size: 36px; font-weight: 700;  color: #ffffff; text-transform: uppercase;}
.headerSection .minheight .dropdown-toggle::after{ display: none; }
.headerSection .minheight .dropdown-toggle .icon-user{ font-size: 48px; }
.navbar-brand{ 
    width: 100%;
    max-width: 310px;
    margin: 0 auto;
    /*
    display: block;
    */
}
.navbar-brand.auto-width{ display: inline-block; max-width: 180px;}


.navbar-brand-middle{display: block; max-width: 75px; margin: 0 auto;}
.navbar-brand-middle.textual-area{ max-width: inherit; }
.navbar-brand-middle.textual-area span{ font-size: 46px; font-family: 'Sansa Pro'; font-weight: 800; color: #263a7b; }


.navbar{  padding: 0; }
.navbar .navbar-nav{ margin-top: 50px; }
.navbar .navbar-nav li.nav-item{ display: block; }
.navbar .navbar-nav li.nav-item > a.nav-link{padding: 15px 0; font-size: 18px; text-align: center; letter-spacing:1px; color:$default-white-color; font-weight:600; text-transform: uppercase;}
.navbar .navbar-nav li.nav-item > a.nav-link i{color: #ffffff;}

.navbar .navbar-nav li.nav-item > a.nav-link:hover,
.navbar .navbar-nav li.nav-item > a.nav-link.active,
.navbar .navbar-nav li.nav-item.show > a.nav-link{color:#d2dc28;}

.navbar .navbar-nav li.nav-item > a.nav-link:hover i,
.navbar .navbar-nav li.nav-item > a.nav-link.active i,
.navbar .navbar-nav li.nav-item.show > a.nav-link i{color: #d2dc28;}

.navbar .navbar-nav .active > .nav-link, 
.navbar .navbar-nav .nav-link.active, 
.navbar .navbar-nav .nav-link.show, 
.navbar .navbar-nav .show > .nav-link,
.navbar .navbar-nav .open > .nav-link{ color:$second-type-of-color;}

.navbar .navbar-collapse {position: relative;}
.navbar-nav .dropdown-menu{min-width: 250px; /*left: auto; right: 0;*/ padding: 10px 20px; color: #6a6a6a; border-radius: 0; border:none; margin: 0px 0 0 0;}

.navbar-nav li .dropdown-menu {min-width: 250px; padding: 10px 20px; color: #6a6a6a; border-radius: 0; border:none; margin: 0px 0 0 0;}
.navbar-nav li .dropdown-item:focus, .navbar-nav li .dropdown-item:hover {color: #153c59;	background-color:transparent;}
.navbar-nav li .dropdown-item{color:#020202;  border-bottom: #f0f0f0 solid 1px; padding:6px 10px; font-size: 14px; line-height:16px; position: relative;}
.navbar-nav li .dropdown-item:before{ position: absolute; background-color: #d51b00; height: 5px; width: 5px; left: 0; top: 12px; border-radius: 100%; content:""; }
.navbar-nav li .dropdown-item:last-child{ margin-bottom: 5px; }
/*.navbar-nav li .dropdown-toggle::after{ display: none; }*/
#triangle-down:after{ position: absolute; left: 22px; top: -12px; width: 0; height: 0; content: ''; border-left: 8px solid transparent;	border-right: 8px solid transparent; border-top: 8px solid #ffffff;}


.navbar-nav li .dropdown-menu.login-form{ padding: 20px 20px; }
.navbar-nav li .dropdown-menu.login-form .btn{ font-size: 14px; padding: 5px 20px; width: 100%;  text-transform: uppercase;}

.headerSection.menu-fixed{ position: fixed; top: 0; width: 100%; left: 0; right: 0; z-index:99999; border-bottom: #17438e solid 1px;}
/*.headerSection.menu-fixed .navbar-brand{ max-width: 130px; }
.headerSection.menu-fixed .top-head-area{ padding: 5px 0; }
.headerSection.menu-fixed .top-head-area .default-heading h5{ font-size: 24px; }
.headerSection.menu-fixed .middle-content.paddTop{ padding-top: 104px; }*/


.lagguage-change{ position: relative; width: 100px;}
.lagguage-change .dropdown-menu{ margin-top: 10px; }
.lagguage-change  .nav-link.dropdown-toggle{ color: #ffffff; padding: 0; display: inline-block; vertical-align: middle; font-weight: bold; line-height: 0; width: 100%; max-width: 60px;}
.lagguage-change .dropdown-toggle::after{ display: none; }
.lagguage-change i{ font-size: 12px; line-height: 0;}

.logo-area{ width: 150px; }

.dropdown-submenu:hover ul{display: block;left: 100%;}
.dropdown-submenu{position:relative;}  
.dropdown-submenu:hover .dropdown-menu{  top:0;}

.humbarger-arrow-area{ position: relative; left: 0; z-index: 1; cursor: pointer; /*display: none; */ display: inline-block;}
.humbarger-arrow-area .icon-menu-bar{ font-size: 40px; }
.humbarger{ position: relative; display: inline-block;}

.humbarger-arrow-area .humbarger.closed .center-border{ display: none; }
.humbarger-arrow-area .humbarger.closed:before{ top: 0px; transform: rotateZ(45deg); }
.humbarger-arrow-area .humbarger.closed:after{transform: rotateZ(-45deg); bottom: -3px;}

.center-border{ height: 3px; width: 33px; background-color: #000;  position:absolute; top:50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.humbarger:before{ position: absolute; top: -10px; left: 0; right: 0; height: 3px; width: 33px; background-color: #000; content: ""; }
.humbarger:after{ position: absolute; bottom: -10px; left: 0; right: 0; height: 3px; width: 33px; background-color: #000; content: ""; }

.top-head-area{ position: relative; padding: 0px 0 0px 0;}
.top-head-area .default-heading h5{letter-spacing: 2px; font-weight:400; color: #198D3A; text-transform: uppercase;}
.top-head-area .default-heading.main-heading{ font-weight:600; text-transform: capitalize;}

.top-head-area .humbarger-arrow-area{ position: absolute; }

