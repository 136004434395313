.container { 
    margin-top: 16px;
}

.info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.info__img {
    margin-bottom: 20px;
    max-height: 350px;
    max-width: 350px;
}

.activity .column-details p.external-paragraph {
    padding-bottom: 10px;
}

.advice-dinner-media-container.activity {
    align-self: center;
    justify-self: center;
    vertical-align: middle;

    margin: 0px;

    text-align: center;

    width: 100%;
    max-width: 180px;
    min-height: auto;
    max-height: 50px;
    
    border-radius: 8px;
    
    background-color: #8F5DC7;
}

.advice-dinner-media-container.activity .text {
    margin: 8px auto;
    font-family: "Sansa Pro";
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    
    color: #FFFFFF;
}

@media screen and (min-width: 768px) {
    .info__img {
        max-height: 700px;
        max-width: 700px;
    }
}