.splashscreen-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    z-index: 99;
}

.splashscreen-container img { 
    height: 200px;
    width: 200px;
}