.outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px;
}

.messages-head-area { 
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
}

.middle-content.inner-area.col-12.message-head {
    padding-bottom: 8px;
}

.chat-list-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    border: none;
    margin: 0px;
    
    overflow: hidden;
    overflow-y: auto;
}

.send-message-container {
    position: sticky;
    bottom: 2px;
    left: auto;
    right: auto;
    display:  flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    text-align: center;
    padding: 0px;
    margin: 0px;
}

.message-title-container {
    display: none;
}

.textarea-message {
    width: 85%;
    padding: 8px;
    margin: auto 6px;
    resize: none;
}

.textarea-button {
    width: 15%;
    height: 100%;
    font-size: 24px;
}

.message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    margin: 12px 0;
    
    padding: 8px;
    background-color: var(--chat-page-message-container-background-color);
    border-radius: 10px;
}

.message-container.left {
    align-self: flex-start;
    color: var(--chat-page-message-container-left-color);
    background-color: var(--chat-page-message-container-left-background-color);
}

.message-container.right {
    align-self: flex-end;
    color: var(--chat-page-message-container-right-color);
    background-color: var(--chat-page-message-container-right-background-color);
}

/*
Baffetto user
.message-container.right:after {
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: var(#13438B);
    border-right: 0;
    border-bottom: 0;
    margin-top: 0;
    margin-right: -10px;
    transform: rotate(
        90deg
    );
}
*/

@media screen and (min-width: 340px) {
   
}

@media screen and (min-width: 768px) {
    .message-title-container {
        display: block;
    }

    .chat-list-container {
        width: 100%;
        text-align: center;
        border: 2px solid #030303;
        margin: 22px 6px 0px 6px;
        
        max-height: 65vh!important;
    }
    
    .send-message-container {
        margin: 16px 0px 0px 0px;
        bottom: 6px;
    }
    
    .textarea-message {
        margin: auto;
    }
}