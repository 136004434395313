html, body { height: $full-height;}
body {background:$body-color; color:$default-text-color; padding:$mar-padd-None; font-family:$default-fontfamily01; font-size:$default-font-size; overflow-x:hidden; 

text-rendering: optimizeLegibility;
text-rendering: geometricPrecision;
font-smooth: always;

font-smoothing: antialiased;
-moz-font-smoothing: antialiased;
-webkit-font-smoothing: antialiased;
-webkit-font-smoothing: subpixel-antialiased;
-moz-osx-font-smoothing: grayscale;
}
*,figure,ul,li{margin:$mar-padd-None;  padding:$mar-padd-None; list-style:none }
h1, h2, h3, h4, h5,h6 {line-height:$default-line-height-norm; margin-bottom: 0;}
a, .btn, button, i{color:$default-black-color;}
a:hover{color:$second-type-of-color;}
.trs { -webkit-transition: all .3s ease-out;-moz-transition: all .3s ease-out;-ms-transition: all .3s ease-out;-o-transition: all .3s ease-out;
transition: all .3s ease-out;}
a:hover, a:focus {text-decoration:$textDecoration; outline:$outlineNone;}
img{ max-width:100%;}
p{font-size: 18px; line-height: 24px; font-weight: 400;}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{color:$placeholder-color; opacity:1;}
input::-moz-placeholder, textarea::-moz-placeholder {color:$placeholder-color; opacity:1;}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {color:$placeholder-color; opacity:1;}
input:-moz-placeholder,textarea:-moz-placeholder {color:$placeholder-color; opacity:1;}
select,input,textarea{ -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }


@font-face {
    font-family: 'Sansa Pro';
    src: url('../fonts/SansaPro-Bold.eot');
    src: url('../fonts/SansaPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SansaPro-Bold.woff2') format('woff2'),
        url('../fonts/SansaPro-Bold.woff') format('woff'),
        url('../fonts/SansaPro-Bold.ttf') format('truetype'),
        url('../fonts/SansaPro-Bold.svg#SansaPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sansa Pro';
    src: url('../fonts/SansaPro-Light.eot');
    src: url('../fonts/SansaPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SansaPro-Light.woff2') format('woff2'),
        url('../fonts/SansaPro-Light.woff') format('woff'),
        url('../fonts/SansaPro-Light.ttf') format('truetype'),
        url('../fonts/SansaPro-Light.svg#SansaPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sansa Pro Nor';
    src: url('../fonts/SansaPro-Normal.eot');
    src: url('../fonts/SansaPro-Normal.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SansaPro-Normal.woff2') format('woff2'),
        url('../fonts/SansaPro-Normal.woff') format('woff'),
        url('../fonts/SansaPro-Normal.ttf') format('truetype'),
        url('../fonts/SansaPro-Normal.svg#SansaPro-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sansa Pro SmBd';
    src: url('../fonts/SansaPro-SemiBold.eot');
    src: url('../fonts/SansaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SansaPro-SemiBold.woff2') format('woff2'),
        url('../fonts/SansaPro-SemiBold.woff') format('woff'),
        url('../fonts/SansaPro-SemiBold.ttf') format('truetype'),
        url('../fonts/SansaPro-SemiBold.svg#SansaPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



/**** Web Icons */
@font-face {
  font-family: 'someal';
  src:  url('../fonts/someal.eot?l8tq3z');
  src:  url('../fonts/someal.eot?l8tq3z#iefix') format('embedded-opentype'),
    url('../fonts/someal.ttf?l8tq3z') format('truetype'),
    url('../fonts/someal.woff?l8tq3z') format('woff'),
    url('../fonts/someal.svg?l8tq3z#someal') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'someal' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
}

.icon-desktop .path1:before {
  content: "\e900";
  color: rgb(51, 51, 51);
}
.icon-desktop .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(44, 80, 154);
}
.icon-desktop .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(44, 80, 154);
}
.icon-mobile .path1:before {
  content: "\e903";
  color: rgb(51, 51, 51);
}
.icon-mobile .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(44, 80, 154);
}
.icon-mobile .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(44, 80, 154);
}
.icon-apple .path1:before {
  content: "\e906";
  color: rgb(50, 40, 91);
}
.icon-apple .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-apple .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-apple .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-bowl-foods .path1:before {
  content: "\e90a";
  color: rgb(50, 40, 91);
}
.icon-bowl-foods .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-foods .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-bowl-foods .path4:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-foods .path5:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-foods .path6:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-foods .path7:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-foods .path8:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-foods .path9:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-spoon .path1:before {
  content: "\e913";
  color: rgb(50, 40, 91);
}
.icon-bowl-spoon .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-spoon .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-bowl-spoon .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-bowl-spoon .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path1:before {
  content: "\e918";
  color: rgb(50, 40, 91);
}
.icon-buns .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path3:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path4:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path5:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path6:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-buns .path7:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-buns .path8:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path9:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path10:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-buns .path11:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-buns .path12:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path13:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-buns .path14:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-buns .path15:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-carrot .path1:before {
  content: "\e927";
  color: rgb(50, 40, 91);
}
.icon-carrot .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path3:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path4:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path5:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path6:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(50, 50, 50);
}
.icon-carrot .path7:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path8:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-carrot .path9:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-carrot .path10:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path11:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path12:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path13:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path14:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-carrot .path15:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path1:before {
  content: "\e936";
  color: rgb(50, 40, 91);
}
.icon-cow .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path3:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path4:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path5:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path6:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path7:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path8:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path9:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path10:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-cow .path11:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-cow .path12:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-cow .path13:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-dish .path1:before {
  content: "\e943";
  color: rgb(50, 40, 91);
}
.icon-dish .path2:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-dish .path3:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-dish .path4:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-dish .path5:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-dish .path6:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-egges .path1:before {
  content: "\e949";
  color: rgb(50, 40, 91);
}
.icon-egges .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-egges .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-egges .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-egges .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-egges .path6:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-egges .path7:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-egges .path8:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-egges .path9:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-female-user .path1:before {
  content: "\e952";
  color: rgb(227, 6, 19);
}
.icon-female-user .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-female-user .path3:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-female-user .path4:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-female-user .path5:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-female-user .path6:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-female-user .path7:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-food-dish .path1:before {
  content: "\e959";
  color: rgb(50, 40, 91);
}
.icon-food-dish .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-food-dish .path3:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-food-plate .path1:before {
  content: "\e95c";
  color: rgb(50, 40, 91);
}
.icon-food-plate .path2:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-food-plate .path3:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-food-plate .path4:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-fruits .path1:before {
  content: "\e960";
  color: rgb(50, 40, 91);
}
.icon-fruits .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-fruits .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-fruits .path4:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-fruits .path5:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-fruits .path6:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-fruits .path7:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-grain .path1:before {
  content: "\e967";
  color: rgb(227, 6, 19);
}
.icon-grain .path2:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-grain .path3:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-grain .path4:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-grain .path5:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-grain .path6:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-grain .path7:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-grain .path8:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-grain .path9:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-heart-beat .path1:before {
  content: "\e970";
  color: rgb(50, 40, 91);
}
.icon-heart-beat .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-heart-beat .path3:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-heart-beat .path4:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-meater .path1:before {
  content: "\e974";
  color: rgb(50, 40, 91);
}
.icon-meater .path2:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-meater .path3:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-meater .path4:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-milk-bottle .path1:before {
  content: "\e978";
  color: rgb(50, 40, 91);
}
.icon-milk-bottle .path2:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-milk-bottle .path3:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-milk-bottle .path4:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-milk-bottle .path5:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-milk-bottle .path6:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-milk-bottle .path7:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-mouse .path1:before {
  content: "\e97f";
  color: rgb(50, 40, 91);
}
.icon-mouse .path2:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-mouse .path3:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-mouse .path4:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-mouse .path5:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-neutrients .path1:before {
  content: "\e984";
  color: rgb(50, 40, 91);
}
.icon-neutrients .path2:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-neutrients .path3:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-neutrients .path4:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-neutrients .path5:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-neutrients .path6:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path1:before {
  content: "\e98a";
  color: rgb(50, 40, 91);
}
.icon-pig .path2:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path3:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path4:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path5:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path6:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path7:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path8:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path9:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-pig .path10:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-pig .path11:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-rice-dish .path1:before {
  content: "\e995";
  color: rgb(227, 6, 19);
}
.icon-rice-dish .path2:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-rice-dish .path3:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-rice-dish .path4:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-rice-dish .path5:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-rice-dish .path6:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-rice-dish .path7:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-sea-foods .path1:before {
  content: "\e99c";
  color: rgb(50, 40, 91);
}
.icon-sea-foods .path2:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-sea-foods .path3:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-sea-foods .path4:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-sea-foods .path5:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-sea-foods .path6:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-sea-foods .path7:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-sea-foods .path8:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-soup .path1:before {
  content: "\e9a4";
  color: rgb(227, 6, 19);
}
.icon-soup .path2:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-soup .path3:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-soup .path4:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-soup .path5:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-soup .path6:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-soup .path7:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-user .path1:before {
  content: "\e9ab";
  color: rgb(50, 40, 91);
}
.icon-user .path2:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-user .path3:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-user .path4:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-user .path5:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-user .path6:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-user .path7:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-women-face .path1:before {
  content: "\e9b2";
  color: rgb(50, 40, 91);
}
.icon-women-face .path2:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-women-face .path3:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-women-face .path4:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-women-face .path5:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-women-face .path6:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-women-face .path7:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-women-face .path8:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-menu-bar .path1:before {
  content: "\e9ba";
  color: rgb(255, 255, 255);
}
.icon-menu-bar .path2:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-menu-bar .path3:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon-menu-bar .path4:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-menu-bar .path5:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(50, 40, 91);
}
.icon-email:before {
  content: "\e9bf";
  color: #32285b;
}
.icon-edit:before {
  content: "\e9c0";
  color: #c4c4c4;
}
.icon-edit2:before {
  content: "\e9c1";
  color: #c4c4c4;
}
.icon-blankstar:before {
  content: "\e9c2";
  color: #263a7b;
}
.icon-solidstar:before {
  content: "\e9c3";
  color: #32285b;
}
.icon-close:before {
  content: "\e9c4";
  color: #fff;
}
.icon-close-circle:before {
  content: "\e9c5";
  color: #fff;
}
.icon-download-arrow:before {
  content: "\e9c6";
  color: #32285b;
}
.icon-top-arrow:before {
  content: "\e9c7";
}
.icon-left-arrow:before {
  content: "\e9c9";
  color: #32285b;
}
.icon-right-arrow:before {
  content: "\e9ca";
  color: #32285b;
}
.icon-left-arrow-solid .path1:before {
  content: "\e9cb";
  color: rgb(101, 103, 106);
}
.icon-left-arrow-solid .path2:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-right-arrow-solid .path1:before {
  content: "\e9cd";
  color: rgb(101, 103, 106);
}
.icon-right-arrow-solid .path2:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-minus:before {
  content: "\e9cf";
}
.icon-plus:before {
  content: "\e9d0";
}
.icon-search .path1:before {
  content: "\e9d1";
  color: rgb(115, 192, 185);
}
.icon-search .path2:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-search2:before {
  content: "\e9d3";
  color: #0f123a;
}
.icon-small-arrow-left:before {
  content: "\e9d4";
  color: #d9e021;
}
.icon-small-arrow-right:before {
  content: "\e9d5";
  color: #d9e021;
}



/***********default*********/
.wrapper{ background-color: #ffffff; position: relative; }
.body-color{ background-color: #000000; }
.container { max-width: 1200px; width: 100%;}
.positioRelative{ position:relative;}
.height100{ height: 100%; }
.height80{ height: 80%; }
.verticalyMiddle{ position:absolute; top:50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.overflowHidden{ overflow: hidden; }
.bodyOverlay{overflow: hidden;}
.bodyOverlay:before{position:fixed; background-color: #000000; opacity: 0.3; content: ''; left: 0; right: 0; bottom: 0; top: 0; z-index: 999;}
.phone-hide{ display:none;}
.img-cover-area{background: no-repeat center center scroll; -webkit-background-size: $bg-size-cover; -moz-background-size: $bg-size-cover; -o-background-size: $bg-size-cover; background-size: $bg-size-cover; background-position:top center;}

.badge-primary{background-color:inherit; color:$second-type-of-color; font-size: 12px; border-radius: 100%; text-align: center; line-height: 20px; padding: 0;}

.paragraph{ position: relative;}
.paragraph p{ display: block; font-size: 22px; line-height:36px; color: #ffffff; font-weight: 400; margin-bottom:0;}

.maxwidth{ max-width: 600px; margin: 0 auto; }
.maxwidth .navbar-brand-middle{ margin-right:0; }

/*.hide-on-mobile{ display: block; }*/
.hide-on-desktop{ display: none; }


a:not([href]).btn:not([tabindex]){color: $default-white-color;}

.btn{ font-size: 18px; padding:6px 30px; min-width: 250px; min-height: 50px; line-height: 36px; text-align: center; text-transform: uppercase; font-weight: bold; letter-spacing: 2px; border-radius: 10px;}
.btn.focus, .btn:focus{box-shadow:$boxShadow;}

.btn-primary{ box-shadow:none; background:#d2dc29; border-color:#d2dc29; color:$default-white-color; color: $default-white-color; }
.btn-primary:hover,
.btn-primary:focus{ box-shadow:none; background-color:$first-type-of-color; border-color:$first-type-of-color; color:$hoverbtncolor01;}
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle{ background-color: $first-type-of-color; border-color:$first-type-of-color; color: $default-white-color; box-shadow:$boxShadow;}

.btn-secondary{box-shadow:none; background: $second-type-of-color;  border-color:$second-type-of-color; color:$default-white-color;}
.btn-secondary:hover,
.btn-secondary:focus{box-shadow:none; background:$third-type-of-color; border-color: $third-type-of-color; color:$default-white-color;}
.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-secondary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-secondary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active, 
.btn-secondary:not(:disabled):not(.disabled):active, 
.show>.btn-secondary.dropdown-toggle{box-shadow:none; background:$third-type-of-color; border-color: $third-type-of-color; color:$default-white-color;}

.btn-success{color: $default-white-color; background-color:$default-green-color; border:$default-green-color solid 3px; display: inline-block;}
.btn-success:hover,
.btn-success:focus{ background: $default-white-color; border-color: $default-green-color; color:$default-green-color;}
.btn-success:not(:disabled):not(.disabled).active:focus, 
.btn-success:not(:disabled):not(.disabled):active:focus, 
.show>.btn-success.dropdown-toggle:focus,
.btn-success:not(:disabled):not(.disabled).active, 
.btn-success:not(:disabled):not(.disabled):active, 
.show>.btn-success.dropdown-toggle{ background: $default-white-color; border-color: $default-green-color; color:$default-green-color;}

.btn-light{color: $first-type-of-color; background-color:$default-white-color; border:$default-white-color solid 3px; display: inline-block;}
.btn-light:hover,
.btn-light:focus{ background: $default-white-color; border-color: $hoverbtncolor02; color:$first-type-of-color;}
.btn-light:not(:disabled):not(.disabled).active:focus, 
.btn-light:not(:disabled):not(.disabled):active:focus, 
.show>.btn-light.dropdown-toggle:focus,
.btn-light:not(:disabled):not(.disabled).active, 
.btn-light:not(:disabled):not(.disabled):active, 
.show>.btn-light.dropdown-toggle{ background: $default-white-color; border-color: $hoverbtncolor02; color:$first-type-of-color;}

.btn-link{ color:$default-white-color; max-width:200px; text-align: left; font-size: 16px; padding: 0;}
.btn-link:hover{ color:$default-white-color; text-decoration: underline; }

.default-heading{ display:block; position:relative;}
.default-heading h1, .default-heading > a.heading01{ font-size:36px; line-height: 40px;}
.default-heading h2, .default-heading > a.heading02{ font-size:30px; line-height: 36px;}
.default-heading h3, .default-heading > a.heading03{ font-size:24px; line-height: 30px;}
.default-heading h4, .default-heading > a.heading04{ font-size:18px; line-height: 28px;}
.default-heading h5, .default-heading > a.heading05{ font-size:16px;  line-height: 24px;}
.default-heading h6, .default-heading > a.heading06{ font-size:14px; line-height: 18px;}


.owl-carousel .owl-item img{ display:$display-n-b; width: auto;}
.owl-carousel .owl-dots{text-align: center;
             button.owl-dot{margin:0 5px; background-color:$default-white-color; width:20px; height:20px; border:$second-type-of-color solid 2px; border-radius:$borderRadiusfull;}
             button.owl-dot.active{background-color:$second-type-of-color; border:$second-type-of-color solid 2px;  width:20px; height:20px; border-radius:$borderRadiusfull;}
}

.owl-nav{ position: absolute; top: 45%; width: 100%; left: 0; right: 0;}
.owl-nav .owl-prev{ position: absolute; left:15px;}
.owl-nav .owl-next{ position: absolute; right:15px;}
.owl-nav .owl-prev i, 
.owl-nav .owl-next i{ font-size: 24px;}


/*.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev{ -webkit-transform: rotateY(130deg); transform: rotateY(130deg);} */



button:focus{outline:none;}

.form-group{ position:relative; margin-bottom:25px;
            label{ color:#7a7a7a; font-weight: 500; position: relative; line-height:18px; font-size: 16px; 
            span{ position: absolute; top: 0; right: -10px; color: #FF0000; margin-left:10px;  display: block;}
          }

    select{font-size: 16px; padding:10px 5px; height: auto; color:$default-text-color; background:#ebeff2; border:none; border-bottom:#7f8bb1 solid 2px; border-radius: 0;}
}

.form-control,
.form-inline .form-control{ width: $full-width-area; padding:10px 5px; border:none; border-bottom:#7f8bb1 solid 2px; border-radius: 0; height: auto; font-size:16px; color:$first-type-of-color;
             &.minheight{ min-height:170px;}
 }

.form-group textarea.form-control{border:#7f8bb1 solid 2px;}

.form-group select.custom-select{ /*background:#ebeff2 url(../images/down-arrow.png);*/ background-repeat: no-repeat; background-position: 98% 16px;
    background-size: 15px; border: 1px solid #b9c6d3;}

.form-group .input-group.date .input-group-addon{ background-color: #ddd;  color: #d51b00; padding: 7px 8px; border-radius: 0rem .25rem .25rem 0rem;}
.form-group .input-group.date .input-group-addon .fa{ color: #d51b00;}


.form-control:focus, select:focus, .custom-select:focus, textarea:focus{box-shadow:none; border-color: #d2dc28;}
.form-control:invalid{border-color: #FF0000;}

.required-area{ color: #FF0000;  }
.error-area{ color: #FF0000; font-size: 11px; line-height: 11px; position: absolute; display: block; margin-top: 3px;}

.custom-checkbox .custom-control-label{min-height: inherit;}
.custom-checkbox .custom-control-label::before{ background-color: inherit; border: #24387C solid 2px; border-radius: 3px;}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{border-color:#24387C; background-color:#24387C;}
.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after{ width: 18px; height: 18px; }
.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after{ left: -1.8rem; /*top:-0.2rem;*/ top: 0rem;}
.custom-checkbox .custom-control-input:checked~.custom-checkbox .custom-control-label::before{ border-color: #153c59; background-color:#153c59;}
.custom-checkbox .custom-control-input:focus~.custom-checkbox .custom-control-label::before {  box-shadow: 0 0 0 0.2rem rgba(21,60,89,.25);}
.custom-checkbox .custom-control-input:not(:disabled):active~.custom-checkbox .custom-control-label::before { background-color: #24387C; border-color: #24387C;}

.blue-radio-btn.custom-control.custom-radio.active .custom-control-input:checked ~ .custom-control-label::before { background-color:#ED6941; border-color: #ED6941;}

.custom-switch{padding-left:0; min-height: inherit;}
.custom-switch .custom-control-label{ cursor: pointer; min-height: inherit; vertical-align: middle; border: #198D3A solid 1px; width: 66px; max-width: 66px; height: 30px; max-height: 30px; border-radius: 100px; }
.custom-switch .custom-control-label::before{ left: 7px; }

.custom-switch .custom-control-label::before{ border:none; background-color: #C0C0C2; width: 48px; height: 8px; top:50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.custom-switch .custom-control-label::after{ background: #f3f2f0; /* Old browsers */
background: -moz-linear-gradient(top, #f3f2f0 0%, #b5b0aa 50%, #e3e2de 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #f3f2f0 0%,#b5b0aa 50%,#e3e2de 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #f3f2f0 0%,#b5b0aa 50%,#e3e2de 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f2f0', endColorstr='#e3e2de',GradientType=0 ); /* IE6-9 */ width: 18px; height:18px; top: calc(.25rem + 1px); left: calc(.25rem + 2px);}
.custom-switch .custom-control-input:checked~.custom-control-label{border:#198D3A solid 1px;}
.custom-switch .custom-control-input:checked~.custom-control-label::before{border-color:#198D3A; background-color:#198D3A;}
.custom-switch .custom-control-input:checked~.custom-control-label::after{ /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2bbb54+1,198d3a+49,2bbb54+100 */
background: rgb(43,187,84); /* Old browsers */
background: -moz-linear-gradient(-45deg, rgba(43,187,84,1) 1%, rgba(25,141,58,1) 49%, rgba(43,187,84,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, rgba(43,187,84,1) 1%,rgba(25,141,58,1) 49%,rgba(43,187,84,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, rgba(43,187,84,1) 1%,rgba(25,141,58,1) 49%,rgba(43,187,84,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2bbb54', endColorstr='#2bbb54',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */ -webkit-transform: translateX(2.2rem); transform: translateX(2.2rem); }

/*.custom-control.custom-radio .custom-control-label::after,
.custom-control.custom-radio .custom-control-label::before{ width: 19px; height: 19px; }*/
.custom-control{ min-height: inherit; padding-left: 30px; }
.custom-control-label::before, .custom-control-label::after{ top: 0.16rem; left: -1.8rem; }
.custom-control.custom-radio .custom-control-label::before{border: #A2A2A2 solid 1px; height: 14px; width: 14px;}
.custom-control.custom-radio .custom-control-input:checked~.custom-control-label::after{ background-image: none; }
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before{border-color:#198D3A; background-color:#198D3A;}

.red-radio-btn.custom-control.custom-radio .custom-control-label::before{border: #ff2d55 solid 1px;}
.red-radio-btn.custom-control.custom-radio .custom-control-input:checked~.custom-control-label::after{ background-image: none; }
.red-radio-btn.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before{ border-color:#ff2d55; background-color:#ff2d55;}


.custom-file-input:lang(en)~.custom-file-label::after { content:"Browse"; background-color:#153c59; color:#FFFFFF;}
.our-custom.custom-file{/*width:100%; max-width: 150px;*/ cursor:pointer; height: auto;}
.our-custom.custom-file.martop{ margin-top:10px;}
.our-custom.custom-file.width-Auto{ max-width:100%; margin-right:0;}
.our-custom.custom-file .custom-file-input:lang(en)~.custom-file-label::after { content: "Browse"; background-color:#067b10; display:none;}
.our-custom.custom-file .custom-file-label::after { display:none;}
.our-custom.custom-file .custom-file-input{ min-width:100%; z-index: -1; height: auto; }
.our-custom.custom-file .custom-file-label{ text-align:center; cursor: pointer; height: auto; min-height: 26px; padding:15px 10px;  text-overflow: ellipsis; overflow: hidden;  white-space: nowrap;}
.our-custom.custom-file .custom-file-label i{ margin-right: 5px;}
.our-custom.custom-file .custom-file-label:hover{ background-color:#982F5F;  color:#FFFFFF;}
.our-custom.custom-file .custom-file-label:hover i{ color: #FFFFFF;}

.custom-control-input:focus~.custom-control-label::before{ box-shadow: none; }
.custom-control-input:focus:not(:checked)~.custom-control-label::before { border-color: #198D3A;}
.custom-control-input:not(:disabled):active~.custom-control-label::before {color: #fff; background-color: #153c59;  border-color: #153c59;}
/*.custom-control-input:focus:not(:checked)~.custom-control-label::before,
.custom-control-input:not(:disabled):active~.custom-control-label::before{ background-color: inherit; }*/

.mCSB_scrollTools{ opacity: 1; right: 0px; -webkit-overflow-scrolling: touch;}
.mCSB_scrollTools .mCSB_draggerRail{ width: 6px; background-color: #a9a9a9; -webkit-border-radius: 50px;  -moz-border-radius: 50px;  border-radius: 50px;}
/*.mCSB_scrollTools .mCSB_dragger{ height: 25px !important;}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{width: 14px; border:#132439 solid 3px;  -webkit-border-radius: 50px;  -moz-border-radius: 50px;  border-radius: 50px; background-color: #FFFFFF;}
*/

.mCustomScrollbar{ height: 100%; max-height: 100%; -webkit-overflow-scrolling: touch;}
.mCSB_inside > .mCSB_container{ margin-right: 15px; }
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{width: 8px; background-color:#C0C0C2; border:#C0C0C2 solid 1px;}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:focus .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, 
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{background-color:#198D3A; border:#198D3A solid 1px;}
.mCSB_draggerRail{ display: none; }

.mCustomScrollbar-area .mCustomScrollbar{ max-height: 95%; }
.mCustomScrollbar-area .mCustomScrollbar.small-scroll{ max-height: 85%; }


.table th, .table thead th{vertical-align: middle; border-top: 1px solid #f2f2f2; font-size: 11px; letter-spacing: 0.22px; color: #A2A2A2;  font-weight: 400; line-height: 16px; padding:10px 6px;}
.table td p, .table td{ font-size: 13px; line-height: 18px; margin-bottom: 0; vertical-align: middle; }
.table-striped tbody tr:nth-of-type(odd){background: #FAFAFA 0% 0% no-repeat padding-box;}
.table tr.active-tr{background: #d0e9fd !important;}
.table-striped tbody tr.gray-strip{background: #FAFAFA 0% 0% no-repeat padding-box;}
.table-striped tbody tr.white-strip{background: #FFFFFF 0% 0% no-repeat padding-box;}

.nav-pills{padding-top:10px; margin-bottom: 20px;}
.nav-pills .nav-link{ background-color:inherit; border:none; border-bottom:#153c59 solid 1px; border-radius: 0; padding: 5px 15px; position: relative; display: inline-block; margin-right: -6px;}
.nav-pills .nav-link.active, 
.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus, 
.nav-pills .show>.nav-link{ background-color: inherit; border:none; border-bottom: #d51b00 solid 1px; color: #d51b00;}

.tab-content{ border: none; }
 section{ position: relative; padding: 0 0 30px 0; }




.modal .modal-dialog{ max-width: 840px; width: 100%; margin: 100px auto 10px auto;}
.modal .modal-dialog .modal-content{border-radius: 0px; border:none;  box-shadow: 0px 3px 6px #00000029;}

.modal .modal-dialog .modal-content .modal-body{height: 100%; min-height: 699px;}
.modal .close, .modal-header .close{ font-size: 36px; border: #263a7b solid 2px; height: 40px; line-height: 32px;  width: 40px; border-radius: 100%; text-shadow: none; opacity:100; position: relative; margin: 0; padding: 5px 5px; color: #263a7b; text-align: center;  display: inline-block; z-index: 1; }
.modal-header{ border:0; padding: 15px; justify-content: flex-end;  }

.alert-light{ box-shadow: 0px 10px 30px #00000029; border-radius:20px; color: #324F68; z-index: 9;}
.alert-light p{font-size: 14px; line-height: 18px; margin-bottom: 0; font-weight: 400;; margin-bottom: 0; }

.accordion .card{ border: none; }
.accordion .card .card-body{ padding: 0; }
.accordion> .card > .card-header{ margin-bottom: 0; background-color:inherit; padding:15px ​15px;}
.accordion> .card > .card-header .btn{ min-height: inherit; }
.accordion> .card > .card-header button::before{ transform: rotate(90deg);  content: "\e909"; color: #7f8792; position: absolute; right: 15px; font-size: 14px; font-family: 'swetrack' !important;}
.accordion> .card > .card-header button.collapsed::before{ transform: rotate(0deg); content: "\e909"; color: #7f8792; position: absolute; right: 5px; font-size: 14px; font-family: 'swetrack' !important;}

.alert-dismissible .close{ opacity: 100; }


.btn-group{background: #FFFFFF 0% 0% no-repeat padding-box; overflow: hidden; border: 0.5px solid #707070; border-radius: 11px; text-align: center; }
.btn-group > .btn.btn-link{ background-color: inherit; min-height: inherit; color: #198D3A; margin: 0; line-height: inherit; width: 100%; max-width: 150px; padding:10px 10px; }
.btn-group > .btn.btn-link:first-child{border-right:0.5px solid #707070;}

.datepicker, .ui-timepicker-standard{ min-width: 365px; background-color: #132439; padding:15px; border-radius: 10px;}
.datepicker table{ width: 100%; min-width: 100%;  color: #ffffff;}
.datepicker table.table-condensed thead tr:nth-child(2){ border-radius: 8px; background: rgb(50,79,104); /* Old browsers */
background: -moz-linear-gradient(top, rgba(50,79,104,1) 0%, rgba(232,224,210,1) 51%, rgba(50,79,104,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(50,79,104,1) 0%,rgba(232,224,210,1) 51%,rgba(50,79,104,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(50,79,104,1) 0%,rgba(232,224,210,1) 51%,rgba(50,79,104,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f68', endColorstr='#324f68',GradientType=0 ); /* IE6-9 */}
.datepicker table.table-condensed thead tr:nth-child(2) th{color: #132438;}
.datepicker table.table-condensed thead tr:nth-child(3){ background-color: #213244; }
.datepicker table.table-condensed thead tr th{ text-align: center; }
.datepicker table tr td, .datepicker table tr th{ color: #ffffff; width: 45px; height: 46px; border-radius: 0;}
.datepicker table.table-condensed thead tr:nth-child(2) th:nth-child(1){ border-radius: 8px 0px 0px 8px; }
.datepicker table.table-condensed thead tr:nth-child(2) th:nth-child(3){ border-radius: 0px 8px 8px 0px; }
.datepicker table tr td.day:hover, .datepicker table tr td.focused{background-color: #213244;  border-radius: 100%;}
.datepicker table tr td.active.active, 
.datepicker table tr td.active.highlighted.active, 
.datepicker table tr td.active.highlighted:active, 
.datepicker table tr td.active:active{background-color: #2f3e51; border-color: #2f3e51; border-radius: 100%;}

.datepicker .datepicker-switch:hover, 
.datepicker .next:hover, 
.datepicker .prev:hover, 
.datepicker tfoot tr th:hover{ background-color: inherit; }

.ui-timepicker-standard a{ color: #ffffff; }
.ui-timepicker-standard a.ui-state-hover{ background-color: #198D3A; border: #198D3A solid 1px; }

.modal{ z-index: 9999; }

.nav-tabs{ border: none; justify-content: space-around; padding:15px 0 15px 0; }
.nav-tabs .nav-link{ border: none; font-size: 26px; font-weight: 800; color: #b8bed4; text-transform: uppercase;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover{color: #24387c;}