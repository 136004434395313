.no-data-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
}

#favorites-container-id .default-heading {
    color: #263a7b;
}

.children #favorites-container-id .recipe__name {
    color: #fff;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 28px;

    height: 50px;
}

.children .single-recipe-favorite-container.primo {
    background: var(--children-component-primo-color);
}
.children .single-recipe-favorite-container.secondo {
    background: var(--children-component-secondo-color);
}
.children .single-recipe-favorite-container.contorno {
    background: var(--children-component-contorno-color);
}
.children .single-recipe-favorite-container.dolce {
    background: var(--children-component-dolce-color);
}
.children .single-recipe-favorite-container.forno {
    background: var(--children-component-forno-color);
}
.children .single-recipe-favorite-container.bevande {
    background: var(--children-component-bevande-color);
}
.children .single-recipe-favorite-container.piatto_unico {
    background: var(--children-component-piatto_unico-color);
}
.children .single-recipe-favorite-container.paninoteca {
    background: var(--children-component-paninoteca-color);
}