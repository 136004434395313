.loader-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    background-color: var(--loader-background-color);
    z-index: 9998;
    height: 100%;
    width: 100%;
}