.link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 24px auto;
}

.link-text { 
    color: var(--custom-link-color-text);
    border: none;
    border-bottom: 2px solid var(--custom-link-color-border);
    text-transform: uppercase;
}