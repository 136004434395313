.no-data-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

/**Children mode*/
.children #news-send-message-box {
    display: none;
}