.menu-area .hide-on-desktop .icon-desktop .path1:before,
.left-area .box-area .icon-desktop .path1:before {
    content: "\e900";
    color: var(--left-area-box-area-icon-diets-color-6);
}

.menu-area .hide-on-desktop .icon-desktop .path2:before,
.left-area .box-area .icon-desktop .path2:before {
    content: "\e901";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-5);
}

.menu-area .hide-on-desktop .icon-desktop .path3:before,
.left-area .box-area .icon-desktop .path3:before {
    content: "\e902";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-5);
}

.menu-area .hide-on-desktop .icon-mobile .path1:before,
.left-area .box-area .icon-mobile .path1:before {
    content: "\e903";
    color: var(--left-area-box-area-icon-diets-color-6);
}

.menu-area .hide-on-desktop .icon-mobile .path2:before,
.left-area .box-area .icon-mobile .path2:before {
    content: "\e904";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-5);
}

.menu-area .hide-on-desktop .icon-mobile .path3:before,
.left-area .box-area .icon-mobile .path3:before {
    content: "\e905";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-5);
}

.menu-area .hide-on-desktop .icon-apple .path1:before,
.left-area .box-area .icon-apple .path1:before {
    content: "\e906";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-apple .path2:before,
.left-area .box-area .icon-apple .path2:before {
    content: "\e907";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-apple .path3:before,
.left-area .box-area .icon-apple .path3:before {
    content: "\e908";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-apple .path4:before,
.left-area .box-area .icon-apple .path4:before {
    content: "\e909";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-bowl-foods .path1:before,
.left-area .box-area .icon-bowl-foods .path1:before {
    content: "\e90a";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-foods .path2:before,
.left-area .box-area .icon-bowl-foods .path2:before {
    content: "\e90b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-foods .path3:before,
.left-area .box-area .icon-bowl-foods .path3:before {
    content: "\e90c";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-bowl-foods .path4:before,
.left-area .box-area .icon-bowl-foods .path4:before {
    content: "\e90d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-foods .path5:before,
.left-area .box-area .icon-bowl-foods .path5:before {
    content: "\e90e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-foods .path6:before,
.left-area .box-area .icon-bowl-foods .path6:before {
    content: "\e90f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-foods .path7:before,
.left-area .box-area .icon-bowl-foods .path7:before {
    content: "\e910";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-foods .path8:before,
.left-area .box-area .icon-bowl-foods .path8:before {
    content: "\e911";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-foods .path9:before,
.left-area .box-area .icon-bowl-foods .path9:before {
    content: "\e912";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-spoon .path1:before,
.left-area .box-area .icon-bowl-spoon .path1:before {
    content: "\e913";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-spoon .path2:before,
.left-area .box-area .icon-bowl-spoon .path2:before {
    content: "\e914";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-spoon .path3:before,
.left-area .box-area .icon-bowl-spoon .path3:before {
    content: "\e915";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-bowl-spoon .path4:before,
.left-area .box-area .icon-bowl-spoon .path4:before {
    content: "\e916";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-bowl-spoon .path5:before,
.left-area .box-area .icon-bowl-spoon .path5:before {
    content: "\e917";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path1:before,
.left-area .box-area .icon-buns .path1:before {
    content: "\e918";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path2:before,
.left-area .box-area .icon-buns .path2:before {
    content: "\e919";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path3:before,
.left-area .box-area .icon-buns .path3:before {
    content: "\e91a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path4:before,
.left-area .box-area .icon-buns .path4:before {
    content: "\e91b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path5:before,
.left-area .box-area .icon-buns .path5:before {
    content: "\e91c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path6:before,
.left-area .box-area .icon-buns .path6:before {
    content: "\e91d";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-buns .path7:before,
.left-area .box-area .icon-buns .path7:before {
    content: "\e91e";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-buns .path8:before,
.left-area .box-area .icon-buns .path8:before {
    content: "\e91f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path9:before,
.left-area .box-area .icon-buns .path9:before {
    content: "\e920";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path10:before,
.left-area .box-area .icon-buns .path10:before {
    content: "\e921";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-buns .path11:before,
.left-area .box-area .icon-buns .path11:before {
    content: "\e922";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-buns .path12:before,
.left-area .box-area .icon-buns .path12:before {
    content: "\e923";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path13:before,
.left-area .box-area .icon-buns .path13:before {
    content: "\e924";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-buns .path14:before,
.left-area .box-area .icon-buns .path14:before {
    content: "\e925";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-buns .path15:before,
.left-area .box-area .icon-buns .path15:before {
    content: "\e926";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-carrot .path1:before,
.left-area .box-area .icon-carrot .path1:before {
    content: "\e927";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path2:before,
.left-area .box-area .icon-carrot .path2:before {
    content: "\e928";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path3:before,
.left-area .box-area .icon-carrot .path3:before {
    content: "\e929";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path4:before,
.left-area .box-area .icon-carrot .path4:before {
    content: "\e92a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path5:before,
.left-area .box-area .icon-carrot .path5:before {
    content: "\e92b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path6:before,
.left-area .box-area .icon-carrot .path6:before {
    content: "\e92c";
    margin-left: -1em;
    color: #323232;
}

.menu-area .hide-on-desktop .icon-carrot .path7:before,
.left-area .box-area .icon-carrot .path7:before {
    content: "\e92d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path8:before,
.left-area .box-area .icon-carrot .path8:before {
    content: "\e92e";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-carrot .path9:before,
.left-area .box-area .icon-carrot .path9:before {
    content: "\e92f";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-carrot .path10:before,
.left-area .box-area .icon-carrot .path10:before {
    content: "\e930";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path11:before,
.left-area .box-area .icon-carrot .path11:before {
    content: "\e931";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path12:before,
.left-area .box-area .icon-carrot .path12:before {
    content: "\e932";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path13:before,
.left-area .box-area .icon-carrot .path13:before {
    content: "\e933";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path14:before,
.left-area .box-area .icon-carrot .path14:before {
    content: "\e934";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-carrot .path15:before,
.left-area .box-area .icon-carrot .path15:before {
    content: "\e935";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path1:before,
.left-area .box-area .icon-cow .path1:before {
    content: "\e936";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path2:before,
.left-area .box-area .icon-cow .path2:before {
    content: "\e937";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path3:before,
.left-area .box-area .icon-cow .path3:before {
    content: "\e938";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path4:before,
.left-area .box-area .icon-cow .path4:before {
    content: "\e939";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path5:before,
.left-area .box-area .icon-cow .path5:before {
    content: "\e93a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path6:before,
.left-area .box-area .icon-cow .path6:before {
    content: "\e93b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path7:before,
.left-area .box-area .icon-cow .path7:before {
    content: "\e93c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path8:before,
.left-area .box-area .icon-cow .path8:before {
    content: "\e93d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path9:before,
.left-area .box-area .icon-cow .path9:before {
    content: "\e93e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path10:before,
.left-area .box-area .icon-cow .path10:before {
    content: "\e93f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-cow .path11:before,
.left-area .box-area .icon-cow .path11:before {
    content: "\e940";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-cow .path12:before,
.left-area .box-area .icon-cow .path12:before {
    content: "\e941";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-cow .path13:before,
.left-area .box-area .icon-cow .path13:before {
    content: "\e942";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-dish .path1:before,
.left-area .box-area .icon-dish .path1:before {
    content: "\e943";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-dish .path2:before,
.left-area .box-area .icon-dish .path2:before {
    content: "\e944";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-dish .path3:before,
.left-area .box-area .icon-dish .path3:before {
    content: "\e945";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-dish .path4:before,
.left-area .box-area .icon-dish .path4:before {
    content: "\e946";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-dish .path5:before,
.left-area .box-area .icon-dish .path5:before {
    content: "\e947";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-dish .path6:before,
.left-area .box-area .icon-dish .path6:before {
    content: "\e948";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-egges .path1:before,
.left-area .box-area .icon-egges .path1:before {
    content: "\e949";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-egges .path2:before,
.left-area .box-area .icon-egges .path2:before {
    content: "\e94a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-egges .path3:before,
.left-area .box-area .icon-egges .path3:before {
    content: "\e94b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-egges .path4:before,
.left-area .box-area .icon-egges .path4:before {
    content: "\e94c";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-egges .path5:before,
.left-area .box-area .icon-egges .path5:before {
    content: "\e94d";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-egges .path6:before,
.left-area .box-area .icon-egges .path6:before {
    content: "\e94e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-egges .path7:before,
.left-area .box-area .icon-egges .path7:before {
    content: "\e94f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-egges .path8:before,
.left-area .box-area .icon-egges .path8:before {
    content: "\e950";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-egges .path9:before,
.left-area .box-area .icon-egges .path9:before {
    content: "\e951";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-female-user .path1:before,
.left-area .box-area .icon-female-user .path1:before {
    content: "\e952";
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-female-user .path2:before,
.left-area .box-area .icon-female-user .path2:before {
    content: "\e953";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-female-user .path3:before,
.left-area .box-area .icon-female-user .path3:before {
    content: "\e954";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-female-user .path4:before,
.left-area .box-area .icon-female-user .path4:before {
    content: "\e955";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-female-user .path5:before,
.left-area .box-area .icon-female-user .path5:before {
    content: "\e956";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-female-user .path6:before,
.left-area .box-area .icon-female-user .path6:before {
    content: "\e957";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-female-user .path7:before,
.left-area .box-area .icon-female-user .path7:before {
    content: "\e958";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-food-dish .path1:before,
.left-area .box-area .icon-food-dish .path1:before {
    content: "\e959";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-food-dish .path2:before,
.left-area .box-area .icon-food-dish .path2:before {
    content: "\e95a";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-food-dish .path3:before,
.left-area .box-area .icon-food-dish .path3:before {
    content: "\e95b";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-food-plate .path1:before,
.left-area .box-area .icon-food-plate .path1:before {
    content: "\e95c";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-food-plate .path2:before,
.left-area .box-area .icon-food-plate .path2:before {
    content: "\e95d";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-food-plate .path3:before,
.left-area .box-area .icon-food-plate .path3:before {
    content: "\e95e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-food-plate .path4:before,
.left-area .box-area .icon-food-plate .path4:before {
    content: "\e95f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-fruits .path1:before,
.left-area .box-area .icon-fruits .path1:before {
    content: "\e960";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-fruits .path2:before,
.left-area .box-area .icon-fruits .path2:before {
    content: "\e961";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-fruits .path3:before,
.left-area .box-area .icon-fruits .path3:before {
    content: "\e962";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-fruits .path4:before,
.left-area .box-area .icon-fruits .path4:before {
    content: "\e963";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-fruits .path5:before,
.left-area .box-area .icon-fruits .path5:before {
    content: "\e964";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-fruits .path6:before,
.left-area .box-area .icon-fruits .path6:before {
    content: "\e965";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-fruits .path7:before,
.left-area .box-area .icon-fruits .path7:before {
    content: "\e966";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-grain .path1:before,
.left-area .box-area .icon-grain .path1:before {
    content: "\e967";
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-grain .path2:before,
.left-area .box-area .icon-grain .path2:before {
    content: "\e968";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-grain .path3:before,
.left-area .box-area .icon-grain .path3:before {
    content: "\e969";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-grain .path4:before,
.left-area .box-area .icon-grain .path4:before {
    content: "\e96a";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-grain .path5:before,
.left-area .box-area .icon-grain .path5:before {
    content: "\e96b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-grain .path6:before,
.left-area .box-area .icon-grain .path6:before {
    content: "\e96c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-grain .path7:before,
.left-area .box-area .icon-grain .path7:before {
    content: "\e96d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-grain .path8:before,
.left-area .box-area .icon-grain .path8:before {
    content: "\e96e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-grain .path9:before,
.left-area .box-area .icon-grain .path9:before {
    content: "\e96f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-heart-beat .path1:before,
.left-area .box-area .icon-heart-beat .path1:before {
    content: "\e970";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-heart-beat .path2:before,
.left-area .box-area .icon-heart-beat .path2:before {
    content: "\e971";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-heart-beat .path3:before,
.left-area .box-area .icon-heart-beat .path3:before {
    content: "\e972";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-heart-beat .path4:before,
.left-area .box-area .icon-heart-beat .path4:before {
    content: "\e973";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-meater .path1:before,
.left-area .box-area .icon-meater .path1:before {
    content: "\e974";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-meater .path2:before,
.left-area .box-area .icon-meater .path2:before {
    content: "\e975";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-meater .path3:before,
.left-area .box-area .icon-meater .path3:before {
    content: "\e976";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-meater .path4:before,
.left-area .box-area .icon-meater .path4:before {
    content: "\e977";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-milk-bottle .path1:before,
.left-area .box-area .icon-milk-bottle .path1:before {
    content: "\e978";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-milk-bottle .path2:before,
.left-area .box-area .icon-milk-bottle .path2:before {
    content: "\e979";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-milk-bottle .path3:before,
.left-area .box-area .icon-milk-bottle .path3:before {
    content: "\e97a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-milk-bottle .path4:before,
.left-area .box-area .icon-milk-bottle .path4:before {
    content: "\e97b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-milk-bottle .path5:before,
.left-area .box-area .icon-milk-bottle .path5:before {
    content: "\e97c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-milk-bottle .path6:before,
.left-area .box-area .icon-milk-bottle .path6:before {
    content: "\e97d";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-milk-bottle .path7:before,
.left-area .box-area .icon-milk-bottle .path7:before {
    content: "\e97e";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-mouse .path1:before,
.left-area .box-area .icon-mouse .path1:before {
    content: "\e97f";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-mouse .path2:before,
.left-area .box-area .icon-mouse .path2:before {
    content: "\e980";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-mouse .path3:before,
.left-area .box-area .icon-mouse .path3:before {
    content: "\e981";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-mouse .path4:before,
.left-area .box-area .icon-mouse .path4:before {
    content: "\e982";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-mouse .path5:before,
.left-area .box-area .icon-mouse .path5:before {
    content: "\e983";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-neutrients .path1:before,
.left-area .box-area .icon-neutrients .path1:before {
    content: "\e984";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-neutrients .path2:before,
.left-area .box-area .icon-neutrients .path2:before {
    content: "\e985";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-neutrients .path3:before,
.left-area .box-area .icon-neutrients .path3:before {
    content: "\e986";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-neutrients .path4:before,
.left-area .box-area .icon-neutrients .path4:before {
    content: "\e987";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-neutrients .path5:before,
.left-area .box-area .icon-neutrients .path5:before {
    content: "\e988";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-neutrients .path6:before,
.left-area .box-area .icon-neutrients .path6:before {
    content: "\e989";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path1:before,
.left-area .box-area .icon-pig .path1:before {
    content: "\e98a";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path2:before,
.left-area .box-area .icon-pig .path2:before {
    content: "\e98b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path3:before,
.left-area .box-area .icon-pig .path3:before {
    content: "\e98c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path4:before,
.left-area .box-area .icon-pig .path4:before {
    content: "\e98d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path5:before,
.left-area .box-area .icon-pig .path5:before {
    content: "\e98e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path6:before,
.left-area .box-area .icon-pig .path6:before {
    content: "\e98f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path7:before,
.left-area .box-area .icon-pig .path7:before {
    content: "\e990";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path8:before,
.left-area .box-area .icon-pig .path8:before {
    content: "\e991";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path9:before,
.left-area .box-area .icon-pig .path9:before {
    content: "\e992";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-pig .path10:before,
.left-area .box-area .icon-pig .path10:before {
    content: "\e993";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-pig .path11:before,
.left-area .box-area .icon-pig .path11:before {
    content: "\e994";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-rice-dish .path1:before,
.left-area .box-area .icon-rice-dish .path1:before {
    content: "\e995";
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-rice-dish .path2:before,
.left-area .box-area .icon-rice-dish .path2:before {
    content: "\e996";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-rice-dish .path3:before,
.left-area .box-area .icon-rice-dish .path3:before {
    content: "\e997";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-rice-dish .path4:before,
.left-area .box-area .icon-rice-dish .path4:before {
    content: "\e998";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-rice-dish .path5:before,
.left-area .box-area .icon-rice-dish .path5:before {
    content: "\e999";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-rice-dish .path6:before,
.left-area .box-area .icon-rice-dish .path6:before {
    content: "\e99a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-rice-dish .path7:before,
.left-area .box-area .icon-rice-dish .path7:before {
    content: "\e99b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-sea-foods .path1:before,
.left-area .box-area .icon-sea-foods .path1:before {
    content: "\e99c";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-sea-foods .path2:before,
.left-area .box-area .icon-sea-foods .path2:before {
    content: "\e99d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-sea-foods .path3:before,
.left-area .box-area .icon-sea-foods .path3:before {
    content: "\e99e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-sea-foods .path4:before,
.left-area .box-area .icon-sea-foods .path4:before {
    content: "\e99f";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-sea-foods .path5:before,
.left-area .box-area .icon-sea-foods .path5:before {
    content: "\e9a0";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-sea-foods .path6:before,
.left-area .box-area .icon-sea-foods .path6:before {
    content: "\e9a1";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-sea-foods .path7:before,
.left-area .box-area .icon-sea-foods .path7:before {
    content: "\e9a2";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-sea-foods .path8:before,
.left-area .box-area .icon-sea-foods .path8:before {
    content: "\e9a3";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-soup .path1:before,
.left-area .box-area .icon-soup .path1:before {
    content: "\e9a4";
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-soup .path2:before,
.left-area .box-area .icon-soup .path2:before {
    content: "\e9a5";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-soup .path3:before,
.left-area .box-area .icon-soup .path3:before {
    content: "\e9a6";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-soup .path4:before,
.left-area .box-area .icon-soup .path4:before {
    content: "\e9a7";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-soup .path5:before,
.left-area .box-area .icon-soup .path5:before {
    content: "\e9a8";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-soup .path6:before,
.left-area .box-area .icon-soup .path6:before {
    content: "\e9a9";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-soup .path7:before,
.left-area .box-area .icon-soup .path7:before {
    content: "\e9aa";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-user .path1:before,
.left-area .box-area .icon-user .path1:before {
    content: "\e9ab";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-user .path2:before,
.left-area .box-area .icon-user .path2:before {
    content: "\e9ac";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-user .path3:before,
.left-area .box-area .icon-user .path3:before {
    content: "\e9ad";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-user .path4:before,
.left-area .box-area .icon-user .path4:before {
    content: "\e9ae";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-user .path5:before,
.left-area .box-area .icon-user .path5:before {
    content: "\e9af";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-user .path6:before,
.left-area .box-area .icon-user .path6:before {
    content: "\e9b0";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-user .path7:before,
.left-area .box-area .icon-user .path7:before {
    content: "\e9b1";
    margin-left: -1em;
    color: #e30613;
}

/*
    Added by Mari on 11/06/2021 for years slider
  */
.menu-area .hide-on-desktop .icon-user .path8:before,
.left-area .box-area .icon-user .path8:before {
    content: "\e952";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-user .path9:before,
.left-area .box-area .icon-user .path9:before {
    content: "\e9b8";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-user .path10:before,
.left-area .box-area .icon-user .path10:before {
    content: "\e9b9";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-women-face .path1:before,
.left-area .box-area .icon-women-face .path1:before {
    content: "\e9b2";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-women-face .path2:before,
.left-area .box-area .icon-women-face .path2:before {
    content: "\e9b3";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-women-face .path3:before,
.left-area .box-area .icon-women-face .path3:before {
    content: "\e9b4";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-women-face .path4:before,
.left-area .box-area .icon-women-face .path4:before {
    content: "\e9b5";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-women-face .path5:before,
.left-area .box-area .icon-women-face .path5:before {
    content: "\e9b6";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-women-face .path6:before,
.left-area .box-area .icon-women-face .path6:before {
    content: "\e9b7";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-women-face .path7:before,
.left-area .box-area .icon-women-face .path7:before {
    content: "\e9b8";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-women-face .path8:before,
.left-area .box-area .icon-women-face .path8:before {
    content: "\e9b9";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-menu-bar .path1:before,
.left-area .box-area .icon-menu-bar .path1:before {
    content: "\e9ba";
    color: white;
}

.menu-area .hide-on-desktop .icon-menu-bar .path2:before,
.left-area .box-area .icon-menu-bar .path2:before {
    content: "\e9bb";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-menu-bar .path3:before,
.left-area .box-area .icon-menu-bar .path3:before {
    content: "\e9bc";
    margin-left: -1em;
    color: #e30613;
}

.menu-area .hide-on-desktop .icon-menu-bar .path4:before,
.left-area .box-area .icon-menu-bar .path4:before {
    content: "\e9bd";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-menu-bar .path5:before,
.left-area .box-area .icon-menu-bar .path5:before {
    content: "\e9be";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-email:before,
.left-area .box-area .icon-email:before {
    content: "\e9bf";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-edit:before,
.left-area .box-area .icon-edit:before {
    content: "\e9c0";
    color: #c4c4c4;
}

.menu-area .hide-on-desktop .icon-edit2:before,
.left-area .box-area .icon-edit2:before {
    content: "\e9c1";
    color: #c4c4c4;
}

.menu-area .hide-on-desktop .icon-blankstar:before,
.left-area .box-area .icon-blankstar:before {
    content: "\e9c2";
    color: #263a7b;
}

.menu-area .hide-on-desktop .icon-solidstar:before,
.left-area .box-area .icon-solidstar:before {
    content: "\e9c3";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-close:before,
.left-area .box-area .icon-close:before {
    content: "\e9c4";
    color: #fff;
}

.menu-area .hide-on-desktop .icon-close-circle:before,
.left-area .box-area .icon-close-circle:before {
    content: "\e9c5";
    color: #fff;
}

.menu-area .hide-on-desktop .icon-download-arrow:before,
.left-area .box-area .icon-download-arrow:before {
    content: "\e9c6";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-top-arrow:before,
.left-area .box-area .icon-top-arrow:before {
    content: "\e9c7";
}

.menu-area .hide-on-desktop .icon-left-arrow:before,
.left-area .box-area .icon-left-arrow:before {
    content: "\e9c9";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-right-arrow:before,
.left-area .box-area .icon-right-arrow:before {
    content: "\e9ca";
    color: var(--left-area-box-area-icon-diets-color-4);
}

.menu-area .hide-on-desktop .icon-left-arrow-solid .path1:before,
.left-area .box-area .icon-left-arrow-solid .path1:before {
    content: "\e9cb";
    color: #65676a;
}

.menu-area .hide-on-desktop .icon-left-arrow-solid .path2:before,
.left-area .box-area .icon-left-arrow-solid .path2:before {
    content: "\e9cc";
    margin-left: -1em;
    color: white;
}

.menu-area .hide-on-desktop .icon-right-arrow-solid .path1:before,
.left-area .box-area .icon-right-arrow-solid .path1:before {
    content: "\e9cd";
    color: #65676a;
}

.menu-area .hide-on-desktop .icon-right-arrow-solid .path2:before,
.left-area .box-area .icon-right-arrow-solid .path2:before {
    content: "\e9ce";
    margin-left: -1em;
    color: white;
}

.menu-area .hide-on-desktop .icon-minus:before,
.left-area .box-area .icon-minus:before {
    content: "\e9cf";
}

.menu-area .hide-on-desktop .icon-plus:before,
.left-area .box-area .icon-plus:before {
    content: "\e9d0";
}

.menu-area .hide-on-desktop .icon-search .path1:before,
.left-area .box-area .icon-search .path1:before {
    content: "\e9d1";
    color: #ff0000;
}

.menu-area .hide-on-desktop .icon-search .path2:before,
.left-area .box-area .icon-search .path2:before {
    content: "\e9d2";
    margin-left: -1em;
    color: white;
}

.menu-area .hide-on-desktop .icon-search2:before,
.left-area .box-area .icon-search2:before {
    content: "\e9d3";
    color: #0f123a;
}

/*
.menu-area .hide-on-desktop .icon-small-arrow-left:before,
.left-area .box-area .icon-small-arrow-left:before {
    content: "\e9d4";
    color: var(--white);
}

.menu-area .hide-on-desktop .icon-small-arrow-right:before,
.left-area .box-area .icon-small-arrow-right:before {
    content: "\e9d5";
    color: var(--white);
}
*/



/**BREAD*/
.menu-area .hide-on-desktop .icon2-BREAD .path1:before,
.left-area .box-area .icon2-BREAD .path1:before {
    content: "\e900";
    color: var(--left-area-box-area-icon-diets-color-1);
}

.menu-area .hide-on-desktop .icon2-BREAD .path2:before,
.left-area .box-area .icon2-BREAD .path2:before {
    content: "\e901";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.menu-area .hide-on-desktop .icon2-BREAD .path3:before,
.left-area .box-area .icon2-BREAD .path3:before {
    content: "\e902";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.menu-area .hide-on-desktop .icon2-BREAD .path4:before,
.left-area .box-area .icon2-BREAD .path4:before {
    content: "\e903";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

/**CARNE*/
.menu-area .hide-on-desktop .icon2-CARNE .path1:before,
.left-area .box-area .icon2-CARNE .path1:before {
    content: "\e904";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CARNE .path2:before,
.left-area .box-area .icon2-CARNE .path2:before {
    content: "\e905";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CARNE .path3:before,
.left-area .box-area .icon2-CARNE .path3:before {
    content: "\e906";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CARNE .path4:before,
.left-area .box-area .icon2-CARNE .path4:before {
    content: "\e907";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-CARNE .path5:before,
.left-area .box-area .icon2-CARNE .path5:before {
    content: "\e908";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

/**CERDO*/
.menu-area .hide-on-desktop .icon2-CERDO .path1:before,
.left-area .box-area .icon2-CERDO .path1:before {
    content: "\e909";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path2:before,
.left-area .box-area .icon2-CERDO .path2:before {
    content: "\e90a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path3:before,
.left-area .box-area .icon2-CERDO .path3:before {
    content: "\e90b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path4:before,
.left-area .box-area .icon2-CERDO .path4:before {
    content: "\e90c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path5:before,
.left-area .box-area .icon2-CERDO .path5:before {
    content: "\e90d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path6:before,
.left-area .box-area .icon2-CERDO .path6:before {
    content: "\e90e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path7:before,
.left-area .box-area .icon2-CERDO .path7:before {
    content: "\e90f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path8:before,
.left-area .box-area .icon2-CERDO .path8:before {
    content: "\e910";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path9:before,
.left-area .box-area .icon2-CERDO .path9:before {
    content: "\e911";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-CERDO .path10:before,
.left-area .box-area .icon2-CERDO .path10:before {
    content: "\e912";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-CERDO .path11:before,
.left-area .box-area .icon2-CERDO .path11:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

/**CUP*/
.menu-area .hide-on-desktop .icon2-CUP .path1:before,
.left-area .box-area .icon2-CUP .path1:before {
    content: "\e914";
    color: var(--left-area-box-area-icon-diets-color-1);
}

.menu-area .hide-on-desktop .icon2-CUP .path2:before,
.left-area .box-area .icon2-CUP .path2:before {
    content: "\e915";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-1);
}

.menu-area .hide-on-desktop .icon2-CUP .path3:before,
.left-area .box-area .icon2-CUP .path3:before {
    content: "\e916";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-1);
}

.menu-area .hide-on-desktop .icon2-CUP .path4:before,
.left-area .box-area .icon2-CUP .path4:before {
    content: "\e917";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-1);
}

.menu-area .hide-on-desktop .icon2-CUP .path5:before,
.left-area .box-area .icon2-CUP .path5:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.menu-area .hide-on-desktop .icon2-CUP .path6:before,
.left-area .box-area .icon2-CUP .path6:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.menu-area .hide-on-desktop .icon2-CUP .path7:before,
.left-area .box-area .icon2-CUP .path7:before {
    content: "\e91a";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

/**DESAYUNO MESA (INFANTIL TRITURADO 4m - 1)*/
.menu-area .hide-on-desktop .icon2-TRITURADA .path1:before,
.left-area .box-area .icon2-TRITURADA .path1:before {
    content: "\e91b";
    color: var(--left-area-box-area-icon-diets-color-3);
}

.menu-area .hide-on-desktop .icon2-TRITURADA .path2:before,
.left-area .box-area .icon2-TRITURADA .path2:before {
    content: "\e91c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-3);
}

.menu-area .hide-on-desktop .icon2-TRITURADA .path3:before,
.left-area .box-area .icon2-TRITURADA .path3:before {
    content: "\e91d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-3);
}

.menu-area .hide-on-desktop .icon2-TRITURADA .path4:before,
.left-area .box-area .icon2-TRITURADA .path4:before {
    content: "\e91e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-3);
}

.menu-area .hide-on-desktop .icon2-TRITURADA .path5:before,
.left-area .box-area .icon2-TRITURADA .path5:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(237, 28, 36);
}

.menu-area .hide-on-desktop .icon2-TRITURADA .path6:before,
.left-area .box-area .icon2-TRITURADA .path6:before {
    content: "\e920";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-3);
}

.menu-area .hide-on-desktop .icon2-TRITURADA .path7:before,
.left-area .box-area .icon2-TRITURADA .path7:before {
    content: "\e921";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-3);
}

.menu-area .hide-on-desktop .icon2-TRITURADA .path8:before,
.left-area .box-area .icon2-TRITURADA .path8:before {
    content: "\e922";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-3);
}

/**FLECHA*/
.menu-area .hide-on-desktop .icon2-FLECHA:before,
.left-area .box-area .icon2-FLECHA:before {
    content: "\e923";
    color: #32285b;
}

/**FRUCTOSA*/
.menu-area .hide-on-desktop .icon2-FRUCTOSA .path1:before,
.left-area .box-area .icon2-FRUCTOSA .path1:before {
    content: "\e924";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path2:before,
.left-area .box-area .icon2-FRUCTOSA .path2:before {
    content: "\e925";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path3:before,
.left-area .box-area .icon2-FRUCTOSA .path3:before {
    content: "\e926";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path4:before,
.left-area .box-area .icon2-FRUCTOSA .path4:before {
    content: "\e927";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path5:before,
.left-area .box-area .icon2-FRUCTOSA .path5:before {
    content: "\e928";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path6:before,
.left-area .box-area .icon2-FRUCTOSA .path6:before {
    content: "\e929";
    margin-left: -1em;
    color: rgb(50, 50, 50);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path7:before,
.left-area .box-area .icon2-FRUCTOSA .path7:before {
    content: "\e92a";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path8:before,
.left-area .box-area .icon2-FRUCTOSA .path8:before {
    content: "\e92b";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path9:before,
.left-area .box-area .icon2-FRUCTOSA .path9:before {
    content: "\e92c";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path10:before,
.left-area .box-area .icon2-FRUCTOSA .path10:before {
    content: "\e92d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path11:before,
.left-area .box-area .icon2-FRUCTOSA .path11:before {
    content: "\e92e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path12:before,
.left-area .box-area .icon2-FRUCTOSA .path12:before {
    content: "\e92f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path13:before,
.left-area .box-area .icon2-FRUCTOSA .path13:before {
    content: "\e930";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path14:before,
.left-area .box-area .icon2-FRUCTOSA .path14:before {
    content: "\e931";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUCTOSA .path15:before,
.left-area .box-area .icon2-FRUCTOSA .path15:before {
    content: "\e932";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

/**FRUTOS-SECOS*/
.menu-area .hide-on-desktop .icon2-FRUTOS-SECOS .path1:before,
.left-area .box-area .icon2-FRUTOS-SECOS .path1:before {
    content: "\e933";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUTOS-SECOS .path2:before,
.left-area .box-area .icon2-FRUTOS-SECOS .path2:before {
    content: "\e934";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUTOS-SECOS .path3:before,
.left-area .box-area .icon2-FRUTOS-SECOS .path3:before {
    content: "\e935";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUTOS-SECOS .path4:before,
.left-area .box-area .icon2-FRUTOS-SECOS .path4:before {
    content: "\e936";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-FRUTOS-SECOS .path5:before,
.left-area .box-area .icon2-FRUTOS-SECOS .path5:before {
    content: "\e937";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-FRUTOS-SECOS .path6:before,
.left-area .box-area .icon2-FRUTOS-SECOS .path6:before {
    content: "\e938";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-FRUTOS-SECOS .path7:before,
.left-area .box-area .icon2-FRUTOS-SECOS .path7:before {
    content: "\e939";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}


/**GUARNICION*/
.menu-area .hide-on-desktop .icon2-GUARNICION .path1:before,
.left-area .box-area .icon2-GUARNICION .path1:before {
    content: "\e93a";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-GUARNICION .path2:before,
.left-area .box-area .icon2-GUARNICION .path2:before {
    content: "\e93b";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-GUARNICION .path3:before,
.left-area .box-area .icon2-GUARNICION .path3:before {
    content: "\e93c";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

/**HIPOCALORICO*/
.menu-area .hide-on-desktop .icon2-HIPOCALORICO .path1:before,
.left-area .box-area .icon2-HIPOCALORICO .path1:before {
    content: "\e93d";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-HIPOCALORICO .path2:before,
.left-area .box-area .icon2-HIPOCALORICO .path2:before {
    content: "\e93e";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-HIPOCALORICO .path3:before,
.left-area .box-area .icon2-HIPOCALORICO .path3:before {
    content: "\e93f";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-HIPOCALORICO .path4:before,
.left-area .box-area .icon2-HIPOCALORICO .path4:before {
    content: "\e940";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

/**INFANTIL-PURE*/
.menu-area .hide-on-desktop .icon2-INFANTIL-PURE .path1:before,
.left-area .box-area .icon2-INFANTIL-PURE .path1:before {
    content: "\e941";
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-PURE .path2:before,
.left-area .box-area .icon2-INFANTIL-PURE .path2:before {
    content: "\e942";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-PURE .path3:before,
.left-area .box-area .icon2-INFANTIL-PURE .path3:before {
    content: "\e943";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-PURE .path4:before,
.left-area .box-area .icon2-INFANTIL-PURE .path4:before {
    content: "\e944";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-PURE .path5:before,
.left-area .box-area .icon2-INFANTIL-PURE .path5:before {
    content: "\e945";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-PURE .path6:before,
.left-area .box-area .icon2-INFANTIL-PURE .path6:before {
    content: "\e946";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-PURE .path7:before,
.left-area .box-area .icon2-INFANTIL-PURE .path7:before {
    content: "\e947";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

/**INFANTIL-SOLIDO*/
.menu-area .hide-on-desktop .icon2-INFANTIL-SOLIDO .path1:before,
.left-area .box-area .icon2-INFANTIL-SOLIDO .path1:before {
    content: "\e948";
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-SOLIDO .path2:before,
.left-area .box-area .icon2-INFANTIL-SOLIDO .path2:before {
    content: "\e949";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-SOLIDO .path3:before,
.left-area .box-area .icon2-INFANTIL-SOLIDO .path3:before {
    content: "\e94a";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-SOLIDO .path4:before,
.left-area .box-area .icon2-INFANTIL-SOLIDO .path4:before {
    content: "\e94b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-SOLIDO .path5:before,
.left-area .box-area .icon2-INFANTIL-SOLIDO .path5:before {
    content: "\e94c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-SOLIDO .path6:before,
.left-area .box-area .icon2-INFANTIL-SOLIDO .path6:before {
    content: "\e94d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-INFANTIL-SOLIDO .path7:before,
.left-area .box-area .icon2-INFANTIL-SOLIDO .path7:before {
    content: "\e94e";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

/**MULTIALERGENO*/
.menu-area .hide-on-desktop .icon2-MULTIALERGENO .path1:before,
.left-area .box-area .icon2-MULTIALERGENO .path1:before {
    content: "\e94f";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-MULTIALERGENO .path2:before,
.left-area .box-area .icon2-MULTIALERGENO .path2:before {
    content: "\e950";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-MULTIALERGENO .path3:before,
.left-area .box-area .icon2-MULTIALERGENO .path3:before {
    content: "\e951";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-MULTIALERGENO .path4:before,
.left-area .box-area .icon2-MULTIALERGENO .path4:before {
    content: "\e952";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-MULTIALERGENO .path5:before,
.left-area .box-area .icon2-MULTIALERGENO .path5:before {
    content: "\e953";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-MULTIALERGENO .path6:before,
.left-area .box-area .icon2-MULTIALERGENO .path6:before {
    content: "\e954";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

/**SEGUNDO-PLATO*/
.menu-area .hide-on-desktop .icon2-SEGUNDO-PLATO .path1:before,
.left-area .box-area .icon2-SEGUNDO-PLATO .path1:before {
    content: "\e955";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-SEGUNDO-PLATO .path2:before,
.left-area .box-area .icon2-SEGUNDO-PLATO .path2:before {
    content: "\e956";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-SEGUNDO-PLATO .path3:before,
.left-area .box-area .icon2-SEGUNDO-PLATO .path3:before {
    content: "\e957";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-SEGUNDO-PLATO .path4:before,
.left-area .box-area .icon2-SEGUNDO-PLATO .path4:before {
    content: "\e958";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

/**TRIANGLE*/
.menu-area .hide-on-desktop .icon2-TRIANGLE .path1:before,
.left-area .box-area .icon2-TRIANGLE .path1:before {
    content: "\e959";
    color: rgb(236, 28, 36);
}

.menu-area .hide-on-desktop .icon2-TRIANGLE .path2:before,
.left-area .box-area .icon2-TRIANGLE .path2:before {
    content: "\e95a";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.menu-area .hide-on-desktop .icon2-TRIANGLE .path3:before,
.left-area .box-area .icon2-TRIANGLE .path3:before {
    content: "\e95b";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-1);
}

.menu-area .hide-on-desktop .icon2-TRIANGLE .path4:before,
.left-area .box-area .icon2-TRIANGLE .path4:before {
    content: "\e95c";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-1);
}

.menu-area .hide-on-desktop .icon2-TRIANGLE .path5:before,
.left-area .box-area .icon2-TRIANGLE .path5:before {
    content: "\e95d";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-1);
}

/**VEGETARIANO*/
.menu-area .hide-on-desktop .icon2-VEGETARIANO .path1:before,
.left-area .box-area .icon2-VEGETARIANO .path1:before {
    content: "\e95e";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path2:before,
.left-area .box-area .icon2-VEGETARIANO .path2:before {
    content: "\e95f";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path3:before,
.left-area .box-area .icon2-VEGETARIANO .path3:before {
    content: "\e960";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path4:before,
.left-area .box-area .icon2-VEGETARIANO .path4:before {
    content: "\e961";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path5:before,
.left-area .box-area .icon2-VEGETARIANO .path5:before {
    content: "\e962";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path6:before,
.left-area .box-area .icon2-VEGETARIANO .path6:before {
    content: "\e963";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path7:before,
.left-area .box-area .icon2-VEGETARIANO .path7:before {
    content: "\e964";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path8:before,
.left-area .box-area .icon2-VEGETARIANO .path8:before {
    content: "\e965";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon2-VEGETARIANO .path9:before,
.left-area .box-area .icon2-VEGETARIANO .path9:before {
    content: "\e966";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon3-BAJO-COLESTEROL .path1:before,
.left-area .box-area .icon3-BAJO-COLESTEROL .path1:before {
    content: "\e900";
    color: var(--left-area-box-area-icon-diets-color-2);
}

.menu-area .hide-on-desktop .icon3-BAJO-COLESTEROL .path2:before,
.left-area .box-area .icon3-BAJO-COLESTEROL .path2:before {
    content: "\e901";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon3-BAJO-COLESTEROL .path3:before,
.left-area .box-area .icon3-BAJO-COLESTEROL .path3:before {
    content: "\e902";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.menu-area .hide-on-desktop .icon3-BAJO-COLESTEROL .path4:before,
.left-area .box-area .icon3-BAJO-COLESTEROL .path4:before {
    content: "\e903";
    margin-left: -1em;
    color: var(--left-area-box-area-icon-diets-color-2);
}