.advices-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 100%;
    padding: 0 8px 0 8px;
}

.menu-asterisk-description-container {
    margin-bottom: 16px;
    display: block;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
}

.menu-asterisk-description-container span {
    border-bottom: 1px solid blue;
}

.busta-svg-image { 
    height: 50px;
    width: 50px;
}

.suggestions__img-container {
    margin: auto 0 16px 0;
    display: block;
}

.suggestions__img-container.disabled {
    display: none;
}

.right-area-children {
    display: none;
}

@media screen and (min-width: 768px) {
    .suggestions__img-container {
        display: none;
    }
}

.children .right-area-children__title { 
    font-family: "Soopafre";

    background-color: #262C61;

    text-align: center;
    font-size: 24px;
    line-height: 16px;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--activevades-default-heading-h2-color);
    display: block;
    text-transform: uppercase;

    background-color: transparent;
}

.news-direct-link { 
    text-align: center;
    font-size: 24px;
    line-height: 16px;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--activevades-default-heading-h2-color);
    display: block;
    text-transform: uppercase;
    font-family: "Sansa Pro";

    background-color: transparent;
}

.games-link-title { 
    text-align: center;
    font-size: 24px;
    line-height: 16px;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--games-default-heading-h2-color);
    display: block;
    text-transform: uppercase;
    font-family: "Sansa Pro";

    background-color: transparent;
}

.children .right-area-children__title.games-link-title { 
    color: var(--games-default-heading-h2-color);
}

.activities-direct-link { 
    text-align: center;
    font-size: 24px;
    line-height: 16px;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--activities-default-heading-h2-color);
    display: block;
    text-transform: uppercase;
    font-family: "Sansa Pro";

    background-color: transparent;
}

.children .right-area-children__title.activities-direct-link { 
    color: var(--activities-default-heading-h2-color);
}

.see-all-text { 
    line-height: 0;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    font-family: "Sansa Pro";

    color: #263A7B;
}

/*
.news-direct-link:hover .see-all-text { 
    display: block;
}
*/

.see-all-children { 
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    font-family: "Sansa Pro";
    color: #263A7B;
}

/*
    Media button
*/
.advice-dinner-media-container {
    align-self: center;
    justify-self: center;
    vertical-align: middle;

    margin: 16px 0px;

    text-align: center;

    width: 100%;
    max-width: 180px;
    min-height: auto;
    max-height: 50px;
    
    border-radius: 8px;
    
    background-color: #8F5DC7;
}

.advice-dinner-media-container .text {
    margin: 8px auto;
    font-family: "Sansa Pro";
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    
    color: #FFFFFF;
}

/*For advice news with p element*/
.advice-text p {
    margin-bottom: 0px;
}

.children .right-area-children .no-data-container {
    height: auto!important;
}

/**
 * SOHAPPY Download area on mobile
 */
#sohappy .menu-area .hide-on-desktop.download-area .box-area {
    max-width: 100%;
}
