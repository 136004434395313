.messages-container {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.messages__list {
    margin-top: 22px;
    text-align: center;
}