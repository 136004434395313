.download-media-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding: 12px;

    min-height: 75px;
    max-width: 300px;
    border-radius: 8px;

    cursor: pointer;
}

.download-media-button-container:not(:last-child) {
    margin-bottom: 10px;
}

.download-media-button-container .description {
    color: #fff;
    text-transform: uppercase;
    font-family: "Sansa Pro";
    font-weight: bold;
    font-size: 18px;
}

#single-activity .download-media-button-container {
    background-color: #FFB81A;
}
