@font-face {
    font-family: 'someal2';
    src: url('../fonts/someal2.eot?fwtv9p');
    src: url('../fonts/someal2.eot?fwtv9p#iefix') format('embedded-opentype'),
        url('../fonts/someal2.ttf?fwtv9p') format('truetype'),
        url('../fonts/someal2.woff?fwtv9p') format('woff'),
        url('../fonts/someal2.svg?fwtv9p#someal2') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon2-"],
[class*=" icon2-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'someal2' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
}

/**BREAD*/
.icon2-BREAD .path1:before {
    content: "\e900";
    color: var(--icon-diets-color-1);
}

.icon2-BREAD .path2:before {
    content: "\e901";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.icon2-BREAD .path3:before {
    content: "\e902";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.icon2-BREAD .path4:before {
    content: "\e903";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

/**CARNE*/
.icon2-CARNE .path1:before {
    content: "\e904";
    color: var(--icon-diets-color-2);
}

.icon2-CARNE .path2:before {
    content: "\e905";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CARNE .path3:before {
    content: "\e906";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CARNE .path4:before {
    content: "\e907";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-CARNE .path5:before {
    content: "\e908";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

/**CERDO*/
.icon2-CERDO .path1:before {
    content: "\e909";
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path2:before {
    content: "\e90a";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path3:before {
    content: "\e90b";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path4:before {
    content: "\e90c";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path5:before {
    content: "\e90d";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path6:before {
    content: "\e90e";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path7:before {
    content: "\e90f";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path8:before {
    content: "\e910";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path9:before {
    content: "\e911";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-CERDO .path10:before {
    content: "\e912";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-CERDO .path11:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

/**CUP*/
.icon2-CUP .path1:before {
    content: "\e914";
    color: var(--icon-diets-color-1);
}

.icon2-CUP .path2:before {
    content: "\e915";
    margin-left: -1em;
    color: var(--icon-diets-color-1);
}

.icon2-CUP .path3:before {
    content: "\e916";
    margin-left: -1em;
    color: var(--icon-diets-color-1);
}

.icon2-CUP .path4:before {
    content: "\e917";
    margin-left: -1em;
    color: var(--icon-diets-color-1);
}

.icon2-CUP .path5:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.icon2-CUP .path6:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.icon2-CUP .path7:before {
    content: "\e91a";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

/**DESAYUNO MESA (INFANTIL TRITURADO 4m - 1)*/
.icon2-TRITURADA .path1:before {
    content: "\e91b";
    color: var(--icon-diets-color-3);
}

.icon2-TRITURADA .path2:before {
    content: "\e91c";
    margin-left: -1em;
    color: var(--icon-diets-color-3);
}

.icon2-TRITURADA .path3:before {
    content: "\e91d";
    margin-left: -1em;
    color: var(--icon-diets-color-3);
}

.icon2-TRITURADA .path4:before {
    content: "\e91e";
    margin-left: -1em;
    color: var(--icon-diets-color-3);
}

.icon2-TRITURADA .path5:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(237, 28, 36);
}

.icon2-TRITURADA .path6:before {
    content: "\e920";
    margin-left: -1em;
    color: var(--icon-diets-color-3);
}

.icon2-TRITURADA .path7:before {
    content: "\e921";
    margin-left: -1em;
    color: var(--icon-diets-color-3);
}

.icon2-TRITURADA .path8:before {
    content: "\e922";
    margin-left: -1em;
    color: var(--icon-diets-color-3);
}

/**FLECHA*/
.icon2-FLECHA:before {
    content: "\e923";
    color: #32285b;
}

/**FRUCTOSA*/
.icon2-FRUCTOSA .path1:before {
    content: "\e924";
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path2:before {
    content: "\e925";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path3:before {
    content: "\e926";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path4:before {
    content: "\e927";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path5:before {
    content: "\e928";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path6:before {
    content: "\e929";
    margin-left: -1em;
    color: rgb(50, 50, 50);
}

.icon2-FRUCTOSA .path7:before {
    content: "\e92a";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path8:before {
    content: "\e92b";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-FRUCTOSA .path9:before {
    content: "\e92c";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-FRUCTOSA .path10:before {
    content: "\e92d";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path11:before {
    content: "\e92e";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path12:before {
    content: "\e92f";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path13:before {
    content: "\e930";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path14:before {
    content: "\e931";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUCTOSA .path15:before {
    content: "\e932";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

/**FRUTOS-SECOS*/
.icon2-FRUTOS-SECOS .path1:before {
    content: "\e933";
    color: var(--icon-diets-color-2);
}

.icon2-FRUTOS-SECOS .path2:before {
    content: "\e934";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUTOS-SECOS .path3:before {
    content: "\e935";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUTOS-SECOS .path4:before {
    content: "\e936";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-FRUTOS-SECOS .path5:before {
    content: "\e937";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-FRUTOS-SECOS .path6:before {
    content: "\e938";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-FRUTOS-SECOS .path7:before {
    content: "\e939";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}


/**GUARNICION*/
.icon2-GUARNICION .path1:before {
    content: "\e93a";
    color: var(--icon-diets-color-2);
}

.icon2-GUARNICION .path2:before {
    content: "\e93b";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-GUARNICION .path3:before {
    content: "\e93c";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

/**HIPOCALORICO*/
.icon2-HIPOCALORICO .path1:before {
    content: "\e93d";
    color: var(--icon-diets-color-2);
}

.icon2-HIPOCALORICO .path2:before {
    content: "\e93e";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-HIPOCALORICO .path3:before {
    content: "\e93f";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-HIPOCALORICO .path4:before {
    content: "\e940";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

/**INFANTIL-PURE*/
.icon2-INFANTIL-PURE .path1:before {
    content: "\e941";
    color: rgb(227, 6, 19);
}

.icon2-INFANTIL-PURE .path2:before {
    content: "\e942";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-PURE .path3:before {
    content: "\e943";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-PURE .path4:before {
    content: "\e944";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-PURE .path5:before {
    content: "\e945";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-PURE .path6:before {
    content: "\e946";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-INFANTIL-PURE .path7:before {
    content: "\e947";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

/**INFANTIL-SOLIDO*/
.icon2-INFANTIL-SOLIDO .path1:before {
    content: "\e948";
    color: rgb(227, 6, 19);
}

.icon2-INFANTIL-SOLIDO .path2:before {
    content: "\e949";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-SOLIDO .path3:before {
    content: "\e94a";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-INFANTIL-SOLIDO .path4:before {
    content: "\e94b";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-SOLIDO .path5:before {
    content: "\e94c";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-SOLIDO .path6:before {
    content: "\e94d";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-INFANTIL-SOLIDO .path7:before {
    content: "\e94e";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

/**MULTIALERGENO*/
.icon2-MULTIALERGENO .path1:before {
    content: "\e94f";
    color: var(--icon-diets-color-2);
}

.icon2-MULTIALERGENO .path2:before {
    content: "\e950";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-MULTIALERGENO .path3:before {
    content: "\e951";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-MULTIALERGENO .path4:before {
    content: "\e952";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-MULTIALERGENO .path5:before {
    content: "\e953";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-MULTIALERGENO .path6:before {
    content: "\e954";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

/**SEGUNDO-PLATO*/
.icon2-SEGUNDO-PLATO .path1:before {
    content: "\e955";
    color: var(--icon-diets-color-2);
}

.icon2-SEGUNDO-PLATO .path2:before {
    content: "\e956";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-SEGUNDO-PLATO .path3:before {
    content: "\e957";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-SEGUNDO-PLATO .path4:before {
    content: "\e958";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

/**TRIANGLE*/
.icon2-TRIANGLE .path1:before {
    content: "\e959";
    color: rgb(236, 28, 36);
}

.icon2-TRIANGLE .path2:before {
    content: "\e95a";
    margin-left: -1em;
    color: rgb(236, 28, 36);
}

.icon2-TRIANGLE .path3:before {
    content: "\e95b";
    margin-left: -1em;
    color: var(--icon-diets-color-1);
}

.icon2-TRIANGLE .path4:before {
    content: "\e95c";
    margin-left: -1em;
    color: var(--icon-diets-color-1);
}

.icon2-TRIANGLE .path5:before {
    content: "\e95d";
    margin-left: -1em;
    color: var(--icon-diets-color-1);
}

/**VEGETARIANO*/
.icon2-VEGETARIANO .path1:before {
    content: "\e95e";
    color: var(--icon-diets-color-2);
}

.icon2-VEGETARIANO .path2:before {
    content: "\e95f";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-VEGETARIANO .path3:before {
    content: "\e960";
    margin-left: -1em;
    color: rgb(227, 6, 19);
}

.icon2-VEGETARIANO .path4:before {
    content: "\e961";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-VEGETARIANO .path5:before {
    content: "\e962";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-VEGETARIANO .path6:before {
    content: "\e963";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-VEGETARIANO .path7:before {
    content: "\e964";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-VEGETARIANO .path8:before {
    content: "\e965";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}

.icon2-VEGETARIANO .path9:before {
    content: "\e966";
    margin-left: -1em;
    color: var(--icon-diets-color-2);
}



@font-face {
    font-family: 'someal3';
    src: url('../fonts/someal3.eot?fwtv9p');
    src: url('../fonts/someal3.eot?fwtv9p#iefix') format('embedded-opentype'),
        url('../fonts/someal3.ttf?fwtv9p') format('truetype'),
        url('../fonts/someal3.woff?fwtv9p') format('woff'),
        url('../fonts/someal3.svg?fwtv9p#someal3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon3-"],
[class*=" icon3-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'someal3' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
}

.icon3-BAJO-COLESTEROL .path1:before {
    content: "\e900";
    color: var(--icon-diets-color-2);
}
.icon3-BAJO-COLESTEROL .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon3-BAJO-COLESTEROL .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(227, 6, 19);
}
.icon3-BAJO-COLESTEROL .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: var(--icon-diets-color-2);
}