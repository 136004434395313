.spacer {
    margin: 8px 0;
    height: 9px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAJBAMAAAAWSsseAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAMUExURf///09flCY6e2l3pB6/ZCAAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTY8AHGBXBFLMyDnkGBgAIgQBJRrqtjwAAAABJRU5ErkJggg==);
    border: none;

    /*
    height: 1px;
    background-image: var(--spacer-background-color);
    background-position: top;
    background-size: 12px 1px;
    background-repeat: repeat-x;
    border-color: var(--spacer-border-color);
    border-width: 2px;
    border-style: dotted;
    border: none;
    border-top: 2px;
    */
    
}