#recipe-detail-container .default-heading h3 { 
    text-transform: none;
}

#recipe-detail-container .column-details .recipe__icon { 
    font-size: 100px;
}

.slide-area .icon-top-arrow.no-rotate {
    transform: rotate(0deg) ;
}

#recipe-detail-container .information-area::after { 
    display: none;
}

/**Children */
.children #recipe-detail-container .go-back-link-container { 
    display: none;
}

#recipe-detail-container .rate-recipe-area-container {
    text-align: center;
}
