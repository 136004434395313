.no-data-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box-area.menu-general.no-data-container {
    background-color: var(--right-area-box-area-info-box-background-color);
    color: var(--right-area-box-area-info-box-color);
}

#menu-general-id .owl-nav { 
    color: var(--menu-general-id-owl-nav-color);
}

@media screen and (max-width: 767px) {
    #menu-general-id .icon{
        margin: 4px 0 0 0;
        padding: 0;
        font-size: 60px;
    }

    #menu-general-id .default-heading {
        display: block;
        margin: 8px 0;
        padding: 0;
    }
    
    #menu-general-id .default-heading h2 { 
        font-size: 14px;
    }

    #menu-general-id .box-area .owl-nav {
        top: 20%;
    }
}

@media screen and (max-width: 1023px) {
    #menu-general-id .icon{
        padding: 4px 0 0 0;
    }
}