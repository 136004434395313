.favorite-remove-container, .favorite-add-container {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
    text-transform: uppercase;

    width: 100%;

    font-size: 25px;    
    height: 60px;

    padding-top: 8px;

    border-radius: 10px;

    color: var(--white);
}

.hoverable-icon {
    font-family: 'someal' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;

    color: var(--white);
}

.favorite-add-container .hoverable-icon::before {  
    font-size: 25px;
    content: '\e9c2';
}

.favorite-remove-container .hoverable-icon::before {  
    font-size: 25px;
    content: '\e9c3';
}

.hoverable-text {  
    /*
    display: none;  
    */  
    color: var(--white);
    font-size: 18px;
    height: 30px;
}

/*
.favorite-add-container:hover .hoverable-icon::before {  
    content: '\e9c3';
}
*/

/*
.favorite-add-container:hover .hoverable-text, 
.favorite-remove-container:hover .hoverable-text {  
    display: block;    
}
*/

/*
.x-container { 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    font-weight: 900;
    background-color: #FF0000;
    color: var(--white);
    height: 40px;
    width: 40px;
    border-radius: 20px;

    margin-left: 8px;

    vertical-align: middle;
}

.x-container:hover { 
    color: #263A7B!important;
    background-color: var(--white);
}
*/