.point-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
    text-align: center;
    
    height: 10px;
    min-height: 10px;
    max-height: 10px;
    width: 10px;
    min-width: 10px;
    max-width: 10px;
    
    border-radius: 100%;
    background-color: var(--point-bg-color);
}