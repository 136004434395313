
/*======================///////////////
			start responsive style
=====================///////////////////////*/
@media (min-width:1920px){
   


}

@media (min-width:1440px) and (max-width:1919px){	
   
}


@media (min-width:1366px) and (max-width:1439px){ 
 
    
}


@media (min-width:992px) and (max-width:1199px){
.headerSection{ padding: 0; }
.nav-tabs .nav-link{font-size: 22px;}
.column-details{ margin-bottom: 30px; }

.graphic.left-graphic, .graphic.right-graphic{ max-width: 200px; }
.tags{ padding: 15px 15px 15px 15px;}
}
  
@media(min-width:768px) and (max-width:991px){
    .graphic{ display: none; }
    .hide-on-mobile{ display: none; }
    .hide-on-desktop{ display: block; }

    .headerSection{ padding: 0; }
    .box-area, .advertisement, .activevades{ min-height: inherit; display: inline-block !important; width: 100%; max-width: 49%; margin-right: 10px; vertical-align: top;}
    .box-area:nth-child(2), .advertisement{ margin-right: 0; }
    .scorri-gruppo{ text-align: center; }
    .recomendaciones-area{ margin-bottom: 30px; }
    .activevades{margin-right: 0;}

    .menu-slider{ padding:30px; }
    .menu-slider .owl-nav .owl-prev { left: 0;}
    .menu-slider .owl-nav .owl-next { right: 0;}

    
    .left-area .box-area .default-heading, .menu-area .box-area .default-heading, .box-area .small-text-area, .advertisement{ display: none; }
    .left-area .box-area{margin-bottom: 0;}
    .box-area .owl-nav{ display: inherit; top: 40%; }
    .box-area .owl-nav .owl-prev{ left: 0; }
    .box-area .owl-nav .owl-next{ right: 0; }
    .box-area .icon-small-arrow-right:before, 
    .box-area .icon-small-arrow-left:before{ color: #5970b9; opacity: 0.5;}
    .box-area .icon{ padding-top: 45px; padding-bottom:45px;}
    .box-area.download-area{ display: none !important; }
    .advertisement{display: none !important;}
    .right-area .advertisement{ display: inline-block !important; margin-right: auto; }

    .news-area .right-area{ margin-right: auto; }
    .tags{ padding: 15px 15px 15px 15px;}



}

@media(max-width:767px ){
    .hide-on-mobile{ display: none; }
    .hide-on-desktop{ display: block; }

    .default-heading h1, .default-heading > a.heading01{ font-size: 24px; }
    .default-heading h3, .default-heading > a.heading03,
    .inner-area .column-details .default-heading h3{ font-size: 20px; line-height: 24px; }


    .headerSection{ padding: 0; /*z-index: 99999;*/}
    .headerSection .minheight{ min-height: 65px; }
    
    .account-area .dropdown-menu{max-width: 230px;  min-width: 230px;}
    .account-area .dropdown-menu:before{height: 4px;}
    .account-area .dropdown-menu:after{ font-size: 10px; }
    .account-area .dropdown-menu .dropdown-header{ max-width: 100%; padding: 15px 20px; font-size: 14px; letter-spacing: 1px; }
    .account-area .user-profile-links, .account-area .btn-link{padding: 15px 20px;}
    .account-area .dropdown-menu .dropdown-item{ padding: 10px 0; }

    .navbar-brand-middle{ max-width: 50px; }
    /*
    .humbarger-arrow-area{ display: block; }
    .account-area{ display: none; }
    */

    .account-area{ margin-right: 0px; }
      
   
    .sidebar{max-width: 100%; margin-right: -100%;}
    .sidebar .navbar{ padding-left: 15px; padding-top: 0; }
    .sidebar.sidebar-hide-desktop{ display: flex; } 
    .sidebar .navbar .navbar-nav li.nav-item > a.nav-link{ font-size: 16px; padding:10px 0px;}


    .box-area,.advertisement{ display: inline-block !important; width: 100%; max-width: 48.8%; margin-right: 9px; margin-bottom: 0; vertical-align: top; min-height: inherit; }

    .box-area:nth-child(2), .advertisement{ margin-right: 0; }

    .left-area .box-area .default-heading, .menu-area .box-area .default-heading, .box-area .small-text-area, .advertisement{ display: none; }
    .left-area .box-area{margin-bottom: 0;}
    .box-area .owl-nav{ display: inherit; top: 40%; }
    .box-area .owl-nav .owl-prev{ left: 0; }
    .box-area .owl-nav .owl-next{ right: 0; }
    .box-area .icon-small-arrow-right:before, 
    .box-area .icon-small-arrow-left:before{ color: #5970b9; opacity: 0.5;}
    .box-area .icon{ padding-top: 15px; padding-bottom:15px; font-size: 50px;}
    .box-area.download-area{ display: none !important; }
    .advertisement{display: none !important;}

    .recomendaciones-area{ margin-bottom: 20px; }
    .activevades{ text-align: center; margin-right: auto;}
    .menu-slider{ padding:10px 20px 20px 20px; }
    .menu-slider .owl-nav .owl-prev{ left: 0; }
    .menu-slider .owl-nav .owl-next{ right: 0; }

    .box-area.suggestion-message{ margin-bottom: 20px; }
    .right-area{ margin-right: auto; }
    .right-area .box-area.info-box{ margin-bottom: 20px; }
    .right-area .box-area.info-box .default-heading { margin-bottom: 15px;}
    .right-area .box-area.info-box p{ margin-bottom: 0; }
    .right-area .advertisement{ display: inline-block !important; margin-right: auto; }
    .right-area .box-area.info-box, .right-area .activevades, .right-area .box-area.suggestion-message,
    .right-area .box-area.download-area,.right-area .advertisement{ max-width: 100%; width: 100%; }

    .graphic{ display: none; }
    .head-area .default-heading h3, .register-here{ text-align: center; margin-bottom: 15px; }
    .btns-area a{ padding-top: 20px; }

    .footer-area{ padding: 10px 0 10px 0; }


    .confirmation{ margin: 10px auto; width: auto; padding: 10px; }
    .head-area .default-heading h2{ font-size: 24px; }

    .navbar-brand.auto-width{ max-width: 125px;}
    .navbar-brand-middle.textual-area span{ font-size: 36px; }
    .language-change a{ max-width: 55px; }

    .create-profile .row-area{ padding: 0 15px; }

    .inner-area .back-btn-top{ left: 15px; }
    .inner-area .back-btn-top span{ display: none; }
    .inner-area .column-details .icon {font-size: 75px; margin-top: 0;}
    .inner-area .column-details .default-heading { margin-bottom: 20px;}
    .ingredientes .ingredientes-list ul li{ max-width: 100%; margin-right: 0; }

    .tags{ padding: 15px 15px 15px 15px;}
}


@media(max-width:576px ){
p{ font-size: 16px; line-height: 22px; }
.btn{ font-size: 16px; min-height: 40px; line-height: 30px;}
.form-group{ margin-bottom: 20px; }

.box-area,.advertisement{ max-width: 47.6%; }
.box-area .default-heading h2, .activevades .default-heading h2, .news-area .default-heading h2{ font-size: 22px; line-height: 22px; }

.nav-tabs .nav-link{ font-size: 16px; padding: 0 4px; }
.column-details{ margin-bottom: 30px; }

.number-list li{ padding: 10px 5px; }
.menu-area .default-heading{padding: 10px 0 0px 0;}
.column-details p, .listing li{font-size: 16px; line-height: 24px;}
.information-area .default-heading h2, .rate-recipe.area-container .default-heading h2, .recomendaciones-area .default-heading h2, .head-area .default-heading h2{font-size: 18px; line-height: 21px;}

.menu-slider .number-counts{width: 50px; font-size: 24px; height: 50px;}
.number-list li .circle-area{height: 54px; width: 54px; font-size: 20px; margin-bottom: 10px; border: #17438e solid 3px;}
.number-list li .circle-area span{font-size: 13px;}
.number-list li .name{ font-size: 16px; }

.right-area .box-area.suggestion-message{ max-width: 100%; }
.right-area .box-area.info-box{ max-width: 100%; }

.owl-carousel .owl-dots button.owl-dot, .owl-carousel .owl-dots button.owl-dot.active{width: 15px; height: 15px;}


.cover-area{ padding: 15px 0; }
.cover-area .navbar-brand-middle{ max-width: 150px; }
.navbar-brand-middle.textual-area{ max-width: 100%; }
.navbar-brand-middle.textual-area span{ font-size: 24px; }
.language-change a{ max-width: 28px;padding-left: 4px; }
.desktop-screen span, .mobile-screen span {font-size: 130px;}

.head-area{ margin: 30px 0 10px 0; }
.confirmation{ margin: 10px; }
.confirmation .form-group label{ font-size: 14px; }

.create-profile .row-area, .create-profile .row-area.top-row{ padding: 0 15px; }
.row-area .col-4{ padding-left: 5px; padding-right: 5px; }
.create-profile .row-area.top-row .column-details .default-heading h3{ font-size: 13px; line-height: 18px;}
.create-profile .row-area.bottom-row .column-details .default-heading h3 {font-size: 12px; line-height: 16px;}

.modal .modal-dialog .modal-content .modal-body{ padding: 0; }
.listing{ padding: 0px 0px 15px 0px; }
.activity.maxwidth .column-details p{ padding-bottom: 0; }

.tags{ padding: 15px 15px 15px 15px;}
.tags li a{ padding: 10px 6px; letter-spacing: -1px; }
.tags .owl-nav .owl-prev i, .tags .owl-nav .owl-next i {  font-size: 18px;}
.news-area .add-items, .news-area .add-items, .news-area .circling-area, .news-area .box-area-small, .news-area .advertisement{ margin-bottom: 30px; }

}
