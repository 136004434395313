@font-face {
    font-family: 'FontAwesome-Regular';
    src: url('../fonts/fa-regular-400.eot?fwtv9p');
    src: url('../fonts/fa-regular-400.eot?fwtv9p#iefix') format('embedded-opentype'),
        url('../fonts/fa-regular-400.ttf?fwtv9p') format('truetype'),
        url('../fonts/fa-regular-400.woff?fwtv9p') format('woff'),
        url('../fonts/fa-regular-400.svg?fwtv9p#fa-regular-400') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'FontAwesome-Solid';
    src: url('../fonts/fa-solid-900.eot?fwtv9p');
    src: url('../fonts/fa-solid-900.eot?fwtv9p#iefix') format('embedded-opentype'),
        url('../fonts/fa-solid-900.ttf?fwtv9p') format('truetype'),
        url('../fonts/fa-solid-900.woff?fwtv9p') format('woff'),
        url('../fonts/fa-solid-900.svg?fwtv9p#fa-solid-900') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="far"],
[class*=" far"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'FontAwesome-Regular' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
}

[class^="fas"],
[class*=" fas"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'FontAwesome-Solid' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
}